import React, { useState, createContext, useContext } from "react";

export const ActionsContext = createContext();

export default function ActionsProvider({ children }) {
  const [optionLoading, setOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  return <ActionsContext.Provider value={{ optionLoading, setOptionLoading, selectedOption, setSelectedOption }}>{children}</ActionsContext.Provider>;
}
