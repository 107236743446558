import React from "react";
import { requestError, round } from "../../utils/helpers";
import { refund } from "../../services/PoliciesService";
import { error, success as successAlert } from "../../utils/notifications";
import _get from "lodash.get";
import { Box, Button, Icon, TextField } from "@mui/material";
import Modal from "../Modal";
import { danger, success, gray } from "../../utils/colors";
import { connect } from "react-redux";

class RefundConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      egn: "",
      amount: "",
      refundComment: "",
      loading: false
    };
  }

  handleAmountChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    const rgx = /^[0-9]*\.?[0-9]*$/;
    let sum;
    const { variant, policy } = this.props;
    if (variant === "installments") {
      sum = _get(policy, "duePayment.promoAmount", _get(policy, "duePayment.amount"));
    } else {
      sum = _get(policy, "totalPromo", _get(policy, "total"));
    }
    if ((!value && !isNaN(value)) || (!isNaN(value) && rgx.test(value) && value <= sum)) {
      this.setState({
        amount: value
      });
    }
  };
  handleAmountChangeByPercentage = percentage => {
    let sum;
    const { variant, policy } = this.props;
    if (variant === "installments") {
      sum = _get(policy, "duePayment.promoAmount", _get(policy, "duePayment.amount"));
    } else {
      sum = _get(policy, "totalPromo", _get(policy, "total"));
    }

    this.setState({
      amount: (sum * percentage).toFixed(2)
    });
  };
  handleTxtChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    this.setState({
      refundComment: value
    });
  };

  handleRefund = async () => {
    if (!this.state.showEgnCheck) {
      this.setState({
        showEgnCheck: true
      });
    } else {
      if (this.state.egn === this.props?.policy?.egn) {
        const { variant, policy, currentProduct } = this.props;

        const roundedAmount = round(this.state.amount);
        this.setState({
          amount: roundedAmount,
          egnValidationError: false,
          egn: ""
        });

        let isFullRefund;
        if (variant === "installments") {
          isFullRefund = roundedAmount === _get(policy, "duePayment.promoAmount", _get(policy, "duePayment.amount"));
        } else {
          isFullRefund = roundedAmount === _get(policy, "totalPromo", _get(policy, "total"));
        }
        try {
          const isCscPlusGo = policy?.groupRequestId && ["CSC", "GO"].includes(policy?.productId);
          let requestPayload = {
            // todo при CSC+GO при рефънд от граждаската полица пращаме requestId-то на каското, защото имаме един Payment в базата за двете полици и той е закачен само за requestId-то на каското
            requestId: currentProduct === "CSC+GO" && policy?.productId === "GO" ? _get(policy, "cscPolicy.requestId") : _get(policy, "requestId"),
            groupRequestId: variant !== "installments" && isCscPlusGo ? policy.groupRequestId : undefined,
            isFullRefund: variant !== "installments" && isCscPlusGo ? false : isFullRefund, // за КАСКО + ГО при "вноски" за момента рефънда е същия както при Каско и Гражданска отделно(за разлика от полиците)
            amount: roundedAmount,
            paymentId: variant === "installments" ? _get(policy, "duePayment.paymentId") : "1"
          };

          if (this.state.refundComment) {
            requestPayload = {
              ...requestPayload,
              comment: this.state.refundComment
            };
          }
          this.setState({
            loading: true
          });
          await refund(requestPayload);
          this.props.close("refundModal");
          successAlert("Успешен REFUND!");
          this.setState({
            loading: false
          });
        } catch (err) {
          let errorMessage = requestError(err);
          error(errorMessage);
        }
      } else {
        this.setState({
          egnValidationError: true
        });
      }
    }
  };

  render() {
    const { amount, egn, refundComment, showEgnCheck, egnValidationError, loading } = this.state;
    const { policy, variant } = this.props;
    const cscPlusGoWarning = variant !== "installments" && policy?.subProduct === "CSC+GO" && !policy?.groupRequestId;
    let sum;
    if (variant === "installments") {
      sum = _get(policy, "duePayment.promoAmount", _get(policy, "duePayment.amount"));
    } else {
      sum = _get(policy, "totalPromo", _get(policy, "total"));
    }
    const { isRefundModalOpen, toggle } = this.props;
    const modalRefundContent = (
      <div>
        <p>ЕГН/ЕИК: {policy.egn}</p>
        <p>requestId: {policy.requestId}</p>
        {policy?.subProduct === "CSC+GO" && <p className={cscPlusGoWarning ? "has-text-danger" : ""}>groupRequestId: {policy?.groupRequestId || "липсва"}</p>}
        <p>сума: {sum ? sum.toFixed(2) : "-"} лв.</p>

        <br />
        <br />
        <Box mb={2}>
          <Button
            onClick={() => this.handleAmountChangeByPercentage(0.1)}
            variant="contained"
            size="small"
            style={{
              background: danger,
              color: "#fff",
              width: "min-content",
              cursor: "pointer"
            }}
          >
            10 %
          </Button>{" "}
          <Button
            onClick={() => this.handleAmountChangeByPercentage(0.2)}
            variant="contained"
            size="small"
            style={{
              background: danger,
              color: "#fff",
              width: "min-content",
              cursor: "pointer"
            }}
          >
            20 %
          </Button>
        </Box>
        <Box mb={2}>
          <TextField fullWidth label="Сума за връщане" id="outlined-size-small-refund-amount" variant="outlined" size="small" value={amount} onChange={this.handleAmountChange} />
          <br />
          <br />
          <TextField
            fullWidth
            label="Добави коментар"
            id="outlined-size-small-refund-comment"
            multiline
            rows={4}
            variant="outlined"
            size="small"
            value={refundComment}
            onChange={this.handleTxtChange}
          />
        </Box>
        {showEgnCheck && (
          <Box mb={2}>
            <TextField
              fullWidth
              label="Код за потвърждение"
              variant="outlined"
              size="small"
              value={this.state.egn}
              onChange={e =>
                this.setState({
                  egn: e.target.value,
                  egnValidationError: false
                })
              }
            />

            {egnValidationError && <p style={{ color: danger }}>Невалиден код!</p>}
          </Box>
        )}
        <Box mb={2}>
          <Button
            onClick={this.handleRefund}
            disabled={!!(!amount || (showEgnCheck && !egn) || cscPlusGoWarning)}
            variant="contained"
            size="small"
            style={{
              background: !(!amount || (showEgnCheck && !egn) || cscPlusGoWarning || loading) ? success : gray,
              color: "#fff",
              width: "min-content",
              float: "right",
              cursor: "pointer"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            {loading ? "loading..." : "Refund"}
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      </div>
    );

    return (
      <Modal open={isRefundModalOpen} setOpen={() => toggle("refundModal")} title="Refund" description="" icon>
        {modalRefundContent}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(RefundConfirmationModal);
