import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import _get from "lodash.get";
import QuestionsAnswers from "./QuestionsAnswers";
import Comment from "../Comments/Comment";
import "./detail.panel.sass";
import { connect } from "react-redux";
import PolicyDataInfo from "./PolicyDataInfo";
import UploadFileForm from "./UploadFileForm";
import KATData from "./KATData";
import ForeignInstallment from "./ForeignInstallment";
import TalonInfo from "./TalonInfo";
import LastModified from "./LastModified";
import Files from "./Files";
import DeliveryInfo from "./DeliveryInfo";
import TitularAndInsuredPersons from "./TitularAndInsuredPersons";
import ProductAndInsurerInfo from "./ProductAndInsurerInfo";
import CountriesInfo from "./CountriesInfo";
import PropTypes from "prop-types";
import Actions from "./Actions";
import TodoList from "./TodoList";
import SideTabs from "./SideTabs";
import UserData from "./UserData";
import Offers from "./Offers";
import { getOptions } from "./TodoList/config";
import routesConfig from "../../routes/routesConfig";
import { useNavigate } from "react-router-dom";
import { clearTabsFromSessionStorage, getDepositedTotal, getPolicyStatus, getSumTotal } from "../../utils/helpers";
import PricingType from "./PricingType";
import CopyToClipboard from "./CopyToClipboard";

const DetailPanel = props => {
  const [selectedTab, setSelectedTab] = useState("data");
  const [policy, setPolicy] = useState(null);
  const navigate = useNavigate();
  const { policy: defaultPolicy, refreshData, type, variant, subVariant, handleAction, hideActions = false, currentProduct, showSubProductTitle, showSecondaryProduct } = props;
  useEffect(() => {
    if (showSecondaryProduct) {
      const goPolicy = { ...defaultPolicy?.goPolicy, cscPolicy: defaultPolicy };
      setPolicy(goPolicy);
    } else {
      setPolicy(defaultPolicy);
    }
  }, []);

  const user = _get(props, "auth.user", {});
  const { uid, roles } = user || "";
  let productId = _get(policy, "productId");
  let installments = "";
  if (["policies"].includes(type)) {
    installments = _get(props, "policy.policyData.installments");
  }
  const TabPanel = props => {
    const { children, value } = props;
    return (
      <div role="tabpanel" hidden={value !== selectedTab} id={`vertical-tabpanel-${selectedTab}`} aria-labelledby={`vertical-tab-${selectedTab}`}>
        {value === selectedTab && children}
      </div>
    );
  };
  const todoListOptions = getOptions(policy, variant, subVariant, currentProduct);
  if (!policy) return <p>Loading...</p>;
  const productNameTitle = (p => {
    if (p === "CSC") return "Каско";
    if (p === "GO") return "Гражданска";
    return "";
  })(productId);
  return (
    <Grid container spacing={2} className="detail-panel-wrapper">
      {showSubProductTitle && (
        <Grid item xs={12}>
          <div className="horizontally-centered vertical-align detail-panel-box rounded">
            <p className="title-casco-plus-go">
              <a
                className="link"
                onClick={() => {
                  clearTabsFromSessionStorage();
                  if (policy?.productId === "GO") {
                    window.open(`${routesConfig.policies.route}?query=${_get(policy, "requestId")}`, "_blank");
                  }
                  if (policy?.productId === "CSC") {
                    window.open(`${routesConfig.policies.route}?query=${_get(policy, "requestId")}`, "_blank");
                  }
                }}
              >
                {productNameTitle}
              </a>
            </p>{" "}
            <div style={{ marginRight: 10 }}>{getPolicyStatus(policy, variant, subVariant)}</div>
            <div style={{ marginRight: 10 }}>
              <span className="block">Обща премия</span>
              {getSumTotal(policy)}
            </div>
            <div>
              <span className="block">Внесена премия</span>
              {getDepositedTotal(policy)}
            </div>
          </div>
        </Grid>
      )}
      <Grid item xs={10}>
        <Grid container spacing={0} sx={{ height: "100%" }}>
          <Grid item xs={2} className="detail-panel-box left-rounded">
            <SideTabs
              onChange={(e, value) => setSelectedTab(value)}
              value={selectedTab}
              productId={productId}
              variant={variant}
              subVariant={subVariant}
              currentProduct={currentProduct}
              isOffer={policy?.isOffer}
            />
          </Grid>
          <Grid item xs={10} className="detail-panel-box right-rounded">
            <TabPanel value="data">
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">За клиента</p>
                    <TitularAndInsuredPersons policy={policy} />
                    <CountriesInfo policy={policy} />
                    <UserData policy={policy} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">За застраховката</p>
                    <ProductAndInsurerInfo policy={policy} type={type} />
                    {["CSC", "GO", "OFF"].includes(productId) && <CopyToClipboard link={`policy-payment/?policy-id=${policy?._id}`} />}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">За доставка</p>
                    <DeliveryInfo policy={policy} variant={props?.variant} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">Коментар</p>
                    <Comment
                      refreshData={refreshData}
                      commentContent={_get(props, "policy.comment")}
                      commentDate={_get(props, "policy.commentDate") ? moment(_get(props, "policy.commentDate")).format("DD-MM-YYYY HH:mm") : ""}
                      policyOid={_get(props, "policy._id")}
                      email={_get(user, "email", "")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="details">
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Box m={2}>
                    {["CSC", "GO"].includes(productId) && (
                      <>
                        <p className="detail-panel-section-title">OT KAT</p>
                        <KATData policy={policy} />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={2}>
                    {["CSC", "KID", "MED"].includes(productId) && (
                      <>
                        <p className="detail-panel-section-title">Въпроси и отговори</p>
                        <QuestionsAnswers policy={policy} />
                      </>
                    )}
                    <ForeignInstallment policy={policy} />
                    <LastModified policy={policy} />
                    {["PRP", "KID", "MED"].includes(productId) && (
                      <div>
                        <b>Брой вноски:</b> <span>{installments}</span>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box m={2}>
                    {(productId === "CSC" || (productId === "GO" && _get(policy, "policyData.savedCar.incompleteKATData", false))) && (
                      <>
                        <p className="detail-panel-section-title">Прикачени</p>
                        <TalonInfo policy={policy} uid={uid} />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <PricingType policy={policy} />
                  <PolicyDataInfo policy={policy} />
                </Grid>
              </Grid>

              {/*{type === "installments" && (*/}
              {/*  <Box m={2}>*/}
              {/*    <LastModified policy={policy} />*/}
              {/*  </Box>*/}
              {/*)}*/}
            </TabPanel>
            <TabPanel value="documents">
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">Добави документи</p>
                    <UploadFileForm policy={policy} refreshData={refreshData} type={type} subVariant={subVariant} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box m={2}>
                    <p className="detail-panel-section-title">Качени документи</p>
                    <Files policy={policy} uid={uid} />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="installments">
              <Grid container spacing={0}>
                {variant === "installments" && (
                  <Grid item xs={6}>
                    <Box m={2}>
                      <p className="detail-panel-section-title">Плащания</p>
                      <span
                        className="link"
                        style={{ display: "block" }}
                        onClick={() => {
                          navigate(routesConfig.payments.route, {
                            state: { requestId: _get(policy, "requestId") }
                          });
                        }}
                      >
                        плащания
                      </span>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="history"></TabPanel>
            <TabPanel value="offers">
              <Offers policy={policy} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={0} sx={{ height: "100%" }}>
          <Grid item xs={12} className="detail-panel-box rounded todo-list" style={todoListOptions.length > 0 ? {} : { display: "none" }}>
            <Box mx={0} mt={0}>
              <TodoList policy={policy} handleAction={handleAction} refreshData={refreshData} options={todoListOptions} />
            </Box>
          </Grid>
          <Grid item xs={12} className="detail-panel-box rounded">
            <Box mx={0} mt={0}>
              {!hideActions && (
                <Actions
                  roles={roles}
                  policy={policy}
                  handleAction={handleAction}
                  type={type}
                  variant={variant}
                  currentProduct={currentProduct}
                  subVariant={subVariant}
                  refreshData={refreshData}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
DetailPanel.propTypes = {
  policy: PropTypes.object,
  auth: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["policies", "installments"]).isRequired,
  variant: PropTypes.oneOf(["queries", "installments", "policies", "offers"]), // todo comes from selectedTab value in Casco.js || GO.js
  subVariant: PropTypes.oneOf([
    "active",
    "renewed",
    "query",
    "offers",
    "confirmed",
    "rejected",
    "process",
    "informed",
    "overdue",
    "issued",
    "cancelled",
    "paidElsewhere",
    "expiring",
    "expired",
    "signedByCustomer",
    "sentToInsurer",
    "all"
  ]), // todo comes from installmentsSelectedTab or offerSelectedTab value in Casco.js || GO.js
  handleAction: PropTypes.func,
  hideActions: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(DetailPanel);
