import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Navigation from "../../Navigation";
import { grayLight, white } from "../../../utils/colors";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "48px !important"
}));

class PageLayout extends React.Component {
  render() {
    switch (process.env.REACT_APP_ENV) {
      case "development":
        window.document.title = "Local Back Office";
        break;
      case "staging":
        window.document.title = "STG Back Office";
        break;
      case "production":
        window.document.title = "Back Office";
        break;
      default:
        return;
    }
    const { children, title, hideNavigation, noPadding, hasGrayBg } = this.props;
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {!hideNavigation && <Navigation DrawerHeader={DrawerHeader} title={title} />}
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: grayLight }}>
          <DrawerHeader />
          <Grid item xs={12} style={{ backgroundColor: hasGrayBg ? grayLight : white, borderRadius: 5, minHeight: "87vh" }} sx={{ p: noPadding ? 0 : 4 }}>
            {children}
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default PageLayout;
