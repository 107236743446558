import React from "react";
import moment from "moment/moment";
import { Grid } from "@mui/material";

const LeftColumnInfo = props => {
  const { policy, Item } = props;
  return (
    <Grid item xs={2}>
      <Item elevation={3} style={{ textAlign: "left" }}>
        <p style={useStyles.smallParagraph}>
          Полица: <b>{policy.policyId}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Продукт: <b>{policy.policyData.productName}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Застраховател: <b>{policy.policyData.insurerName}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Вноски: <b>{policy.policyData.installments}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Застраховащ: <b>{policy.name}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          ЕГН: <b>{policy.egn}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Телефон: <b>{policy.phone}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Имейл: <b>{policy.email}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Премия: <b>{policy.premium} лв.</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Данък: <b>{policy.tax} лв.</b>
        </p>
        <p style={useStyles.smallParagraph}>
          Общо:<b> {policy.total} лв.</b>
        </p>
        <p style={useStyles.smallParagraph}>
          От: <b>{moment(policy.validFrom).locale("bg").format("DD.MM.YYYY")}</b>
        </p>
        <p style={useStyles.smallParagraph}>
          До: <b>{moment(policy.validTo).locale("bg").format("DD.MM.YYYY")}</b>
        </p>
      </Item>
    </Grid>
  );
};
const useStyles = {
  paragraph: {
    paddingLeft: 10,
    fontSize: 18
  },
  smallParagraph: {
    paddingLeft: 12,
    fontSize: 10
  }
};
export default LeftColumnInfo;
