import firebase from "../utils/firebase";
import { request } from "./APIservice";
import { GET } from "../constants/api";

const { auth } = firebase;
export default class AuthUserService {
  static unsubscribe;

  static async createUserWithEmail(email, password) {
    await firebase.createUserWithEmailAndPassword(auth, email, password).catch(error => {
      throw new Error(error.message);
    });
  }

  static async setUserInfo(displayName) {
    await auth.currentUser.updateProfile({
      displayName: displayName
    });
  }

  static getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      this.unsubscribe = auth.onAuthStateChanged(user => {
        resolve(user);
      }, reject);
      this.unsubscribe();
    });
  }
  static subscribeAuthChange(callback) {
    // (user: firebase.currentUser | null)
    this.c = auth.onAuthStateChanged(callback);
  }

  static unsubscribeAuthChange() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  static async logOut() {
    await firebase.signOut(auth).catch(error => {
      throw new Error(error.message);
    });
  }
  static async sendPasswordResetEmail(email) {
    return await firebase.sendPasswordResetEmail(auth, email).catch(error => {
      throw error.message;
    });
  }

  static async loginWithEmail(email, password) {
    return await firebase.signInWithEmailAndPassword(auth, email, password).catch(error => {
      throw new Error(error.message);
    });
  }

  static async loginAnonymous() {
    await firebase.signInAnonymously(auth).catch(error => {
      throw new Error(error.message); // todo някой път хвърля грешка от firebase
    });
  }

  static async checkRoleDefined(email) {
    const response = await request(GET, `/checkRoleDefined/${email}`);
    return response;
  }
}
