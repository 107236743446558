import React from "react";
import { grayBorder } from "../../../utils/colors";
import { Grid } from "@mui/material";

const UtmAndSource = props => {
  const { utmSource, source } = props;
  const utmHtml = utmSource ? <span style={{ display: "block", fontSize: "10px", color: grayBorder }}>{utmSource}</span> : null;
  const sourceHtml = source ? <span style={{ display: "block", fontSize: "10px", color: grayBorder }}>{source}</span> : null;
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      {utmHtml} {sourceHtml}
    </Grid>
  );
};

export default UtmAndSource;
