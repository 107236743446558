export const insurersList = [
  { label: "Алианц", value: "allianz" },
  { label: "Армеец", value: "armeec" },
  { label: "Инстинкт", value: "instinct" },
  { label: "Бул Инс", value: "bulins" },
  { label: "Булстрад", value: "bulstrad" },
  { label: "Булстрад Живот", value: "bulstradlife" },
  { label: "Дал Бог", value: "dallbogg" },
  { label: "ДЗИ", value: "dzi" },
  { label: "Евроинс", value: "euroins" },
  { label: "Дженерали", value: "generali" },
  { label: "Левинс", value: "levins" },
  { label: "ОЗК", value: "ozk" },
  { label: "ЗК Уника АД", value: "uniqa" }
];
