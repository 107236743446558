import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { getRefunds } from "../../../services/PoliciesService";
import { error } from "../../../utils/notifications";
import _get from "lodash.get";
import moment from "moment";
import { Avatar, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { productImage } from "../../../utils/helpers";
import { danger, primary } from "../../../utils/colors";
import InstallmentIcon from "@mui/icons-material/Update";

class Refunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refunds: []
    };
  }
  async componentDidMount() {
    try {
      const response = await getRefunds();
      const refunds = _get(response, "data");
      this.setState({
        refunds
      });
    } catch (err) {
      let errorMessage = "";
      if (err.response) {
        errorMessage = err.response.data ? _get(err, "response.data.message") : JSON.stringify(err.response);
      } else if (err.request) {
        errorMessage = "The request was made but no response was received";
      } else {
        errorMessage = "Something happened in setting up the request that triggered an Error";
      }
      error(errorMessage);
    }
  }

  // TODO: Ask collegues about this grid.

  render() {
    const { refunds } = this.state;
    return (
      <PageLayout title="Refunds" {...this.props}>
        <Grid item xs={12} sm={12} md={12}>
          {refunds.length ? (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Име</TableCell>
                  <TableCell align="right" width="15%">
                    № на полицата
                  </TableCell>
                  <TableCell align="center" width="10%">
                    Продукт
                  </TableCell>
                  <TableCell align="right">Коментар</TableCell>
                  <TableCell align="right">Върната сума</TableCell>
                  <TableCell align="right">Цяла сума</TableCell>
                  <TableCell align="right" width="10%">
                    Дата
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refunds.map((refund, index) => {
                  const { email, name, policyId, comment, productId, productName, insurerName, timestamp, refundedAmount, totalAmount, duePaymentId } = refund;
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {email}
                      </TableCell>
                      <TableCell align="right">{name}</TableCell>
                      <TableCell align="center" width="15%">
                        {policyId}
                        {!!duePaymentId && (
                          <span className="font-small vertical-align horizontally-centered">
                            <InstallmentIcon htmlColor={primary} fontSize="small" style={{ display: "inline-block" }} />
                            вноска
                          </span>
                        )}
                      </TableCell>
                      <TableCell width="10%">
                        <Grid container alignContent="center" alignItems="center" justifyContent="center" direction="column" spacing={2} mt={0.5}>
                          <Avatar alt="Policy Icon" src={productImage(productId)} style={{ width: 36, height: 36 }} />
                          <Typography style={{ fontSize: "9px", textAlign: "center" }}>
                            {productName} <br />
                            <b>{insurerName}</b>
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">{comment ? comment : "-"}</TableCell>
                      <TableCell align="right">
                        <b style={{ color: danger }}>{refundedAmount.toFixed(2)}</b>
                        <span className="font-small"> лв.</span>
                      </TableCell>
                      <TableCell align="right">
                        <b style={{ color: danger }}>{totalAmount.toFixed(2)}</b>
                        <span className="font-small"> лв.</span>
                      </TableCell>
                      <TableCell align="right" width="10%">
                        {timestamp ? moment(timestamp).format("DD-MM-YYYY HH:mm") : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <p style={{ textAlign: "center" }}>Няма резултати</p>
          )}
        </Grid>
      </PageLayout>
    );
  }
}
export default Refunds;
