import moment from "moment";

export const subtractFiveDays = moment().add(-5, "days").format("YYYY-MM-DD");
export const addThirtyDays = moment().add(30, "days").format("YYYY-MM-DD");
export const subtractThirtyDays = moment().add(-30, "days").format("YYYY-MM-DD");
export const addFifteenDays = moment().add(15, "days").format("YYYY-MM-DD");
export const subtractFifteenDays = moment().add(-15, "days").format("YYYY-MM-DD");
export const addSixtyDays = moment().add(60, "days").format("YYYY-MM-DD");
export const subtractSixtyDays = moment().add(-60, "days").format("YYYY-MM-DD");
export const addHundredAndTwentyDays = moment().add(120, "days").format("YYYY-MM-DD");
export const subtractHundredAndTwentyDays = moment().add(-120, "days").format("YYYY-MM-DD");
