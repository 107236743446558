import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import { moveToIssued } from "../../../../services/PoliciesService";
import { timeout } from "../../../../utils/helpers";

const MoveToIssued = props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const movetoIssuedCall = async () => {
      try {
        const { policy } = props;
        await moveToIssued({ policyId: policy?._id });
        successAlert("moved successfully!");

        await timeout(2000);
        props.close();
      } catch (e) {
        if (e?.response?.data) errorAlert("Error: " + e.response.data);
        else if (e?.message) errorAlert("Error: " + e.message);
        else errorAlert("Error occurred!");
      }
      setLoading(false);
    };

    movetoIssuedCall();
  }, []);

  return (
    <>
      <h2 className="move-to-issued-title">Полицата е издадена</h2>
      <Grid container spacing={0} display="flex" justifyContent="center">
        <Typography>{loading ? "loading..." : "Преместено в издадени"}</Typography>
      </Grid>
    </>
  );
};

export default MoveToIssued;
