import React from "react";
import _get from "lodash.get";
import { cancelPolicy } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { danger } from "../../utils/colors";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

class CancelPolicyConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: "Cancelled" };
  }
  handleTxtChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  };

  render() {
    const { modalName, isCancelPolicyModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const modalTitle = "Анулиране/Прекратяване на полицата";
    const btnTxt = "Анулирай";
    const { status } = this.state;

    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={danger}
        btnTxt={btnTxt}
        isModalOpen={isCancelPolicyModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{ ...objWithEgnAndRequestId, status }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Статус
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              labelId="demo-simple-select-placeholder-label-label"
              id="outlined-size-small"
              value={status}
              onChange={e => this.handleTxtChange(e, "status")}
              displayEmpty
            >
              <MenuItem value={"Cancelled"}>Анулирана полица</MenuItem>
              <MenuItem value={"PartiallyPaid"}>Отказни плащания</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </ConfirmationModal>
    );
  }
}
const generatePayloadRequest = props => {
  return {
    policyId: _get(props, "objWithEgnAndRequestId._id"),
    status: _get(props, "objWithEgnAndRequestId.status")
  };
};

export default withConfirmationModal(CancelPolicyConfirmationModal, {
  generatePayloadRequest,
  apiRequest: cancelPolicy,
  modalName: "cancelPolicyModal",
  successMessage: "Успешно анулиране!"
});
