import { createSlice } from "@reduxjs/toolkit";
import AuthUserService from "../../services/AuthUserService";
import { rolesSecurityCheck, setTokensToStorage } from "../../utils/auth";
import jwt_decode from "jwt-decode";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: undefined,
    isAuthenticated: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    unsetUser: state => {
      state.user = {};
      state.isAuthenticated = false;
    }
  }
});

export const { setUser, unsetUser } = authSlice.actions;
export const verifyAuth = () => dispatch => {
  AuthUserService.subscribeAuthChange(async user => {
    if (user) {
      const { email, uid, refreshToken, emailVerified, displayName } = user;
      const token = await user.getIdToken(); //todo to return await user.getIdToken(true);
      const decodedToken = jwt_decode(token);
      let additionalData = {};
      if (decodedToken) {
        //const { roles, aud, exp, iat, utmSources } = decodedToken || {};
        let { roles, aud, exp, iat, utmSources } = decodedToken || {};

        additionalData = { roles, aud, exp, iat, utmSources };
        if (rolesSecurityCheck(token)) {
          dispatch(setUser({ email, uid, refreshToken, emailVerified, displayName, ...additionalData, token }));
          setTokensToStorage({ ...user, token, roles, aud, exp, iat, utmSources });
        } else {
          dispatch(unsetUser());
          console.log("No user");
        }
      }
    } else {
      dispatch(unsetUser());
      console.log("No user");
    }
  });
};
export const selectUser = state => state.auth.user;
export const selectIsAuthenticated = state => state.auth.isAuthenticated;

export default authSlice.reducer;
