import _get from "lodash.get";
import _find from "lodash.find";

export const attachmentFields = [
  {
    name: "policy",
    type: "file",
    defaultValueFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "policy" }), "uploaded", false);
      return uploaded ? { name: "файлът е качен" } : "";
    },
    defaultErrorFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      return _get(_find(uploadedFiles, { key: "policy" }), "message", "");
    },
    label: "Полица  (задължително):",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "vdog",
    type: "file",
    defaultValueFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "vdog" }), "uploaded", false);
      return uploaded ? { name: "файлът е качен" } : "";
    },
    defaultErrorFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      return _get(_find(uploadedFiles, { key: "vdog" }), "message", "");
    },
    label: "Възлагателен договор (задължително):",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "questionnaire",
    type: "file",
    label: "Предложение въпросник:",
    defaultValueFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "questionnaire" }), "uploaded", false);
      return uploaded ? { name: "файлът е качен" } : "";
    }
  },
  {
    name: "moneyLaundering",
    type: "file",
    label: "Пране на пари (за премии над 10 000 лв.):",
    defaultValueFunction: props => {
      const formData = _get(props, `form.offlineFormData`);
      const uploadedFiles = _get(props, `form.offlineUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "moneyLaundering" }), "uploaded", false);
      return uploaded ? { name: "файлът е качен" } : "";
    }
    // validators: ["required"],
    // errorMessages: ["Полето е задължително"]
  }
];
