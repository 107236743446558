import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { addPromoCode, getPromoCodes, removePromoCode } from "../../../services/PoliciesService";
import { Box, Button, Grid, Icon, TextField, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import _get from "lodash.get";
import Checkboxes from "../../Checkboxes";
import mainConfig from "../../../config/mainConfig";
import { danger, success, gray } from "../../../utils/colors";
import moment from "moment";

const configProducts = mainConfig?.products;

class AddPromoCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      expiration: "",
      products: [],
      productIds: [],
      isLoading: false,
      error: false,
      percentage: 0,
      list: []
    };
  }
  async componentDidMount() {
    const response = await getPromoCodes();
    this.setState({
      list: _get(response, "data", [])
    });
  }
  handleChange = (e, name) => {
    if (name === "percentage" && !this.validatePercentage(e.target.value)) {
      return;
    }

    this.setState(
      {
        [name]: e.target.value,
        error: false
      },
      () => {}
    );
  };

  handleRemove = async id => {
    const response = await removePromoCode({
      id
    });
    const { list } = this.state;
    const removeIndex = list.map(item => item["_id"]).indexOf(_get(response, "data._id"));
    const newList = [...list];
    if (removeIndex >= 0) {
      newList.splice(removeIndex, 1);
      this.setState({ list: newList });
    }
  };
  handleSubmit = async e => {
    e.preventDefault();
    try {
      const { code, expiration, productIds, percentage } = this.state;
      this.setState({ isLoading: true });
      const response = await addPromoCode({
        code,
        expiration,
        products: productIds,
        percentage
      });
      if (_get(response, "status") === 200) {
        this.setState(prevState => {
          return { isLoading: false, list: [...prevState.list, _get(response, "data")], code: "", percentage: 0 };
        });
        successAlert("sent successfully!");
      }
    } catch (e) {
      errorAlert(e.message);
      this.setState({ isLoading: false });
    }
  };
  handleChangeCheckboxes = (questionKey, selectedObjects, selectedIds) => {
    this.setState({ products: selectedObjects, productIds: selectedIds });
  };
  validatePercentage = percentage => {
    const rgx = /^[0-9]*\.?[0-9]*$/;
    return percentage.match(rgx) && percentage >= 0 && percentage <= 100;
  };
  render() {
    const { code, expiration, products, isLoading, error, percentage, list } = this.state;
    const btnDisabled = !percentage || !code || isLoading || !expiration || !products.length;
    return (
      <PageLayout title="Добавяне на промо кодове" {...this.props}>
        {error && <p style={{ color: danger }}>Възникна грешка</p>}
        <Box m={2}>{isLoading && <LinearProgress />}</Box>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <form style={{ marginLeft: "10px" }} onSubmit={this.handleSubmit}>
              <Box mb={2}>
                <TextField fullWidth label="Промокод" placeholder="primex" variant="outlined" size="small" value={code} onChange={e => this.handleChange(e, "code")} />
              </Box>
              <Box mb={2}>
                <TextField onChange={e => this.handleChange(e, "percentage")} variant="outlined" size="small" label="Отстъпка в проценти" value={percentage} />
              </Box>
              <Box mb={2}>
                <TextField
                  onChange={e => this.handleChange(e, "expiration")}
                  label="Валидност до"
                  type="date"
                  value={expiration}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                />
              </Box>
              <Box mb={2}>
                <Checkboxes isHalf handleChange={this.handleChangeCheckboxes} name="products" selected={products || []} options={configProducts} />
              </Box>
              <Box mb={2} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <Button
                  disabled={btnDisabled}
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    background: !btnDisabled ? success : gray,
                    color: "#fff",
                    width: "min-content"
                  }}
                  endIcon={<Icon>arrow_right</Icon>}
                >
                  Запиши
                </Button>
              </Box>
            </form>
          </Grid>
          <Grid item xs={6}>
            <List dense>
              {list.map(item => (
                <ListItem key={item["_id"]}>
                  <ListItemIcon>
                    <Icon style={{ color: danger }}>local_offer</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.code} -${_get(item, "percentage", 0)}% (exp: ${item.expiration ? moment(item.expiration).format("DD-MM-YYYY") : " - "})`}
                    secondary={(item.products || []).toString()}
                  />
                  <ListItemIcon>
                    <Icon style={{ color: danger, cursor: "pointer" }} onClick={() => this.handleRemove(_get(item, "_id", 0))}>
                      clear
                    </Icon>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

export default AddPromoCodes;
