import React from "react";
import _get from "lodash.get";
import { updateDateOfDuePayment } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { TextField } from "@mui/material";
import moment from "moment";
import { danger, primary } from "../../utils/colors";

class UpdateDateOfDuePaymentConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    const dueDate = moment(_get(props, "objWithEgnAndRequestId.dueDate")).format("DD-MM-YYYY");
    this.state = { dueDate };
  }
  handleDateChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    this.setState({
      dueDate: value
    });
  };
  dueDateIsValid = () => {
    const date = moment(this.state.dueDate, "DD-MM-YYYY", true);
    return date.isValid();
  };
  render() {
    const { modalName, isUpdateDateOfDuePaymentModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const modalTitle = "Промяна на датата на плащане";
    const btnTxt = "Промени";
    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        btnDisabled={!this.dueDateIsValid()}
        isModalOpen={isUpdateDateOfDuePaymentModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{
          ...objWithEgnAndRequestId,
          newDueDate: `${this.state.dueDate} 12:00`
        }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <TextField
          fullWidth
          label="Промени датата"
          placeholder="24-01-2020"
          id="outlined-size-small-due-date"
          variant="outlined"
          size="small"
          value={this.state.dueDate}
          onChange={this.handleDateChange}
        />
        {!this.dueDateIsValid() && <p style={{ color: danger }}>Невалидна дата(формат ден-месец-година) </p>}
      </ConfirmationModal>
    );
  }
}
const generatePayloadRequest = props => {
  const requestPayload = {
    duePaymentId: _get(props, "objWithEgnAndRequestId.duePaymentId"),
    dueDate: _get(props, "objWithEgnAndRequestId.newDueDate")
  };
  return requestPayload;
};

export default withConfirmationModal(UpdateDateOfDuePaymentConfirmationModal, {
  generatePayloadRequest,
  apiRequest: updateDateOfDuePayment,
  modalName: "updateDateOfDuePaymentModal",
  successMessage: "Успешна промяна!"
});
