import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { danger } from "../../utils/colors";
import _get from "lodash.get";

const PricingType = props => {
  const [visible, setVisible] = useState(false);
  const cscPayments = _get(props, "policy.policyData.cscPayments");
  const allPayments = _get(props, "policy.policyData.allPayments");
  const goPayments = _get(props, "policy.policyData.goPayments");
  const cscPricingType = _get(props, "policy.policyData.cscPricingType");
  const product = _get(props, "policy.productId");
  const subProduct = _get(props, "policy.subProduct");
  const toggle = () => {
    setVisible(visible => !visible);
  };

  const paymentsHtml = (p => {
    if (subProduct !== "CSC+GO") return <pre>{JSON.stringify(cscPayments, undefined, 2)}</pre>;
    if (product === "CSC" && allPayments?.length > 0) {
      return (
        <>
          <p>плащания само за каско</p>
          <pre>{JSON.stringify(cscPayments, undefined, 2)}</pre>
          <p>всички плащания</p>
          <pre>{JSON.stringify(allPayments, undefined, 2)}</pre>
        </>
      );
    }
    if (product === "GO" && allPayments?.length > 0) {
      return (
        <>
          <p>плащания само за гражданска</p>
          <pre>{JSON.stringify(goPayments, undefined, 2)}</pre>
          <p>всички плащания</p>
          <pre>{JSON.stringify(allPayments, undefined, 2)}</pre>
        </>
      );
    }
    return <></>;
  })(product, subProduct);

  if (["CSC", "GO"].includes(product) && cscPricingType === "OfferWithSamplePrice") {
    return (
      <div style={{ overflow: "scroll", maxWidth: "800px" }}>
        <p className="vertical-align">
          <InfoIcon onClick={toggle} htmlColor={danger} fontSize="small" style={{ display: "inline-block", cursor: "pointer" }} />
          <span onClick={toggle}>PricingType: OfferWithSamplePrice</span>
        </p>
        {visible && paymentsHtml}
      </div>
    );
  }
  return null;
};

export default PricingType;
