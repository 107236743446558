import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { getIndividualPolicy, updatePayments } from "../../../services/PoliciesService";
import _get from "lodash.get";
import PageLayout from "../../layouts/PageLayout";

import withParams from "../../HOC/withParams";
import PaymentsCSCGO from "../../Payments/PaymentsCSCGO";
import ContentWrapperWithButtons from "./ContentWrapperWithButtons";
import { round, validatePercentage } from "../../../utils/helpers";

const cscServices = [
  { short: "EE", name: "Експертна оценка" },
  { short: "TS", name: "Доверен сервиз" },
  { short: "OS", name: "Официален сервиз" }
];

const discounts = [
  { label: "без отстъпка", value: 0 },
  { label: "5 %", value: 5 },
  { label: "10 %", value: 10 },
  { label: "15 %", value: 15 },
  { label: "20 %", value: 20 }
];

const SendOfferCSCGO = props => {
  const id = props.params.id;
  const [email, setEmail] = useState("");
  const [cscService, setCscService] = useState("");
  const [discount, setDiscount] = useState(0);
  const [insuranceSum, setInsuranceSum] = useState();
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState();
  const [policy, setPolicy] = useState({});
  const productId = "CSC+GO";
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getIndividualPolicy(id);
      setPolicy(_get(response, "data"));
      setEmail(_get(response, "data.email"));
      setInsuranceSum(_get(response, "data.policyData.cscCarPrice"));
      setCscService(_get(response, "data.policyData.cscService"));
      let paymentsResponse = _get(response, "data.policyData.payments", {});
      if (Object.keys(paymentsResponse).length === 0) {
        paymentsResponse = {
          1: {
            active: false,
            sum: "0.00",
            original: "0.00",
            1: {
              number: 1,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            }
          },
          2: {
            active: false,
            sum: "0.00",
            original: "0.00",
            1: {
              number: 1,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            },
            2: {
              number: 2,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            }
          },
          4: {
            active: false,
            sum: "0.00",
            original: "0.00",
            1: {
              number: 1,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            },
            2: {
              number: 2,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            },
            3: {
              number: 3,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            },
            4: {
              number: 4,
              original_price_csc: "0.00",
              original_price_go: "0.00",
              original_price: "0.00",
              premium_with_tax: "0.00",
              discount: "0.00"
            }
          }
        };
      }
      const cscPricingType = _get(response, "data.policyData.cscPricingType");
      if (cscPricingType === "OfferWithSamplePrice" || cscPricingType === "") {
        const discountRate = _get(response, "data.policyData.discountRate");

        const calculatedDiscount = round(discountRate * 100);
        setDiscount(calculatedDiscount);
      }
      setPayments(paymentsResponse);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    let p = payments;
    if (p !== undefined && Object.keys(p).length > 0) {
      Object.keys(p).map(k => {
        let sum = calculateSum(k, p);
        let original = calculateOriginalSum(k, p);
        p[k].sum = parseFloat(sum).toFixed(2);
        p[k].original = parseFloat(original).toFixed(2);
        p[k].active = sum > 0;
      });
    }
    setPayments(p);
  }, [counter]);

  useEffect(() => {
    updatePaymentsAfterDiscountChange();
  }, [discount]);

  const handleSubmit = async e => {
    let buttonType = e.target.name;
    let backofficeStatus = "None";
    let mailSend = false;
    if (buttonType === "send") {
      backofficeStatus = "OfferSent";
      mailSend = true;
    }
    try {
      const data = {
        id,
        mailSend,
        productId,
        payments,
        backofficeStatus,
        cscService,
        insuranceSum,
        toEmail: email,
        groupRequestId: policy?.groupRequestId,
        discountRate: round(discount / 100)
      };
      const response = await updatePayments(data);
      // const response = { status: 200 };
      if (response.status === 200) {
        successAlert("sent successfully!");
        setDialogOpen(false);
      }
    } catch (e) {
      errorAlert(e.message);
    }
  };

  function getSequenceElement(n) {
    if (n === 1) {
      return 1;
    }
    if (n < 4) {
      return 2;
    }
    return 4;
  }

  const paymentsKeyMapping = index => {
    let key1 = getSequenceElement(index);
    let key2 = index - (key1 - 1);
    return [key1, key2];
  };

  const updatePaymentsAfterDiscountChange = () => {
    if (payments) {
      let array = [1, 2, 4];
      let newPayments = { ...payments };
      for (let i = 0; i < array.length; i++) {
        const key1 = array[i];
        Object.keys(payments[key1])
          .filter(Number)
          .forEach(key2 => {
            const original_price_csc = _get(payments, `[${key1}][${key2}].original_price_csc`);
            const original_price_go = _get(payments, `[${key1}][${key2}].original_price_go`);
            newPayments = { ...newPayments, [key1]: { ...newPayments[key1], [key2]: calcSums(original_price_csc, original_price_go) } };
          });
      }
      setPayments(newPayments);
    }
  };

  const calcSums = (originalPriceCSC, originalPriceGO) => {
    const discountInNumber = discount / 100;
    let premiumWithTax = parseFloat(originalPriceGO) + parseFloat(originalPriceCSC) - originalPriceCSC * discountInNumber;
    let originalPrice = parseFloat(originalPriceCSC) + parseFloat(originalPriceGO);
    return {
      original_price_csc: parseFloat(originalPriceCSC).toFixed(2),
      original_price_go: parseFloat(originalPriceGO).toFixed(2),
      original_price: parseFloat(originalPrice).toFixed(2),
      discount: parseFloat(originalPrice - premiumWithTax).toFixed(2),
      premium_with_tax: parseFloat(premiumWithTax).toFixed(2)
    };
  };

  const calculateOriginalSum = (key1, payments) => {
    let sum = 0;
    if (payments[key1]) {
      Object.keys(payments[key1])
        .filter(Number)
        .map(k => {
          if (["sum", "active", "original"].indexOf(k) !== -1) {
            return;
          }
          sum += parseFloat(payments[key1][k]?.original_price);
        });
    }
    return sum;
  };

  const calculateSum = (key1, payments) => {
    let sum = 0;
    if (payments[key1]) {
      Object.keys(payments[key1])
        .filter(Number)
        .map(k => {
          if (["sum", "active", "original"].indexOf(k) !== -1) {
            return;
          }
          sum += parseFloat(payments[key1][k]?.premium_with_tax);
        });
    }
    return sum;
  };

  const handleBlur = e => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "insuranceSum") {
      setInsuranceSum(e.target.value);
    } else if (name === "cscSevice") {
      setCscService(e.target.value);
    } else if (/originalPriceCSC:r([0-9]):/.test(name)) {
      const index = parseInt(name.match(/originalPriceCSC:r([0-9]):/)[1]);
      const [key1, key2] = paymentsKeyMapping(index);

      let p = {
        ...payments,
        [key1]: {
          ...payments[key1],
          [key2]: calcSums(value, _get(payments, `[${key1}][${key2}].original_price_go`))
        }
      };
      setPayments(p);
    } else if (/originalPriceGO:r([0-9]):/.test(name)) {
      const index = parseInt(name.match(/originalPriceGO:r([0-9]):/)[1]);
      const [key1, key2] = paymentsKeyMapping(index);

      let p = {
        ...payments,
        [key1]: {
          ...payments[key1],
          [key2]: calcSums(_get(payments, `[${key1}][${key2}].original_price_csc`), value)
        }
      };
      setPayments(p);
    }
    setCounter(counter + 1);
  };

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "insuranceSum") {
      setInsuranceSum(e.target.value);
    } else if (name === "cscSevice") {
      setCscService(e.target.value);
    } else if (name === "toEmail") {
      setEmail(e.target.value);
    } else if (/originalPriceCSC:r([0-9]):/.test(name)) {
      const index = parseInt(name.match(/originalPriceCSC:r([0-9]):/)[1]);
      const [key1, key2] = paymentsKeyMapping(index);
      let p = {
        ...payments,
        [key1]: { ...payments[key1], [key2]: { ...payments[key1][key2], original_price_csc: value } }
      };
      setPayments(p);
    } else if (/originalPriceGO:r([0-9]):/.test(name)) {
      const index = parseInt(name.match(/originalPriceGO:r([0-9]):/)[1]);
      const [key1, key2] = paymentsKeyMapping(index);
      let p = {
        ...payments,
        [key1]: { ...payments[key1], [key2]: { ...payments[key1][key2], original_price_go: value } }
      };
      setPayments(p);
    } else if (name === "discount") {
      setDiscount(e.target.value);
    } else if (name === "customDiscount") {
      if (!validatePercentage(e.target.value)) {
        return;
      } else {
        setDiscount(e.target.value);
      }
    }
    setCounter(counter + 1);
  };
  return (
    <PageLayout title="Изпращане на оферта" {...props}>
      {!loading ? (
        <ContentWrapperWithButtons
          policy={policy}
          handleChange={handleChange}
          insuranceSum={insuranceSum}
          email={email}
          cscService={cscService}
          cscServices={cscServices}
          discounts={discounts}
          discount={discount}
          handleSubmit={handleSubmit}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        >
          <PaymentsCSCGO handleChange={handleChange} handleBlur={handleBlur} payments={payments}></PaymentsCSCGO>
        </ContentWrapperWithButtons>
      ) : (
        <div style={{ color: "grey", fontSize: 14, textAlign: "center" }}>
          {loading && (
            <Typography gutterBottom variant="body1" component="span">
              <p>Зареждане на полица ...</p>
            </Typography>
          )}
          {loading && <CircularProgress size={20} />}{" "}
        </div>
      )}
    </PageLayout>
  );
};

export default withParams(SendOfferCSCGO);
