import React, { useState, useEffect } from "react";
import withValidation from "../../../HOC/withValidation";
import { fields } from "../../../../config/WizardAnnexes/Payment.page.config";
import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import WizardButton from "../../common/WizardButton";
import { setWizardStatus, updateAnnex } from "../../../../services/AnnexesService";
import { error as errorAlert } from "../../../../utils/notifications";
import _get from "lodash.get";

const Payment = props => {
  const [loading, setLoading] = useState(false);
  const [hasPayment, setHasPayment] = useState(true);
  const { annex, nextStep, wizardStatus, setPassedData } = props;

  useEffect(() => {
    props?.setWidth(500);
  }, []);

  const handleSubmit = async () => {
    const annexDBId = annex._id;
    if (!hasPayment) {
      await setWizardStatus({ annexDBId: annexDBId, wizardStatus });
      nextStep();
    } else if (props?.handleValidation()) {
      setLoading(true);
      const { formData } = props;
      try {
        const updatedAnnex = await updateAnnex({ data: formData, annexDBId: annexDBId });
        setPassedData({ annex: _get(updatedAnnex, "data") });
        await setWizardStatus({ annexDBId: annexDBId, wizardStatus });
        nextStep();
      } catch (e) {
        errorAlert(e?.response?.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasPayment) {
      props?.outerErrorsSet({});
    }
  }, [hasPayment]);

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          <Grid item xs={12}>
            <Grid container spacing={0} display="flex" justifyContent="center">
              <FormGroup className="size-6 shipment-gray-box switch-padding has-delivery-switch" sx={{ mb: 1 }}>
                <FormControlLabel control={<Switch onChange={e => setHasPayment(!e.target.checked)} />} label="Няма да се извършва плащане" />
              </FormGroup>
            </Grid>
          </Grid>
          {hasPayment ? props?.renderFields(props, fields) : null}
          <WizardButton disabled={loading} loading={loading} handleSubmit={handleSubmit} />
        </div>
      </Grid>
    </Grid>
  );
};

export default withValidation(Payment, { txtFields: fields });
