import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const ManualAutomaticRadioButtons = props => {
  const { loading, handleChange, selectedTab, disabled } = props;
  return (
    <FormControl fullWidth disabled={disabled || loading}>
      <RadioGroup className="vertical-align" row aria-labelledby="row-radio-buttons" name="row-radio-buttons-group" value={selectedTab}>
        <FormLabel id="row-radio-buttons">Начин на издаване</FormLabel>
        <FormControlLabel value="manual" control={<Radio onChange={handleChange} />} label="Ръчно" />
        <FormControlLabel value="automatic" control={<Radio onChange={handleChange} />} label="Автоматично" />
      </RadioGroup>
    </FormControl>
  );
};

export default ManualAutomaticRadioButtons;
