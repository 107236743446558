import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import boleronWhite from "../../images/tenant/Boleron_icon_white background.svg";
import vivacomWhite from "../../images/tenant/Vivacom_icon_white background.svg";
import boleronDark from "../../images/tenant/Boleron_icon_blue background.svg";
import vivacomDark from "../../images/tenant/Vivacom_icon_black background.svg";
import CheckIcon from "@mui/icons-material/Check";
import "./tenant.switcher.sass";

const TenatSwitcher = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [siteConfig, setSiteConfig] = React.useState(window.localStorage.getItem("siteConfig") || "boleron");
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = tenant => {
    handleClose();
    window.localStorage.setItem("siteConfig", tenant);
    setSiteConfig(tenant);
    handleRefresh();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    const worker = new SharedWorker("/sharedworker.js", { name: "Worker1" });
    const port = worker.port;
    port.postMessage("refresh");
  };

  return (
    <React.Fragment>
      <Tooltip title="tenant settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {siteConfig === "boleron" && <img src={boleronWhite} alt="boleron tenant" />}
          {siteConfig === "vivacom" && <img src={vivacomWhite} alt="vivacom tenant" />}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleChange("boleron")}>
          <img src={boleronDark} style={{ marginRight: 10 }} alt="boleron" />
          Boleron {siteConfig === "boleron" && <CheckIcon className="tenant-check" />}
        </MenuItem>
        <Divider className="tenant-divider" />

        <MenuItem onClick={() => handleChange("vivacom")}>
          <img src={vivacomDark} style={{ marginRight: 10 }} alt="vivacom" /> Vivacom {siteConfig === "vivacom" && <CheckIcon className="tenant-check" />}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default TenatSwitcher;
