import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    orange: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
      contrastText: "#fff"
    }
  }
});

export default theme;
