import _get from "lodash.get";
import _find from "lodash.find";

export const fields = [
  {
    name: "file1",
    type: "file",
    label: "Файл 1:",
    defaultErrorFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      return _get(_find(uploadedFiles, { key: "file1" }), "message", "");
    },
    defaultValueFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "file1" }), "uploaded", false);
      return uploaded ? { name: _get(_find(uploadedFiles, { key: "file1" }), "name", "") } : "";
    }
    // validators: ["required"],
    // errorMessages: ["Полето е задължително"]
  },
  {
    name: "file2",
    type: "file",
    label: "Файл 2:",
    defaultErrorFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      return _get(_find(uploadedFiles, { key: "file2" }), "message", "");
    },
    defaultValueFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "file2" }), "uploaded", false);
      return uploaded ? { name: _get(_find(uploadedFiles, { key: "file2" }), "name", "") } : "";
    }
  },
  {
    name: "file3",
    type: "file",
    label: "Файл 3:",
    defaultErrorFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      return _get(_find(uploadedFiles, { key: "file3" }), "message", "");
    },
    defaultValueFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "file3" }), "uploaded", false);
      return uploaded ? { name: _get(_find(uploadedFiles, { key: "file3" }), "name", "") } : "";
    }
  },
  {
    name: "file4",
    type: "file",
    label: "Файл 4:",
    defaultErrorFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      return _get(_find(uploadedFiles, { key: "file4" }), "message", "");
    },
    defaultValueFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "file4" }), "uploaded", false);
      return uploaded ? { name: _get(_find(uploadedFiles, { key: "file4" }), "name", "") } : "";
    }
  },
  {
    name: "file5",
    type: "file",
    label: "Файл 5:",
    defaultErrorFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      return _get(_find(uploadedFiles, { key: "file5" }), "message", "");
    },
    defaultValueFunction: props => {
      const uploadedFiles = _get(props, `form.sendForSigningUploadedFiles[${props?.objWithEgnAndRequestId?._id}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "file5" }), "uploaded", false);
      return uploaded ? { name: _get(_find(uploadedFiles, { key: "file5" }), "name", "") } : "";
    }
  }
];
