import React, { useEffect, useState } from "react";
import withValidation from "../../../HOC/withValidation";
import { getFields } from "../../../../config/WizardAnnexes/AddAnnex.page.config";
import { Grid } from "@mui/material";
import WizardButton from "../../common/WizardButton";
import { setWizardStatus, updateAnnex } from "../../../../services/AnnexesService";
import { error as errorAlert } from "../../../../utils/notifications";

const fields = getFields();

const AddAnnex = props => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props?.setWidth(500);
  }, []);

  const { policy, nextStep, annex, wizardStatus } = props;

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      setLoading(true);
      const { formData } = props;
      try {
        await updateAnnex({ data: formData, annexDBId: annex?._id });
        await setWizardStatus({ annexDBId: annex?._id?.trim(), wizardStatus });
        nextStep();
      } catch (e) {
        errorAlert(e?.response?.data);
      }
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {props?.renderFields(props, getFields(policy?.productId))}
          <WizardButton disabled={loading} loading={loading} handleSubmit={handleSubmit} />
        </div>
      </Grid>
    </Grid>
  );
};

export default withValidation(AddAnnex, { txtFields: fields });
