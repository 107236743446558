import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, TextField, Grid } from "@mui/material";
import moment from "moment";
import { error } from "../../../utils/notifications";
import _get from "lodash.get";
import ReportsDetailsPanel from "./ReportsDetailsPanel";
import { reportsPath } from "../../../services/PoliciesService";

export default function CommissionAndWeeklyReports(props) {
  const [reports, setReports] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(moment().add(-1, "year").format("YYYY-MM-DD"));
  const [selectedToDate, setSelectedToDate] = useState(moment().format("YYYY-MM-DD"));
  const [titles, setTitles] = useState({ weekly: "Седмични отчети", monthly: "Месечни отчети", commissions: "Обобщена месечна справка" });
  const [periodColumnTitle, setPeriodColumnTitle] = useState({ weekly: "Седмица", monthly: "Meceц", commissions: "Meceц" });
  const [reportType, setReportType] = useState();
  useEffect(() => {
    const fetchData = async () => {
      let type = _get(props, "location.pathname");
      type ? (type = type.split("/")[2]) : null;
      setReportType(type);
      let response;
      if (type === "weekly") {
        response = await reportsPath(selectedFromDate, selectedToDate, "report-weekly");
      } else if (type === "monthly") {
        response = await reportsPath(selectedFromDate, selectedToDate, "report-monthly");
      } else if (type === "commissions") {
        response = await reportsPath(selectedFromDate, selectedToDate, "report-monthly-commissions");
      }
      const data = _get(response, "data");
      if (data) {
        setReports(data);
      }
    };

    fetchData().catch(err => {
      error(err);
    });
  }, [selectedFromDate, selectedToDate]);

  const areDateAccurate = (startDate, endDate) => {
    const startDated = moment(startDate);
    const endDated = moment(endDate);
    const areDatesAccurate = startDated.diff(endDated, "seconds");

    if (!areDatesAccurate) {
      setSelectedFromDate(endDate);
    } else {
      setSelectedFromDate(startDate);
    }
  };

  return (
    <PageLayout title={titles[reportType]} {...props}>
      <Grid container direction="row" alignContent="flex-start" justifyContent="flex-start" spacing={2}>
        <Grid item xs={6} sm={4} md={3} lg={3}>
          <Box mb={2}>
            <TextField
              fullWidth
              onChange={e => areDateAccurate(e.target.value, selectedToDate)}
              id="datetime-local"
              label="Начална дата"
              type="date"
              value={selectedFromDate}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
              InputProps={{
                inputProps: {
                  min: moment(selectedFromDate).subtract(2, "M").format("YYYY-MM-DD"),
                  max: selectedToDate
                }
              }}
            />
            <TextField
              fullWidth
              onChange={e => setSelectedToDate(e.target.value)}
              id="datetime-local"
              label="Крайна дата"
              type="date"
              value={selectedToDate}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  min: selectedFromDate,
                  max: moment(selectedFromDate).add(2, "M").format("YYYY-MM-DD")
                }
              }}
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3}></Grid>
      </Grid>

      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left" width="32%">
                Дата на отчета
              </TableCell>
              <TableCell align="left" width="32%">
                {periodColumnTitle[reportType]}
              </TableCell>
              <TableCell align="right" width="32%">
                Брой файлове
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="100%" align="center" colSpan="4">
                {reports.map(row => (
                  <ReportsDetailsPanel key={row.date} yearMonth={row.yearMonth} row={row} reportType={reportType} />
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
}
