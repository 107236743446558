import _get from "lodash.get";
import moment from "moment";
import { calculateDueDate } from "../../utils/helpers";

export const instalmentsFields = [
  {
    name: "installments",
    value: 1,
    type: "select",
    label: "Брой вноски",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: [
      { label: "Една", value: 1 },
      { label: "Две", value: 2 },
      { label: "Четири", value: 4 },
      { label: "Дванайсет", value: 12 }
    ]
  },
  {
    name: "premium",
    type: "text",
    label: "Нетна премия (без данък)",
    placeholder: "9.80",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "tax",
    type: "text",
    label: "Данък (сума)",
    placeholder: "0.20",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "total",
    type: "text",
    label: "Брутна премия (с данък)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "totalPromo",
    type: "text",
    label: "Брутна премия (с данък, след отстъпка)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"],
    hidden: props => {
      return !_get(props, "form.offlineFormData.hasPromo", false);
    }
  },
  {
    name: "dueDate",
    type: "text",
    label: "Дата на падеж",
    defaultValueFunction: (props, name) => {
      let from = _get(props, "form.offlineFormData.fromTo.from", null);
      from = from ? moment(from) : from;
      const months = _get(name.match(/\d+/), "[0]");
      const installments = _get(props, "installments");
      return calculateDueDate(from, parseInt(months) - 1, installments).format("DD-MM-YYYY");
    },
    placeholder: "24-01-2020",
    validators: ["required", "dateFromIsValid"],
    errorMessages: ["Полето е задължително", "Невалидна дата(формат ден-месец-година)"]
  }
];
