import React, { useState } from "react";
import { TextField } from "@mui/material";
import { txtFields } from "../../../../../config/WizardCSCGO/AutomaticPolicyIssuance.page.config";
import withValidation from "../../../../HOC/withValidation";
import { error as errorAlert, success as successAlert } from "../../../../../utils/notifications";
import _get from "lodash.get";
// import { useSelector } from "react-redux";
// import moment from "moment";
import { getAdditionalPolicyFiles, createCSCPolicy, setWizardStatus } from "../../../../../services/PoliciesService";
import { gray, primary } from "../../../../../utils/colors";
import { ADDITONAL_PDF_SERVICE_URL } from "../../../../../utils/config";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WizardButton from "../../../common/WizardButton";
// import { timeout } from "../../../../../utils/helpers";

const AutomaticPolicyIssuance = props => {
  const [files, setFiles] = useState(false);
  const { variant, objWithEgnAndRequestId, setLoading, loading, nextStep, refreshData, setRadiosDisabled } = props;
  // const { user } = useSelector(state => _get(state, "auth", {}));

  const handleSubmit = async () => {
    if (props?.isFromWizard && props?.handleValidation()) {
      const policyId = _get(props, "objWithEgnAndRequestId._id");
      const requestId = _get(props, "objWithEgnAndRequestId.requestId");
      setLoading(true);
      try {
        if (variant === "queries") {
          const dateFrom = _get(props, "formData.dateFrom");
          const requestPayload = {
            requestId,
            dateFrom: `${dateFrom} 12:00`
          };

          // await timeout(3000); // todo to remove it when backend is ready
          await createCSCPolicy(requestPayload);
          const response = (await getAdditionalPolicyFiles(policyId)) || {};
          const filteredFiles = _get(response, "data").filter(f => f.type === "policy" || f.type === "receiptInsurer" || f.type === "greencard");
          setFiles(filteredFiles);
          setRadiosDisabled(true);
          await setWizardStatus({ policyDBId: props?.objWithEgnAndRequestId?._id, wizardStatus: "step1.2" });
          refreshData();
        }
        setLoading(false);
        successAlert("created successfully!");
      } catch (e) {
        setLoading(false);
        if (e?.response?.data) errorAlert("Error: " + e.response.data);
        else if (e?.message) errorAlert("Error: " + e.message);
        else errorAlert("Error occurred!");
      }
    }
  };

  let fields = txtFields;

  if (loading) return <p className="has-text-centered">Моля изчакайте, файловете се генерират...</p>;

  const pdfIcon = <PictureAsPdfIcon sx={{ mr: 1 }} />;
  if (files)
    return (
      <>
        {files.map?.((file, index) => (
          <div className="additionalFiles vertical-align" key={`additionaFile_${index}`}>
            {pdfIcon}
            <a
              style={{ color: file.hidden ? gray : primary }}
              href={`${ADDITONAL_PDF_SERVICE_URL}/${objWithEgnAndRequestId?._id}/${file.name}/${objWithEgnAndRequestId?.uid}`}
              target="_blank"
              rel="noopener noreferrer"
              className="document-link"
            >
              {file.name}
            </a>
          </div>
        ))}
        <WizardButton disabled={loading} loading={loading} handleSubmit={nextStep} />
      </>
    );
  return (
    <>
      {variant === "queries" && (
        <span className="red size-6" style={{ marginLeft: 14, marginBottom: 5, display: "block" }}>
          В сила от:
        </span>
      )}

      {fields.map(f => (
        <TextField
          sx={{ mb: 1, mt: 1 }}
          key={f.name}
          placeholder={f.placeholder}
          fullWidth
          label={f.label}
          variant="outlined"
          size="small"
          name={f.name}
          value={props?.formData[f.name]}
          onChange={props?.handleChange}
          helperText={props?.errors[f.name]}
          error={!!props?.errors[f.name]}
        />
      ))}
      <WizardButton handleSubmit={handleSubmit} btnText="Създай" />
    </>
  );
};

export default withValidation(AutomaticPolicyIssuance, { txtFields });
