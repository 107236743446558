import React, { useEffect, useState } from "react";
import withValidation from "../../../HOC/withValidation";
import { installmentsTxt, round } from "../../../../utils/helpers";
import { instalmentsFields } from "../../../../config/WizardOFF/Installments.page.config";
import { Box, Button, TextField } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectOfflineFormData, setAndResetOfflineFormData, setOfflineFormData } from "../../../../store/reducers/form";
import _get from "lodash.get";
import { getInsurerName } from "../../../../utils/functions";
import moment from "moment";
import { createOfflinePolicy } from "../../../../services/PoliciesService";
import { error as errorAlert } from "../../../../utils/notifications";

const InstallmentTextFields = props => {
  const { installmentsCountArr } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formData = useSelector(selectOfflineFormData);

  const handleSubmit = async () => {
    const { nextStep, refreshData } = props;
    if (props?.handleValidation()) {
      try {
        setLoading(true);
        dispatch(setOfflineFormData(_get(props, "formData")));
        const payload = formatPayload({ ...formData, ..._get(props, "formData"), installments: props?.installments });
        const response = await createOfflinePolicy(payload);
        dispatch(
          setAndResetOfflineFormData({
            policyOid: _get(response, "data._id"),
            policyNumber: _get(response, "data.policyId"),
            validFrom: _get(formData, "fromTo.from")
          })
        );
        refreshData();
        nextStep();
      } catch (e) {
        setLoading(false);
        if (e?.response?.data) errorAlert("Error: " + e.response.data);
        else if (e?.message) errorAlert("Error: " + e.message);
        else errorAlert("Error occurred!");
      }
    }
  };

  const formatPayload = formData => {
    formData.fullAmountCommission = formData.commissionPercentage > 0 ? round((formData.fullAmountPremium * formData.commissionPercentage) / 100).toString() : "0";
    formData.wizardStatus = "step4";
    if (formData?.installments === 1) {
      formData.premium = formData.fullAmountPremium;
      formData.tax = formData.fullAmountTax;
      formData.total = formData.fullAmountTotal;
      formData.totalPromo = formData.fullAmountPromo;
    } else {
      formData.premium = formData["premium_1"];
      formData.tax = formData["tax_1"];
      formData.total = formData["total_1"];
      formData.totalPromo = formData["totalPromo_1"];
    }
    for (let i = 1; i <= formData["installments"]; i++) {
      formData[`commission_${i}`] = formData?.commissionPercentage > 0 ? round((formData[`premium_${i}`] * formData.commissionPercentage) / 100).toString() : "0";
    }

    Object.keys(formData).forEach(key => {
      if (key === "insurerId") {
        formData.insurerName = getInsurerName(formData?.insurerId);
      }
      if (key === "fromTo") {
        formData.validFrom = _get(formData, "fromTo.from").toDate();
        formData.validTo = _get(formData, "fromTo.to").toDate();
      }
      if (key === "fullAmountCommission") {
        delete formData["egn"];
      }
      const pattern = /_([0-9])/;
      if (pattern.test(key)) {
        const extractedNumber = parseInt(_get(key.match(/\d+/), "[0]", 0));
        if (formData?.installments === 1) {
          if (extractedNumber > 0) delete formData[key];
        } else {
          if (extractedNumber > formData?.installments) delete formData[key];
        }
      }
    });
    delete formData["fromTo"];
    return formData;
  };
  return (
    <>
      {installmentsCountArr.map(inst => {
        return (
          <div key={`installment_${inst}`}>
            <p className="size-4 bold" style={{ margin: "25px 0 0px 15px" }}>
              {installmentsTxt(inst)} ВНОСКА
            </p>
            {instalmentsFields
              .filter(f => !f.hidden?.(props))
              .filter(f => f.type === "text")
              .map(f => {
                return (
                  <TextField
                    sx={{ mb: 1, mt: 1 }}
                    key={`${f.name}_${inst}`}
                    placeholder={f.placeholder}
                    fullWidth
                    label={f.label}
                    variant="outlined"
                    size="small"
                    name={`${f.name}_${inst}`}
                    value={props?.formData[`${f.name}_${inst}`]}
                    onChange={props?.handleChange}
                    helperText={props?.errors[`${f.name}_${inst}`]}
                    error={!!props?.errors[`${f.name}_${inst}`]}
                  />
                );
              })}
          </div>
        );
      })}
      <Box mb={2} mt={2} display="flex" justifyContent="center">
        <Button
          color={loading ? "inherit" : "primary"}
          className="size-3"
          type="submit"
          variant="contained"
          size="large"
          sx={{ width: "fit-content", mb: 1 }}
          onClick={loading ? () => {} : handleSubmit}
        >
          {loading ? "loading..." : "Продължи"}
        </Button>
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(InstallmentTextFields));
