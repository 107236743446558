import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import { Divider, Grid, Button } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Looks1Outlined from "@mui/icons-material/LooksOneOutlined";
import Looks2Outlined from "@mui/icons-material/LooksTwoOutlined";
import Looks3Outlined from "@mui/icons-material/Looks3Outlined";
import Looks4Outlined from "@mui/icons-material/Looks4Outlined";
import Looks5Outlined from "@mui/icons-material/Looks5Outlined";
import Looks6Outlined from "@mui/icons-material/Looks6Outlined";
import { getCurrentWizardStep } from "../../../utils/helpers";
import { gray, success } from "../../../utils/colors";

const icons = {
  1: <Looks1Outlined />,
  2: <Looks2Outlined />,
  3: <Looks3Outlined />,
  4: <Looks4Outlined />,
  5: <Looks5Outlined />,
  6: <Looks6Outlined />
};

const TodoList = ({ options = [], handleAction, refreshData, policy }) => {
  const [selectedOption] = useState(null);
  const currentStep = getCurrentWizardStep(policy?.wizardStatus);
  if (options.length === 0) {
    return null;
  }
  return (
    <List
      sx={{ width: "100%", pb: 0 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          TO DO
        </ListSubheader>
      }
    >
      <Divider />
      {options.map((option, index, options) => {
        if (option.hidden) {
          return null;
        }
        return (
          <React.Fragment key={option.id}>
            <ListItemButton
              disabled={option.disabled}
              selected={option.name === selectedOption}
              onClick={() => {
                handleAction(policy, { hasModal: true, id: "issuePolicyWizard" }, refreshData);
              }}
            >
              <ListItemIcon sx={{ color: currentStep > parseInt(option?.id) ? success : gray }}>{icons[option?.id]}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItemButton>
            {index + 1 !== options.length && <Divider variant="middle" />}
          </React.Fragment>
        );
      })}
      {/*<ListItemButton*/}
      {/*  onClick={() => {*/}
      {/*    //todo*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Grid container spacing={0} display="flex" justifyContent="center">*/}
      {/*    <Button*/}
      {/*      className="size-7"*/}
      {/*      onClick={() => {*/}
      {/*        //todo*/}
      {/*      }}*/}
      {/*      sx={{ mb: 2, mt: 2 }}*/}
      {/*      type="button"*/}
      {/*      variant="contained"*/}
      {/*      color="primary"*/}
      {/*      size="small"*/}
      {/*      // disabled={currentStep === totalSteps || !canContinue} // todo to uncomment*/}
      {/*      disabled={false}*/}
      {/*    >*/}
      {/*      {" "}*/}
      {/*      Премести в издадени{" "}*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*</ListItemButton>*/}
    </List>
  );
};

export default TodoList;
