import React from "react";
import { toast } from "react-toastify";

const errorToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  className: "toast-error",
  bodyClassName: "toast-body",
  autoClose: false
};

const successToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  className: "toast-success",
  bodyClassName: "toast-body",
  autoClose: 4000,
  hideProgressBar: true
};

const warningToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  className: "toast-warning",
  bodyClassName: "toast-body",
  autoClose: 4000,
  hideProgressBar: true
};

export const warning = warningMessage => {
  toast(
    <React.Fragment>
      <b>Внимание!</b> <br /> {warningMessage}
    </React.Fragment>,
    warningToastOptions
  );
};
export const error = errorMessage => {
  toast(
    <React.Fragment>
      <b>Грешка!</b> <br /> {errorMessage}
    </React.Fragment>,
    errorToastOptions
  );
};

export const success = successMessage => {
  toast(
    <React.Fragment>
      <b>Готово!</b> <br /> {successMessage}
    </React.Fragment>,
    successToastOptions
  );
};
