import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PageLayout from "../../layouts/PageLayout";
import { LinearProgress, Box, TextField, Button, Grid, Icon, Paper } from "@mui/material";
import AuthUserService from "../../../services/AuthUserService";
import { validateEmail } from "../../../utils/functions";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

const ForgottenPass = props => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const nextStep = async () => {
    try {
      if (!validateEmail(email)) {
        setLoading(false);
        errorAlert("Въведете валиден имейл");
      } else {
        setLoading(true);
        let res = await AuthUserService.checkRoleDefined(email.trim());
        if (res.data.rolesDefined === true) {
          await AuthUserService.sendPasswordResetEmail(email.trim());
          successAlert("sent successfully!");
        } else {
          errorAlert(res.data.message);
        }
        setLoading(false);
      }
    } catch (err) {
      errorAlert(err);
      setLoading(false);
      setError(err.message);
    }
  };

  return (
    <PageLayout title="Login" {...props}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={1} sm={10} md={4} mt={5}>
          <form style={{ marginLeft: "10px" }}>
            {loading && <LinearProgress />}
            <Box mb={2}>
              <TextField
                type="email"
                style={{ minWidth: 350 }}
                onChange={handleChange}
                name="toEmail"
                variant="outlined"
                size="small"
                label="Изпращане на имейл до:"
                value={email}
              />
            </Box>
            <Button
              type="button"
              onClick={nextStep}
              variant="contained"
              size="small"
              style={{
                color: "#fff",
                width: "min-content",
                float: "right"
              }}
              endIcon={<Icon>send</Icon>}
            >
              Изпрати
            </Button>
          </form>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
export default ForgottenPass;
