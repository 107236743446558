import { request } from "../APIservice";
import { POST, GET } from "../../constants/api";
import fileDownload from "js-file-download";

export const addShipment = async data => {
  const response = await request(POST, `/shipment`, data, {}, true, true);
  return response.data;
};

export const downloadPdfByPolicyId = async form => {
  const { policyId, requestId, courier } = form;
  const r = await request(
    GET,
    `shipment/print`,
    {
      policyId
    },
    {},
    true,
    true,
    false,
    "blob"
  ).then(({ data }) => fileDownload(data, `Policy-${requestId}-${courier}.pdf`));
  return r;
};

export const checkShipment = async (policyId, duePaymentId) => {
  const response = await request(GET, `/shipment/get?policyId=${policyId}&duePaymentId=${duePaymentId}`);
  return response;
};
