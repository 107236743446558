import React from "react";
import withValidation from "../../../../HOC/withValidation";
import { legalEntityFields, extractFieldsFromSections } from "../../../../../config/WizardOFF/Individaul.page.config";
import _get from "lodash.get";
import { Box, Button, TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { setOfflineFormData } from "../../../../../store/reducers/form";

const extractedFields = extractFieldsFromSections(legalEntityFields);
const LegalEntity = props => {
  const { nextStep } = props;
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      dispatch(setOfflineFormData(_get(props, "formData")));
      nextStep();
    }
  };
  return (
    <>
      {Object.keys(legalEntityFields).map(key => {
        return (
          <div key={key}>
            <p>{legalEntityFields[key].text}</p>
            {legalEntityFields[key].fields.map(f => (
              <TextField
                sx={{ mb: 1, mt: 1 }}
                key={f.name}
                placeholder={f.placeholder}
                fullWidth
                label={f.label}
                variant="outlined"
                size="small"
                name={f.name}
                value={props?.formData[f.name]}
                onChange={props?.handleChange}
                helperText={props?.errors[f.name]}
                error={!!props?.errors[f.name]}
              />
            ))}
          </div>
        );
      })}
      <Box mb={2} mt={2} display="flex" justifyContent="center">
        <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
          Продължи
        </Button>
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(LegalEntity, { txtFields: extractedFields }));
