import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { info } from "../../utils/colors";
import _get from "lodash.get";

const PolicyDataInfo = props => {
  const [visible, setVisible] = useState(false);
  const policyData = _get(props, "policy.policyData");
  if (_get(props, "policy.productId") === "LFE") {
    delete policyData.lfeQuestions;
  }
  const toggle = () => {
    setVisible(!visible);
  };
  return (
    <div style={{ overflow: "scroll", maxWidth: "800px" }}>
      <p className="vertical-align">
        <InfoIcon onClick={toggle} htmlColor={info} fontSize="small" style={{ display: "inline-block", cursor: "pointer" }} />
        <span onClick={toggle}>покажи Policy Data</span>
      </p>

      {visible && <pre>{JSON.stringify(policyData, undefined, 2)}</pre>}
    </div>
  );
};

export default PolicyDataInfo;
