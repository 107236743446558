import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Checkbox, FormControlLabel, Grid, Icon, LinearProgress } from "@mui/material";
import { signPDF } from "../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import _get from "lodash.get";
import { success, gray } from "../../../utils/colors";

class SigningPDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base64string: "",
      isBulstrad: false,
      isTwice: false,
      file: null
    };
  }

  onFileChange = e => {
    this.setState({ file: e.target.files[0] });
  };

  handleSubmit = async e => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      // let pdfWindow = window.open("");
      const response = await signPDF({ ...this.state });
      this.setState({ isLoading: false, base64string: `data:application/pdf;base64,${_get(response, "data")}` });
      successAlert("signed successfully!");
    } catch (e) {
      errorAlert(e.message);
    }
  };
  renderForm = () => {
    const { file, isLoading, isBulstrad, isTwice } = this.state;
    const btnDisabled = !file || isLoading;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box mb={2}>
          <input type="file" onChange={this.onFileChange} />
        </Box>
        <Box mb={2} style={{ display: isBulstrad ? "none" : "" }}>
          <FormControlLabel
            label="Два пъти"
            control={
              <Checkbox
                checked={isTwice}
                onChange={() => {
                  this.setState(prevState => {
                    return {
                      isTwice: !prevState.isTwice
                    };
                  });
                }}
              />
            }
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            label="Булстрад"
            control={
              <Checkbox
                checked={isBulstrad}
                onChange={() => {
                  this.setState(prevState => {
                    return {
                      isBulstrad: !prevState.isBulstrad
                    };
                  });
                }}
              />
            }
          />
        </Box>
        <Box mb={2}>
          <Button
            disabled={btnDisabled}
            type="submit"
            variant="contained"
            size="small"
            style={{
              background: !btnDisabled ? success : gray,
              color: "#fff",
              width: "min-content",
              float: "right"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            Изпрати
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      </form>
    );
  };

  render() {
    const { isLoading, base64string } = this.state;
    return (
      <PageLayout title="Подписване на pdf с електронен подпис" {...this.props}>
        <Box m={2}>{isLoading && <LinearProgress />}</Box>

        <Grid item xs={6} sm={4} md={2}>
          {this.renderForm()}
          {base64string && (
            <div style={{ marginTop: 20 }}>
              <a download="signedDoc" href={base64string} title="Download pdf document">
                Свали подписания документ
              </a>
            </div>
          )}
        </Grid>
      </PageLayout>
    );
  }
}

export default SigningPDF;
