import _get from "lodash.get";
import jwt_decode from "jwt-decode";
import { rolesMatched } from "../components/PrivateRoute/PrivateRoute";

export const setTokensToStorage = user => {
  const { email, uid, token, refreshToken, emailVerified, displayName, roles, aud, exp, iat, utmSources } = user;
  const userObj = { email, token, refreshToken, uid, emailVerified, displayName, roles, aud, exp, iat, utmSources };
  window.localStorage.setItem(uid, JSON.stringify(userObj));
};
export const rolesSecurityCheck = token => {
  const roles = _get(jwt_decode(token), "roles", []);
  return rolesMatched(["admin", "employee", "viewer"], roles);
};
