import React, { useEffect } from "react";

import SendDocuments from "../../../SendDocuments";
import { sendDocumentsFields } from "../../../../config/WizardAnnexes/SendDocuments.page.config.js";
import { sendAnnex } from "../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import { timeout } from "../../../../utils/helpers";
import { setWizardStatus } from "../../../../services/AnnexesService";

const SendDocumentsAnnex = props => {
  const { nextStep, refreshData, annex, wizardStatus } = props;

  useEffect(() => {
    props?.setWidth(500);
  }, []);

  const handleSubmit = async data => {
    // const { email, additionalEmails, files } = data; // remove the code for getting the files
    const { policyDBId, email } = data;
    try {
      const annexDBId = annex._id;
      const response = await sendAnnex({ policyDBId, annexDBId, email });

      if (response.status === 200) {
        await setWizardStatus({ annexDBId: annexDBId, wizardStatus, backofficeStatus: "Issued" });
        successAlert("sent successfully!");
        refreshData();
        nextStep();
      }
    } catch (e) {
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  };

  return <SendDocuments {...props} includeHiddenFiles={false} withLayout={false} withCheckboxes={false} txtFields={sendDocumentsFields} handleSubmit={handleSubmit} />;
};

export default SendDocumentsAnnex;
