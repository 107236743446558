import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import routesConfig from "../../../../routes/routesConfig";
import { getStepNumberFromStep } from "../../../../utils/helpers";
import Shipment from "../../../../components/pages/Shipment";

const ShipmentCSCGO = props => {
  const { policy } = props;

  useEffect(() => {
    props?.setWidth(900);
  }, []);

  let cscStepNumber, goStepNumber, btnDisabled, goPolicy;
  if (policy?.productId === "CSC") {
    goPolicy = policy?.goPolicy;
    cscStepNumber = getStepNumberFromStep(policy?.wizardStatus);
    goStepNumber = getStepNumberFromStep(policy?.goPolicy?.wizardStatus);
    btnDisabled = !(cscStepNumber === 1 && goStepNumber === 2);
  }
  if (policy?.productId === "GO") {
    goPolicy = policy;
    cscStepNumber = getStepNumberFromStep(policy?.cscPolicy?.wizardStatus);
    goStepNumber = getStepNumberFromStep(policy?.wizardStatus);
    btnDisabled = !(cscStepNumber === 1 && goStepNumber === 2);
  }

  const handleClick = () => {
    const newTab = window.open(`${routesConfig.shipment.route}/${goPolicy?._id}`);
    newTab.opener = window;
  };

  return (
    <Grid container spacing={0} display="flex" justifyContent="center">
      <Grid item xs={11} lg={11}>
        {btnDisabled && (
          <Grid item xs={12}>
            <p className="has-text-centered" style={{ display: "block" }}>
              Стъпките и за каско, и за гражданска, трябва да са изпълнени до &ldquo;Доставка&ldquo;!
            </p>
          </Grid>
        )}
        {/*винаги закачаме shipment-a към полицата на гражданската -> BOL-5447*/}
        {!btnDisabled && (
          <Shipment
            policyDBId={goPolicy?._id}
            isFromWizard
            canCurrentStepContinue={() => props?.canCurrentStepContinue("", true)}
            cantCurrentStepContinue={props?.cantCurrentStepContinue}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ShipmentCSCGO;
