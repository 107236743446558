import React from "react";
import { useParams } from "react-router-dom";

function withParams(Component) {
  return props => {
    return <Component {...props} params={useParams()} />;
  };
}

export default withParams;
