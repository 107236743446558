import moment from "moment";
import _get from "lodash.get";

export const txtFields = [
  {
    name: "dateFrom",
    label: "Въведи датата",
    defaultValueFunction: props => {
      return moment(_get(props, "objWithEgnAndRequestId.policyData.validFrom", "")).format("DD-MM-YYYY");
    },
    placeholder: "24-01-2020",
    validators: ["required", "dateFromIsValid"],
    errorMessages: ["Полето е задължително", "Невалидна дата(формат ден-месец-година)"]
  },
  {
    name: "sticker",
    label: "Въведи стикер",
    placeholder: "Ex: 00012678",
    validators: ["required", "stickerIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Стикер - трябва да е 8 или 9 цифри."],
    defaultValueFunction: props => {
      return _get(props, "sticker.seq", "");
    }
  },
  {
    name: "greenCard",
    label: "Въведи номер на Зелена карта",
    placeholder: "Ex. 001278",
    validators: ["required", "greenCardIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден номер на Зелена карта - трябва да е 6 цифри."],
    defaultValueFunction: props => {
      return _get(props, "greenCard.seq", "");
    }
  },
  {
    name: "prefix",
    label: "Въведи префикс на Зелена карта",
    placeholder: "Ex. BG/XY...",
    validators: ["required", "prefixIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Префикс на Зелена карта- трябва да има 2 ГЛАВНИ букви."],
    defaultValueFunction: props => {
      return _get(props, "greenCard.prefix", "");
    }
  }
];
