import _get from "lodash.get";
import _find from "lodash.find";

export const attachmentFields = [
  {
    name: "annexAttachment",
    type: "file",
    label: "Прикачи анекс:",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "talonAttachment",
    type: "file",
    label: "Снимка на талон:",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  }
];
