import _get from "lodash.get";
export const fields = [
  {
    name: "carNo",
    label: "РЕГ. НОМЕР",
    placeholder: "CB1111AA",
    validators: ["required", "checkRegNumber"],
    errorMessages: ["Полето е задължително", "Въведете валиден номер на автомобил"],
    type: "text",
    defaultValueFunction: props => {
      return _get(props, "policy.policyData.savedCar.carNo", "");
    }
  },
  {
    name: "talonNo",
    label: "НОМЕР НА ТАЛОН",
    placeholder: "001122345",
    validators: ["required", "checkTalon"],
    errorMessages: ["Полето е задължително", "Въведете валиден номер на талон"],
    type: "text"
  }
];
