import * as React from "react";
import * as colors from "../../../utils/colors";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import _get from "lodash.get";
import { Item } from "../LeftMenu";

export default function NestedItem({ id, label, icon, color, options, open }) {
  const location = useLocation();
  const currentRoute = _get(location, "pathname", "");
  const [expanded, setExpanded] = React.useState(options.map(o => o.link).includes(currentRoute));
  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ListItemButton
        key={id}
        selected={options.map(o => o.link).includes(currentRoute)}
        onClick={handleClick}
        sx={{
          minHeight: 60,
          justifyContent: open ? "initial" : "center",
          py: 0,
          px: 2.5
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            background: colors[`${color}Light`],
            color: colors[color],
            p: 0.5,
            borderRadius: "5px"
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0, whiteSpace: open ? "pre-wrap" : "nowrap" }} primaryTypographyProps={{ variant: "body2" }} />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={!!expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options?.map(o => (
            <Item key={o?.id} id={o?.id} label={o?.label} icon={o?.icon} color={o?.color} link={o?.link} open={open} />
          ))}
        </List>
      </Collapse>
    </>
  );
}
