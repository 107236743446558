import React, { useEffect } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { instalmentsFields } from "../../../../config/WizardOFF/Installments.page.config";
import InstallmentTextFields from "./InstallmentTextFields";
import withValidation from "../../../HOC/withValidation";

const Installments = props => {
  const installmentsSelect = instalmentsFields.find(o => o.name === "installments");
  const installmentTextFields = instalmentsFields.filter(f => f.type === "text");
  let installmentsCountArr = [];
  let newInstallmentFields = [];
  useEffect(() => {
    props?.setWidth(700);
  }, []);
  for (let i = 1; i <= props?.formData["installments"]; i++) {
    if (props?.formData["installments"] !== 1) {
      installmentsCountArr.push(i);
      for (let y = 0; y <= installmentTextFields?.length - 1; y++) {
        newInstallmentFields.push({ ...installmentTextFields[y], name: `${installmentTextFields[y].name}_${i}` });
      }
    }
  }
  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          <FormControl fullWidth key={installmentsSelect.name} error={!!props?.errors[installmentsSelect.name]}>
            <InputLabel id="demo-simple-select-label">{installmentsSelect.label}</InputLabel>
            <Select
              value={props?.formData[installmentsSelect.name]}
              name={installmentsSelect.name}
              onChange={e => props?.handleChange(e)}
              sx={{ mb: 1, mt: 1 }}
              size="small"
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              labelId="demo-simple-select-placeholder-label-label"
              id="outlined-size-small"
              displayEmpty
            >
              {(installmentsSelect.options || []).map(o => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            {!!props?.errors[installmentsSelect.name] && <FormHelperText>{props?.errors[installmentsSelect.name]}</FormHelperText>}
          </FormControl>
          <InstallmentTextFields
            txtFields={newInstallmentFields}
            installmentsCountArr={installmentsCountArr}
            installments={props?.formData[installmentsSelect.name]}
            refreshData={props?.refreshData}
            nextStep={props?.nextStep}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default withValidation(Installments, { txtFields: instalmentsFields.filter(f => f.type === "select") });
