import moment from "moment";
import _get from "lodash.get";

export const txtFields = [
  {
    name: "dateFrom",
    label: "Въведи датата",
    defaultValueFunction: props => {
      return moment(_get(props, "objWithEgnAndRequestId.policyData.validFrom", "")).format("DD-MM-YYYY");
    },
    placeholder: "24-01-2020",
    validators: ["required", "dateFromIsValid"],
    errorMessages: ["Полето е задължително", "Невалидна дата(формат ден-месец-година)"]
  }
];
