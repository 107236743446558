import React, { useEffect, useState } from "react";
import withValidation from "../../../HOC/withValidation";
import { fields } from "../../../../config/WizardAnnexes/CarData.page.config";
import { Grid, LinearProgress } from "@mui/material";
import WizardButton from "../../common/WizardButton";
import { updateAnnex } from "../../../../services/AnnexesService";
import { getFullVehicleData } from "../../../../services/PoliciesService";
import { error as errorAlert } from "../../../../utils/notifications";
import _get from "lodash.get";
import DataChange from "./DataChange";

const CarData = props => {
  const [loading, setLoading] = useState(false);
  const { nextStep, annex, setPassedData } = props;

  useEffect(() => {
    props?.setWidth(500);
  }, []);

  useEffect(() => {
    if (props?.passedData?.annex) {
      props?.setTitle?.("Промяна на данни");
    }
  }, [props?.passedData?.annex]);

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      setLoading(true);
      const { formData, policy } = props;
      const { carNo, talonNo } = formData || {};

      // todo just for test purposes
      // const carNo = "PB3611CX";
      // const talonNo = "010885690";

      try {
        const response = await getFullVehicleData({
          carNo,
          talonNo
        });
        const annexDBId = annex._id;
        const updatedAnnex = await updateAnnex({ data: { annexData: { savedCar: response?.data?.result } }, annexDBId: annexDBId });
        setPassedData({ annex: _get(updatedAnnex, "data") });
      } catch (e) {
        errorAlert(e?.response?.data);
      }
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {loading && <LinearProgress sx={{ mt: 2, mb: 2 }} />}
          {!props.passedData?.annex ? (
            <>
              {props?.renderFields(props, fields)}
              <WizardButton disabled={loading} loading={loading} handleSubmit={handleSubmit} />
            </>
          ) : (
            <DataChange passedData={props.passedData} nextStep={nextStep} wizardStatus={props.wizardStatus} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default withValidation(CarData, { txtFields: fields });
