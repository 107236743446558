import { GET, POST, PUT, DELETE } from "../constants/api";
import axios from "axios";
import qs from "qs";
import { error as errorAlert } from "../utils/notifications";
import firebase from "../utils/firebase";
const { auth } = firebase;

const CancelToken = axios.CancelToken;
const sourceArr = [];
const siteConfig = window.localStorage.getItem("siteConfig") || "boleron";
const baseUrl = process.env[`REACT_APP_API_BASE_URL_${siteConfig.toUpperCase()}`];
const baseUrlMainApi = process.env[`REACT_APP_BASE_URL_${siteConfig.toUpperCase()}`];

export const request = async (method, url, data, headers, accessToken, returnData, mainApi, responseType = "json") => {
  const source = CancelToken.source();
  let token;
  sourceArr.push(source);
  const noBaseUrl = url.includes("http");
  const config = {
    method,
    baseURL: noBaseUrl ? "" : mainApi ? baseUrlMainApi : baseUrl,
    url: `${url}`,
    headers: {},
    cancelToken: source.token,
    data: {},
    responseType,
    params: {},
    paramsSerializer: ""
  };

  if (accessToken) {
    token = await auth?.currentUser?.getIdToken();
    config.headers = { Authorization: `Bearer ${token}` };
  }
  if (headers) {
    config.headers = Object.assign(config.headers, headers);
  }
  if (data) {
    switch (method) {
      case GET:
        config.paramsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" });
        config.params = data;
        break;
      case POST:
        config.data = data;
        break;
      case PUT:
        config.data = data;
        break;
      case DELETE:
        config.data = data;
        break;
      default:
        break;
    }
  }

  if (returnData) {
    return axios(config)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  }
  return axios(config).catch(error => {
    console.log(error);
    errorAlert(error);
  });
};

export const cancelRequests = () => {
  const arrLength = sourceArr.length;
  for (let i = 0; i < arrLength; i++) {
    sourceArr[i].cancel();
  }
};

export const excuteAll = arrayOfReuqests => {
  axios
    .all([arrayOfReuqests])
    .then(
      axios.spread(function () {
        // Both requests are now complete
      })
    )
    .catch(error => {
      console.log(error);
    });
};
