import React, { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { request } from "../../../services/APIservice";
import { POST } from "../../../constants/api";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";

import _get from "lodash.get";

export default function ReportsDetailsPanel(props) {
  const reportType = _get(props, "reportType");
  const date = _get(props, "row.date");
  const reports = _get(props, "row.groupReports");
  const fromTo = _get(props, "row.fromTo");
  const rowYearMonth = _get(props, "yearMonth");

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);
  const [groupReports, setGroupReports] = useState(
    reports.sort((a, b) => {
      if (a.reportName < b.reportName) {
        return -1;
      }
      if (a.reportName > b.reportName) {
        return 1;
      }
      return 0;
    })
  );
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();

  const months = ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"];
  const readableYearMonth = yearMonth => {
    if (yearMonth) {
      const [year, month] = yearMonth.split("-");
      return `${months[parseInt(month) - 1]} ${year}`;
    } else {
      return "";
    }
  };

  const [yearMonth, setYearMonth] = useState(readableYearMonth(rowYearMonth));

  useEffect(() => {
    if (currentRow && currentRow.fromTo) {
      const { from, to } = destructFromTo(currentRow.fromTo);
      setSelectedFromDate(from);
      setSelectedToDate(to);
    }
  }, [currentRow]);

  const destructFromTo = fromTo => {
    const [fromYear, fromMonth, fromDay, toYear, toMonth, toDay] = fromTo.split("-");
    return { from: `${fromYear}-${fromMonth}-${fromDay}`, to: `${toYear}-${toMonth}-${toDay}` };
  };

  const periodReadableFormat = fromTo => {
    if (fromTo) {
      const to = fromTo.split("-")[1].trim();
      const date = moment(to, "DD.MM.YYYY");
      const monthDigit = parseInt(moment(date).format("M"));
      return `${months[monthDigit - 1]} ${moment(date).format("YYYY")}`;
    }
  };
  let message = `Потвърждавате ли прегенерирането на отчет ${reportType !== "commissions" ? currentRow?.insurerId + " " + currentRow?.products : ""}?`;
  if (reportType === "weekly") {
    message += "\n" + "Свързаните Месечен отчет и Обобщена месечна справка ще се прегенерират автоматично, ако са генерирани.";
  } else if (reportType === "monthly") {
    message += "\n" + "Свързаната Обобщена месечна сравка ще се прегенерира автоматично, ако е генерирана.";
  }
  return (
    <React.Fragment>
      <Dialog
        style={{ padding: 7, margin: 12 }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Прегенериране"}</DialogTitle>

        <DialogContent>
          {" "}
          {reportType === "monthly" && (
            <Grid container direction="row" alignContent="flex-start" justifyContent="flex-start">
              <Grid item xs={7} sm={5} md={4} lg={4}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    onChange={e => setSelectedFromDate(e.target.value)}
                    id="datetime-local"
                    label="Начална дата"
                    type="date"
                    value={selectedFromDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="standard"
                    InputProps={{
                      inputProps: {
                        min: moment(selectedFromDate).subtract(2, "M").format("YYYY-MM-DD"),
                        max: selectedToDate
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    onChange={e => setSelectedToDate(e.target.value)}
                    id="datetime-local"
                    label="Крайна дата"
                    type="date"
                    value={selectedToDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputProps: {
                        min: selectedFromDate,
                        max: moment(selectedFromDate).add(2, "M").format("YYYY-MM-DD")
                      }
                    }}
                    variant="standard"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Не</Button>
          <Button
            onClick={() => {
              const data = {
                fromTo: `${selectedFromDate}-${selectedToDate}`,
                insurerId: currentRow.insurerId,
                searchIn: currentRow.searchIn,
                reportName: currentRow.reportName,
                packId: currentRow.packId,
                reportId: currentRow.reportId,
                exportType: currentRow.exportType,
                yearMonth: currentRow.yearMonth
              };

              request(POST, `/exports/reports-for-period-regenerate`, data, {}, true, true, true)
                .then(result => {
                  successAlert("Отчетът е прегенериран успешно");
                  const id = result.data._id;
                  const allReports = [...groupReports];
                  const report = allReports.find(r => r._id === id);
                  report.files = result.data.files;
                  report.searchIn = result.data.searchIn;
                  report._id = result.data._id;
                  report.timestamp = result.data.timestamp;
                  report.insurerId = result.data.insurerId;
                  report.packId = result.data.packId;
                  report.reportId = result.data.reportId;
                  report.exportType = result.data.exportType;
                  report.fromTo = result.data.fromTo;
                  report.products = result.data.products;
                  report.reportName = result.data.reportName;
                  report.total = result.data.total;
                  report.totalCommissions = result.data.totalCommissions;
                  report.__v = result.data.__v;
                  report.yearMonth = result.data.yearMonth;
                  setGroupReports(allReports);
                })
                .catch(() => errorAlert("Грешка при прегенериране на отчета"));

              setDialogOpen(false);
            }}
            autoFocus
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>

      <Table aria-label="collapsible table">
        <TableRow>
          <TableCell style={{ paddingLeft: 4, paddingBottom: 4, paddingTop: 4 }} width="32%" align="left">
            {moment(date).locale("bg").format("DD.MM.YYYY")}
          </TableCell>
          <TableCell width="32%" align="left">
            {reportType !== "weekly" ? yearMonth : fromTo}
          </TableCell>
          <TableCell width="32%" align="right">
            {groupReports.length}
          </TableCell>
          <TableCell align="right" width="80px">
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ marginLeft: 0, marginRight: 0, padding: 0, background: "#FAF9F6" }}>
          <TableCell style={{ paddingLeft: 4, paddingBottom: 1, paddingTop: 1 }} colSpan={4}>
            <Collapse in={open}>
              <Box sx={{ marginBottom: 5, padding: 0 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {reportType !== "commissions" && <TableCell align="left">Застраховател</TableCell>}
                      <TableCell align="center">Сума по отчет</TableCell>
                      <TableCell align="center">Комисион по отчета</TableCell>
                      {reportType !== "commissions" && <TableCell align="center">Период на отчета</TableCell>}
                      <TableCell width="10%" align="center">
                        Изтегли отчет
                      </TableCell>
                      <TableCell width="12%" align="right">
                        Прегенериране на отчет
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupReports.map((row, index) => (
                      <TableRow key={row._id} style={index % 2 ? { background: "#fefefe" } : { paddingLeft: 0 }}>
                        {reportType !== "commissions" && <TableCell textColor="primary">{row.reportName ? row.reportName : row.insurerId}</TableCell>}
                        <TableCell style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }} align="center">
                          {row.total ? row.total.toFixed(2) : "0.00"}
                        </TableCell>
                        <TableCell align="center">{row.totalCommissions ? row.totalCommissions.toFixed(2) : "0.00"}</TableCell>
                        {reportType !== "commissions" && <TableCell align="center">{row.fromTo}</TableCell>}
                        <TableCell align="center">
                          {" "}
                          <Button
                            type="button"
                            disabled={!row.total && !row.totalCommissions}
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => {
                              if (row.total || row.totalCommissions) {
                                location.href = row.files[0];
                              }
                            }}
                          >
                            Изтегли
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            style={{ paddingLeft: 10 }}
                            type="button"
                            onClick={() => {
                              setCurrentRow(row);
                              setDialogOpen(true);
                            }}
                            variant="outlined"
                            size="small"
                            color="success"
                          >
                            Прегенериране
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Table>
    </React.Fragment>
  );
}
