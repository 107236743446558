import React, { useEffect, useState } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import { success } from "../../../../utils/colors";
import { updateVehicleData } from "../../../../services/AnnexesService";

const Done = props => {
  const { close, policy, annex } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props?.setWidth(500);
  }, []);
  useEffect(() => {
    const updateVehicleDataCall = async () => {
      const response = await updateVehicleData({ annexDBId: annex?._id, policyDBId: policy?._id });
      setLoading(false);
    };
    updateVehicleDataCall();
  }, []);

  const handleSubmit = async () => {
    close();
  };

  return (
    <>
      <Box mt={6} display="flex" justifyContent="center">
        <CheckCircleOutlined style={{ color: success }} fontSize="large" />
      </Box>
      <h2 className="policy-created-title">Анексът е създаден</h2>
      <Box mb={2} mt={4} display="flex" justifyContent="center">
        {loading ? (
          <div style={{ width: "60%" }}>
            <LinearProgress sx={{ mt: 2, mb: 6 }} />
          </div>
        ) : (
          <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
            Готово
          </Button>
        )}
      </Box>
    </>
  );
};

export default Done;
