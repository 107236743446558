import _get from "lodash.get";
import moment from "moment";
export const referralStatuses = [
  { value: "", label: "Избери статус" },
  { value: "Pending", label: "Обработва се" },
  { value: "Completed", label: "Завършен" },
  { value: "Cancelled", label: "Отказан" }
];

export const fields = [
  {
    name: "referralEmail",
    type: "text",
    label: "Имейл на referral",
    placeholder: "referral@boleron.com",
    defaultValueFunction: props => {
      return _get(props, "referralEmail", "");
    },
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "referredEmail",
    type: "text",
    label: "Имейл на referred",
    placeholder: "referred@boleron.com",
    defaultValueFunction: props => {
      return _get(props, "referredEmail", "");
    },
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "status",
    type: "select",
    label: "Статус *",
    value: "Pending",
    readOnly: true,
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: referralStatuses
  },
  {
    name: "expiration",
    type: "date",
    label: "Валидност до *",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    value: moment().add(30, "days")
  }
];
