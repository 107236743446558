import React from "react";
import { Navigate } from "react-router-dom";
import _intersection from "lodash.intersection";
import routesConfig from "../../routes/routesConfig";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../store/reducers/auth";

export function rolesMatched(allowedRoles, userRoles, allAllowed) {
  if (allAllowed) {
    return true;
  }
  return _intersection(allowedRoles, userRoles).length > 0;
}

const PrivateRoute = ({ children, userRoles, allowedRoles, allAllowed }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return isAuthenticated ? (
    rolesMatched(allowedRoles, userRoles, allAllowed) ? (
      children
    ) : (
      <Navigate to={routesConfig.notAuthorized.route} />
    )
  ) : (
    <Navigate to={routesConfig.login.route} />
  );
};
export default PrivateRoute;
