import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Icon, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import { createOfflinePolicy } from "../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { connect } from "react-redux";
import { txtFields, selectFields } from "../../../config/OfflinePolicy.page.config";
import _get from "lodash.get";
import { getInsurerName, validateEGN, validateEIKOrEgn, validateEmail, validateName, validatePhone, validateSum } from "../../../utils/functions";
import moment from "moment";
import { round } from "../../../utils/helpers";
import { danger, success, gray } from "../../../utils/colors";

const validationFunctionArr = { validateEGN, validateEIKOrEgn, validateEmail, validateName, validatePhone, validateSum };

class OfflinePolicy extends React.Component {
  constructor(props) {
    super(props);
    const formData = this.initFormData();
    this.state = { isLoading: false, formData, valid: true, percentage: 0, isPromo: false, includedInReports: false };
  }

  initFormData = () => {
    let formData = {};
    txtFields.forEach(txtField => {
      formData[txtField.name] = { value: txtField.value || "" }; // todo to remove
    });
    selectFields.forEach(selectField => {
      formData[selectField.name] = { value: selectField.value || "" };
    });
    // // тестови данни
    // formData.name = { value: "Кристиан Милев" }; // todo to remove
    // formData.egnEik = { value: "8902124440" }; // todo to remove
    // formData.email = { value: "kristianmilew@gamil.com" }; // todo to remove
    // formData.insurerId = { value: "coface" }; // todo to remove
    // formData.phone = { value: "0884286747" }; // todo to remove
    // formData.installments = { value: 2 }; // todo to remove
    // formData.subProduct = { value: "Best Doctors" }; // todo to remove
    // formData.policyId = { value: "123456789" }; // todo to remove
    // formData.city = { value: "Пловдив" }; // todo to remove
    // formData.address = { value: "Мария Кюри 5" }; // todo to remove
    // formData.validFrom = { value: "2022-05-19" }; // todo to remove
    // formData.validTo = { value: "2022-05-23" }; // todo to remove
    // formData.fullAmountPremium = { value: "300" }; // todo to remove
    // formData.fullAmountPromo = { value: "270" }; // todo to remove
    // formData.fullAmountTax = { value: "6.36" }; // todo to remove
    // formData.fullAmountTotal = { value: "306.36" }; // todo to remove
    // formData.premium = { value: "155.82" }; // todo to remove
    // formData.tax = { value: "3.18" }; // todo to remove
    // formData.total = { value: "159" }; // todo to remove
    // formData.totalPromo = { value: "135" }; // todo to remove

    return formData;
  };
  handleTxtChange = (e, name) => {
    const value = e.target.value;
    this.setState(
      prevState => {
        return {
          ...prevState,
          formData: {
            ...prevState.formData,
            [name]: { value, error: false, errorMessage: "" }
          }
        };
      },
      () => {}
    );
  };

  handlePercentageChange = e => {
    let value = e.target.value;
    value = parseInt(value ? value : 0);
    this.setState(
      prevState => {
        return {
          percentage: value >= 0 && value <= 100 ? value : prevState.percentage
        };
      },
      () => {
        const percentage = this.state.percentage;
        if (_get(this.state, `formData.total.value`)) {
          this.setState({
            formData: {
              ...this.state.formData,
              premium: {
                value: round((_get(this.state, `formData.total.value`, 0) * (100 - percentage)) / 100).toString(),
                error: false,
                errorMessage: ""
              },
              tax: {
                value: round((_get(this.state, `formData.total.value`, 0) * percentage) / 100).toString(),
                error: false,
                errorMessage: ""
              }
            }
          });
        }
      }
    );
  };

  handleSubmit = async e => {
    e.preventDefault();
    try {
      const { formData, isPromo, includedInReports } = this.state;
      const txtKeysArr = txtFields
        .filter(field => {
          if (_get(formData, "installments.value") === 1 && field.label.toLowerCase().includes("годишна")) {
            return false;
          }
          if (!isPromo && _get(field, "validators[0]") === "required" && field.name.includes("Promo")) {
            return false;
          }
          return _get(field, "validators[0]") === "required";
        })
        .map(a => a.name);
      const selectKeysArr = selectFields
        .filter(field => {
          return _get(field, "validators[0]") === "required";
        })
        .map(a => a.name);
      const fieldsForValidation = [...txtKeysArr, ...selectKeysArr, "validFrom", "validTo"];
      const allFieldsFilled = fieldsForValidation.every(field => !!_get(formData, `[${field}].value`));
      const newFormData = JSON.parse(JSON.stringify(formData));
      let valid = true;
      if (!allFieldsFilled) {
        fieldsForValidation.forEach(key => {
          const fieldObj = txtFields.find(obj => {
            return obj.name === key;
          });
          if ((key.includes("Promo") && !isPromo) || (_get(formData, "installments.value") === 1 && _get(fieldObj, "label", "").toLowerCase().includes("годишна"))) {
            newFormData[key] = { ...newFormData[key], error: false, errorMessage: "" };
          } else {
            if (!_get(formData, `[${key}].value`)) {
              newFormData[key] = {
                ...newFormData[key],
                error: true,
                errorMessage: "Полето е задължително"
              };
            }
          }
        });
        this.setState({
          formData: {
            ...formData,
            ...newFormData
          }
        });
      } else {
        const txtFieldsForValidation = txtFields.filter(field => {
          return _get(field, "validators[1]");
        });
        txtFieldsForValidation.forEach(el => {
          if (el.name.includes("Promo") && !isPromo) {
            newFormData[el.name] = { ...newFormData[el.name], error: false, errorMessage: "" };
          } else {
            const value = _get(formData, `[${el.name}].value`);
            const validatiorFunction = validationFunctionArr[_get(el, "validators[1]")];
            const isValid = validatiorFunction(value);

            if (!isValid) {
              valid = false;
            }

            newFormData[el.name] = {
              ...newFormData[el.name],
              error: !isValid,
              errorMessage: isValid ? "" : _get(el, "errorMessages[1]")
            };
          }
        });
        const fullAmountTotalValue = _get(formData, "installments.value") === 1 ? _get(formData, "total.value") : _get(formData, "fullAmountTotal.value");
        const fullAmountTotalPromoValue = _get(formData, "installments.value") === 1 ? _get(formData, "totalPromo.value") : _get(formData, "fullAmountPromo.value");
        const fullAmountPremium = _get(formData, "installments.value") === 1 ? _get(formData, "premium.value") : _get(formData, "fullAmountPremium.value");
        const fullAmountTax = _get(formData, "installments.value") === 1 ? _get(formData, "tax.value") : _get(formData, "fullAmountTax.value");
        const fullAmountPromo = isPromo ? fullAmountTotalPromoValue : fullAmountTotalValue;
        const totalPromo = isPromo ? _get(formData, "totalPromo.value") : _get(formData, "total.value");
        this.setState(
          {
            formData: {
              ...formData,
              ...newFormData
            },
            valid,
            isLoading: valid
          },
          async () => {
            if (valid) {
              let data = {};
              for (const [key, val] of Object.entries(this.state.formData)) {
                if (["validTo", "validFrom"].includes(key)) {
                  data[key] = moment(val.value, "YYYY-MM-DD").toDate();
                } else if (key === "insurerId") {
                  data[key] = val.value;
                  data["insurerName"] = getInsurerName(val.value);
                } else {
                  data[key] = val.value;
                }
              }
              const response = await createOfflinePolicy({
                ...data,
                fullAmountPremium,
                fullAmountTax,
                fullAmountPromo,
                totalPromo,
                fullAmountTotal: fullAmountTotalValue,
                includedInReports
              });
              if (response?.status === 200) {
                successAlert("sent successfully!");
                this.setState({
                  formData: this.initFormData()
                });
              }
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
      errorAlert(e.message);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading, formData, valid, isPromo, includedInReports } = this.state;
    const btnDisabled = isLoading;
    return (
      <PageLayout title="" {...this.props}>
        <Box m={2}>{isLoading && <LinearProgress />}</Box>
        <Typography style={{ fontSize: "20px", textAlign: "center", marginBottom: 20 }}>Създаване на офлайн полица</Typography>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={6} sm={6} md={6}>
            <form style={{ marginLeft: "10px" }} onSubmit={this.handleSubmit}>
              {!valid && <Typography style={{ fontSize: "12px", textAlign: "center", marginBottom: 10, color: danger }}>Формата има грешки!</Typography>}
              <FormControlLabel
                label="Полицата е включена в репортите"
                control={
                  <Checkbox
                    checked={includedInReports}
                    onChange={() => {
                      this.setState(
                        prevState => {
                          return {
                            includedInReports: !prevState.includedInReports
                          };
                        },
                        () => {
                          if (!this.state.includedInReports) {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                insuranceType: ""
                              }
                            });
                          }
                        }
                      );
                    }}
                  />
                }
              />
              {selectFields.map((selectField, index) => {
                if (selectField.name === "insuranceType" && !includedInReports) {
                  return null;
                }
                return (
                  <Box key={`select_${selectField.name}_${index}`} mb={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        {selectField.label}
                      </InputLabel>
                      <Select
                        style={{ width: "50%" }}
                        SelectDisplayProps={{
                          style: { fontSize: 16 }
                        }}
                        name={selectField.name}
                        error={!!_get(formData, `[${selectField.name}].error`)}
                        labelId="demo-simple-select-placeholder-label-label"
                        id={`outlined-size-small${index}`}
                        value={_get(formData, `[${selectField.name}].value`, selectField?.value || "")}
                        onChange={e => this.handleTxtChange(e, selectField.name)}
                        displayEmpty
                      >
                        {selectField.options.map((option, index) => {
                          return (
                            <MenuItem key={`${selectField.name}_${index}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                );
              })}
              <FormControlLabel
                label="Полицата е с допълнителна отстъпка"
                control={
                  <Checkbox
                    checked={isPromo}
                    onChange={() => {
                      this.setState(prevState => {
                        return {
                          isPromo: !prevState.isPromo
                        };
                      });
                    }}
                  />
                }
              />
              {txtFields.map((txtField, index) => {
                if (txtField.name.includes("fullAmount") && txtField.name !== "fullAmountCommission" && _get(formData, `installments.value`) === 1) {
                  return null;
                }
                if (txtField.name.includes("Promo") && !isPromo) {
                  return null;
                }
                return (
                  <Box key={`txt_${index}`} mb={2}>
                    <TextField
                      id="outlined-size-small"
                      error={!!_get(formData, `[${txtField.name}].error`)}
                      disabled={!!txtField.disabled}
                      placeholder={txtField.placeholder}
                      name={txtField.name}
                      label={txtField.label}
                      fullWidth={
                        !["total", "tax", "premium", "totalPromo", "fullAmountPremium", "fullAmountTax", "fullAmountTotal", "fullAmountPromo", "fullAmountCommission"].includes(
                          txtField.name
                        )
                      }
                      style={{ width: "50%" }}
                      InputProps={{ style: { fontSize: 12 } }}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      variant="outlined"
                      size="small"
                      value={_get(formData, `[${txtField.name}].value`, "")}
                      onChange={e => this.handleTxtChange(e, txtField.name)}
                      helperText={_get(formData, `[${txtField.name}].errorMessage`)}
                    />
                    {txtField.name === "total" && (
                      <TextField
                        id="outlined-size-small"
                        placeholder="2"
                        name="taxPrecentage"
                        label={"Данък в % (1 до 100)"}
                        InputProps={{ style: { fontSize: 12 } }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        variant="outlined"
                        size="small"
                        value={this.state.percentage}
                        style={{ marginLeft: 10 }}
                        onChange={e => this.handlePercentageChange(e)}
                      />
                    )}
                  </Box>
                );
              })}

              <Box mb={2}>
                <TextField
                  fullWidth
                  style={{ width: "50%" }}
                  error={!!_get(formData, `validFrom.error`)}
                  onChange={e => this.handleTxtChange(e, "validFrom")}
                  id="datetime-local-from"
                  label="Начална дата"
                  type="date"
                  value={_get(formData, `validFrom.value`, "")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  style={{ width: "50%" }}
                  error={!!_get(formData, `validTo.error`)}
                  onChange={e => this.handleTxtChange(e, "validTo")}
                  id="datetime-local-to"
                  label="Крайна дата"
                  type="date"
                  value={_get(formData, `validTo.value`, "")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  error={_get(formData, `comment.error`)}
                  fullWidth
                  id="comment"
                  onChange={e => this.handleTxtChange(e, "comment")}
                  value={_get(formData, `comment.value`, "")}
                  label="Описание/Бележки"
                  multiline
                  rows={4}
                  placeholder="Въведи описание"
                  variant="outlined"
                  helperText={_get(formData, `comment.errorMessage`)}
                />
              </Box>
              <Box mb={2}>
                {!valid && <Typography style={{ fontSize: "12px", textAlign: "center", marginBottom: 10, color: danger }}>Формата има грешки!</Typography>}
                <Button
                  disabled={btnDisabled}
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    background: !btnDisabled ? success : gray,
                    color: "#fff",
                    width: "min-content",
                    float: "right"
                  }}
                  endIcon={<Icon>arrow_right</Icon>}
                >
                  Изпрати
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(OfflinePolicy);
