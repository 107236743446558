import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { gray, primary, white } from "../../utils/colors";
import { addCommentToPolicy } from "../../services/PoliciesService";
import moment from "moment/moment";
import { error as errorAlert, success as successAlert } from "../../utils/notifications";

export default function Comment(props) {
  const { commentContent, commentDate, email = "", policyOid } = props;
  const [comment, setComment] = useState(commentContent);
  const submitComment = async content => {
    setComment(content);
    try {
      if (policyOid) {
        await addCommentToPolicy({
          policyOid,
          comment: content,
          lastModified: moment().format("YYYY-MM-DD HH:mm:ss"),
          lastModifiedUser: email,
          lastModifiedAction: "commentAdded"
        });
      }
      // if (duePaymentId) {
      //   await addCommentToDuePayment({
      //     duePaymentId,
      //     comment: content,
      //     lastModified: moment().format("YYYY-MM-DD HH:mm:ss"),
      //     lastModifiedUser: email,
      //     lastModifiedAction: "commentAdded"
      //   });
      // }

      props.refreshData();
      successAlert("Коментарът беше добавен!");
    } catch (e) {
      errorAlert(JSON.stringify(e));
    }
  };
  return (
    <React.Fragment>
      <>
        <TextField
          style={{ width: "100%", marginTop: 10 }}
          label="Остави коментар"
          rows={6}
          multiline
          onChange={e => setComment(e.target.value)}
          value={comment}
          variant="outlined"
        />
        <>{commentDate && commentContent ? <p style={{ fontSize: 10, color: gray }}>comment date:{`${commentDate}`}</p> : ""}</>
        <Button
          onClick={() => {
            submitComment(comment);
          }}
          variant="contained"
          size="small"
          style={{
            marginTop: 20,
            background: primary,
            color: white,
            width: "min-content",
            float: "right",
            cursor: "pointer"
          }}
        >
          Коментирай
        </Button>
      </>

      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}
