import _get from "lodash.get";
import { insurersList } from "../helpers/insurers";
import * as React from "react";

export const stickerFields = {
  section1: {
    text: "Тип и застраховател",
    fields: [
      {
        name: "type",
        type: "select",
        label: "Тип *",
        validators: ["required"],
        errorMessages: ["Полето е задължително"],
        options: [
          { label: "Стикер ГО", value: "goSticker" },
          { label: "Зелена карта ГО", value: "goGreenCard" }
        ]
      },
      {
        name: "insurerId",
        type: "select",
        label: "Застраховател *",
        validators: ["required"],
        errorMessages: ["Полето е задължително"],
        options: insurersList
      }
    ]
  },
  section2: {
    text: "Поредни номера и префикс",
    fields: [
      {
        name: "prefix",
        type: "text",
        label: "Въведи префикс на Зелена карта",
        placeholder: "Ex. BG/XY...",
        validators: ["required", "prefixIsValid"],
        errorMessages: ["Полето е задължително", "Невалиден Префикс на Зелена карта- трябва да има 2 ГЛАВНИ букви."],
        hidden: props => {
          return props?.formData?.type === "goSticker";
        }
      },
      {
        name: "from",
        type: "text",
        label: "Номер от:",
        placeholder: "10000001",
        validators: ["required", "validateNumberFrom"],
        errorMessages: props => {
          if (props?.formData?.type === "goGreenCard") {
            return ["Полето е задължително", "Номер от трябва да е 6 цифри."];
          }
          return ["Полето е задължително", "Номер от трябва да е 8 или 9 цифри."];
        }
      },
      {
        name: "to",
        type: "text",
        label: "Номер до:",
        placeholder: "10000002",
        validators: ["validateNumberTo"],
        errorMessages: props => {
          if (props?.formData?.type === "goGreenCard") {
            return ["Номер до трябва да е по-голямо от Номер от и да е 6 цифри."];
          }
          return ["Номер до трябва да е по-голямо от Номер от и да е 8 цифри."];
        }
      }
    ]
  }
};

export const extractFieldsFromSections = sectionsObj => {
  let fields = [];
  Object.keys(sectionsObj).forEach(key => {
    fields = [...fields, ..._get(sectionsObj, `[${key}].fields`)];
  });
  return fields;
};
