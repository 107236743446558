import React from "react";
import { Box, Button, Icon, LinearProgress } from "@mui/material";
import Modal from "../Modal";
import { success, gray } from "../../utils/colors";
import { connect } from "react-redux";

class ResultModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [`${props.modalName}IsLoading`]: false
    };
  }
  handleLoading = (modalName, isLoading) => {
    this.setState({
      [`${modalName}IsLoading`]: isLoading
    });
  };
  render() {
    const { modalName, modalTitle, btnTxt, btnColor, btnDisabled, isModalOpen, toggle, handle, children } = this.props;
    const props = { ...this.props, handleLoading: this.handleLoading };
    const btnDisabledFromParent = btnDisabled || false;
    const isLoading = this.state[`${this.props.modalName}IsLoading`];
    const disabled = !!(btnDisabledFromParent || isLoading);
    const content = (
      <div>
        <Box mt={2} mb={2}>
          {isLoading && <LinearProgress />}
        </Box>
        {children && <Box mb={2}>{children}</Box>}
        <Box mb={2}>
          <Button
            onClick={() => handle(props)}
            disabled={disabled}
            variant="contained"
            size="small"
            style={{
              background: !disabled ? (btnColor ? btnColor : success) : gray,
              color: "#fff",
              width: "min-content",
              float: "right",
              cursor: "pointer"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            {btnTxt}
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      </div>
    );
    return isModalOpen ? (
      <Modal open={isModalOpen} setOpen={() => toggle(modalName)} title={modalTitle} description="" icon>
        {content}
      </Modal>
    ) : null;
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(ResultModal);
