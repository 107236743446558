import * as React from "react";
import { Navigate } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Grid, Icon, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import AuthUserService from "../../../services/AuthUserService";
import _get from "lodash.get";
import routesConfig from "../../../routes/routesConfig";
import { connect } from "react-redux";
import { success, gray } from "../../../utils/colors";
import { addAuditTrail } from "../../../services/AddAuditTrail";
import { rolesSecurityCheck } from "../../../utils/auth";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      siteConfig: window.localStorage.getItem("siteConfig") || "boleron"
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    try {
      const { email, password } = this.state;
      this.setState({ isLoading: true }); // todo da se napravi s reducer
      const response = await AuthUserService.loginWithEmail(email, password);
      const user = _get(response, "user");
      if (user && rolesSecurityCheck(_get(user, "accessToken"))) {
        successAlert("logged in successfully!");
        await addAuditTrail();
      } else {
        this.setState({ isLoading: false });
        errorAlert("No user found");
      }
    } catch (e) {
      errorAlert(e?.message);
      this.setState({ isLoading: false });
    }
  };
  handleTxtChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  };

  render() {
    const { isLoading, email, password, siteConfig } = this.state;
    const btnDisabled = !(email && password);
    // const user = _get(this.props, "auth.user");
    const isAuthenticated = _get(this.props, "auth.isAuthenticated");
    return isAuthenticated ? (
      <Navigate to={routesConfig.home.route} />
    ) : (
      <PageLayout hideNavigation>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="h6">Login</Typography>
            <form style={{ marginLeft: "10px" }} onSubmit={this.handleSubmit}>
              {isLoading && <LinearProgress />}
              <Box mb={2} mt={10}>
                <TextField value={email} onChange={e => this.handleTxtChange(e, "email")} fullWidth label="Email" id="outlined-size-small" variant="outlined" size="small" />
              </Box>{" "}
              <Box mb={2}>
                <TextField
                  value={password}
                  onChange={e => this.handleTxtChange(e, "password")}
                  fullWidth
                  label="Парола"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  type="password"
                />
              </Box>
              <Box mb={2}>
                <Select
                  value={siteConfig}
                  name="siteConfig"
                  onChange={e => {
                    window.localStorage.setItem("siteConfig", e.target.value);
                    this.handleTxtChange(e, "siteConfig");
                    location.reload();
                  }}
                  sx={{ mb: 1, mt: 1 }}
                  size="small"
                  MenuProps={{ sx: { maxHeight: 300 } }}
                  SelectDisplayProps={{
                    style: { fontSize: 16 }
                  }}
                  labelId="demo-simple-select-placeholder-label-label"
                  id="outlined-size-small"
                  displayEmpty
                >
                  {[
                    { label: "boleron", value: "boleron" },
                    { label: "vivacom", value: "vivacom" }
                  ].map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: !btnDisabled ? success : gray,
                  color: "#fff",
                  width: "min-content",
                  float: "right"
                }}
                endIcon={<Icon>arrow_right</Icon>}
              >
                Вход
              </Button>
            </form>
            <Grid item ml={1}>
              <span className="link" style={{ display: "block", fontSize: "11px" }} onClick={() => this.props.navigate(`${routesConfig.forgottenPass.route}`)}>
                Забравена парола
              </span>
            </Grid>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(Login);
