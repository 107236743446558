import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Grid, Icon, LinearProgress, TextField } from "@mui/material";
import { cloneBDPolicy } from "../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import _get from "lodash.get";
import { connect } from "react-redux";
import { success, gray } from "../../../utils/colors";

class CloneBestDoctors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyId: "",
      newPolicyId: "",
      newDuePaymentPrice: ""
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      const response = await cloneBDPolicy({ ...this.state });
      console.log(_get(response, "data"));
      this.setState({ isLoading: false });
      successAlert("cloned successfully!");
    } catch (e) {
      errorAlert(e.message);
    }
  };
  handleTxtChange = (e, name) => {
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  renderForm = () => {
    const { policyId, newPolicyId, newDuePaymentPrice, isLoading } = this.state;
    const btnDisabled = !newDuePaymentPrice || !newPolicyId || !policyId || isLoading;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box mb={2}>
          <TextField
            id="outlined-size-small"
            placeholder="Пример: BD92101368"
            name="policyId"
            label="ID на полицата"
            style={{ width: "50%" }}
            InputProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            variant="outlined"
            size="small"
            value={policyId}
            onChange={e => this.handleTxtChange(e, "policyId")}
          />
        </Box>
        <Box mb={2}>
          <TextField
            id="outlined-size-small"
            placeholder="Пример: BD92101368"
            name="newPolicyId"
            label="ID на новата полицата"
            style={{ width: "50%" }}
            InputProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            variant="outlined"
            size="small"
            value={newPolicyId}
            onChange={e => this.handleTxtChange(e, "newPolicyId")}
          />
        </Box>
        <Box mb={2}>
          <TextField
            id="outlined-size-small"
            placeholder="107.30"
            name="newDuePaymentPrice"
            label="новата цена на вноската"
            style={{ width: "50%" }}
            InputProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            variant="outlined"
            size="small"
            value={newDuePaymentPrice}
            onChange={e => this.handleTxtChange(e, "newDuePaymentPrice")}
          />
        </Box>
        <Box mb={2}>
          <Button
            disabled={btnDisabled}
            type="submit"
            variant="contained"
            size="small"
            style={{
              background: !btnDisabled ? success : gray,
              color: "#fff",
              width: "min-content",
              float: "right"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            Изпрати
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      </form>
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      <PageLayout title="Клониране на полица Бест Докторс" {...this.props}>
        <Box m={2}>{isLoading && <LinearProgress />}</Box>

        <Grid item xs={6} sm={4} md={4}>
          {this.renderForm()}
        </Grid>
      </PageLayout>
    );
  }
}

export default CloneBestDoctors;
