import colors from "../styles/colors.module.scss";

export const {
  black,
  white,
  primary,
  success,
  info,
  warning,
  danger,
  teal,
  pink,
  purple,
  gray,
  yellow,
  deepOrange,
  primaryLight,
  primaryLessLight,
  successLight,
  infoLight,
  warningLight,
  dangerLight,
  tealLight,
  pinkLight,
  purpleLight,
  grayLight,
  grayBorder,
  yellowLight,
  deepOrangeLight
} = colors;
