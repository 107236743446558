import React from "react";
import { Box, Button, TextField, Grid, Checkbox } from "@mui/material";
import { premiumFields } from "../../../../config/WizardOFF/Premium.page.config";
import withValidation from "../../../HOC/withValidation";
import { setOfflineFormData } from "../../../../store/reducers/form";
import _get from "lodash.get";
import { connect, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";

const Premium = props => {
  const { nextStep } = props;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      dispatch(
        setOfflineFormData({
          ..._get(props, "formData"),
          fullAmountPromo: _get(props, "formData.fullAmountPromo", _get(props, "formData.fullAmountTotal"))
        })
      );
      nextStep();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {premiumFields
            .filter(f => !f.hidden?.(props))
            .map(f => {
              switch (f.type) {
                case "text":
                case "percentage":
                  return (
                    <TextField
                      sx={{ mb: 1, mt: 1 }}
                      key={f.name}
                      placeholder={f.placeholder}
                      fullWidth
                      label={f.label}
                      variant="outlined"
                      size="small"
                      name={f.name}
                      value={props?.formData[f.name]}
                      onChange={f.type === "percentage" ? props?.handlePercentageChange : props?.handleChange}
                      helperText={props?.errors[f.name]}
                      error={!!props?.errors[f.name]}
                    />
                  );
                case "checkbox":
                  return <FormControlLabel key={f.name} label={f.label} control={<Checkbox name={f.name} checked={props?.formData[f.name]} onChange={props?.handleCheckbox} />} />;
              }
            })}
          <Box mb={2} mt={2} display="flex" justifyContent="center">
            <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
              Продължи
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(Premium, { txtFields: premiumFields }));
