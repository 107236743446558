import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, LinearProgress, Icon } from "@mui/material";
import _get from "lodash.get";
import { connect } from "react-redux";
import DotsMenu from "../../DotsMenu";
import DetailPanel from "./DetailPanel";
import MaterialTable from "material-table";
import { getPurchases } from "../../../services/PoliciesService";
import withMultipleModals from "../../HOC/withMultipleModals";
import { danger, success } from "../../../utils/colors";

class Purchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.tableRef = React.createRef();
  }
  async componentDidMount() {
    const result = await getPurchases({});
    this.setState({
      data: _get(result, "data", []),
      loading: false
    });
  }
  // handleTxtChange = (e, name) => {
  //   const value = e.target.value;
  //   this.setState({ [name]: value });
  // };

  handleAction = (purchase, option) => {
    this.setState({
      purchase,
      dotMenuItemOption: option.name
    });
    if (option.hasModal) {
      this.props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      // this.props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    }
  };

  render() {
    const { loading, data } = this.state;
    const user = _get(this.props, "auth.user");
    return (
      <PageLayout title="Покупки" {...this.props}>
        <Box m={2}>{loading && <LinearProgress />}</Box>
        <MaterialTable
          title="Покупки"
          tableRef={this.tableRef}
          columns={[
            {
              title: "purchaseRequestId",
              field: "purchaseRequestId",
              render: purchase => {
                const { purchaseRequestId } = purchase;
                return <p className="font-small">{purchaseRequestId}</p>;
              }
            },
            {
              title: "amount",
              field: "amount",
              render: purchase => {
                const { amount } = purchase;
                return (
                  <>
                    <b style={{ color: danger }}>{amount.toFixed(2)}</b> <span className="font-small"> лв.</span>
                  </>
                );
              }
            },
            {
              title: "total",
              field: "total",
              render: purchase => {
                const { total } = purchase;
                return (
                  <>
                    <b style={{ color: danger }}>{total.toFixed(2)}</b> <span className="font-small"> лв.</span>
                  </>
                );
              }
            },
            {
              title: "tax",
              field: "tax",
              render: purchase => {
                const { tax } = purchase;
                return (
                  <>
                    <b style={{ color: danger }}>{tax.toFixed(2)}</b> <span className="font-small"> лв.</span>
                  </>
                );
              }
            },
            {
              title: "Activated",
              field: "isFulfilled",
              render: purchase => {
                const { isFulfilled } = purchase;
                return <>{isFulfilled ? <Icon style={styles.successIcon}>check</Icon> : <Icon style={styles.dangerIcon}>close</Icon>}</>;
              }
            },
            {
              title: "actions",
              sorting: false,
              field: "",
              render: purchase => {
                return (
                  <DotsMenu
                    options={[
                      {
                        id: "someAction",
                        name: "some action",
                        disabled: true,
                        data: purchase,
                        handleAction: option => this.handleAction(purchase, option),
                        hasModal: true
                      }
                    ]}
                  />
                );
              }
            }
          ]}
          data={data}
          isLoading={loading}
          options={{
            sorting: false, //true
            filtering: false, //,
            search: false, //true
            pageSize: 50,
            pageSizeOptions: [10, 20, 50, 100, 200],
            padding: "dense",
            debounceInterval: 1000
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} от {count}",
              labelRowsSelect: "реда"
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
              searchTooltip: "Търси",
              searchPlaceholder: "Търси"
            },
            header: {
              actions: ""
            },
            body: {
              emptyDataSourceMessage: "No records to display",
              filterRow: {
                filterTooltip: "Филтър"
              }
            }
          }}
          detailPanel={rowData => {
            return <DetailPanel purchase={rowData} user={user} />;
          }}
        />
      </PageLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
const styles = {
  successIcon: { color: success, marginRight: 2, fontSize: "1rem" },
  dangerIcon: { color: danger, marginRight: 2, fontSize: "1rem" }
};
export default connect(mapStateToProps)(withMultipleModals(Purchases, [{ id: "someModal" }]));
