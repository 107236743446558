import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { addReferral, getReferrals, removeReferral } from "../../../services/PoliciesService";
import { Box, Button, Grid, Icon, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import _get from "lodash.get";
import { danger, success, gray } from "../../../utils/colors";
import withValidation from "../../HOC/withValidation";
import { fields } from "../../../config/AddReferral.page.config";
import moment from "moment/moment";

const referral = {
  referralDiscount: "15%",
  referredDiscount: "10%"
};

class AddReferal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoading: false
    };
  }
  async componentDidMount() {
    const response = await getReferrals();
    this.setState({
      list: _get(response, "data", [])
    });
  }

  handleRemove = async id => {
    const response = await removeReferral({
      id
    });
    const { list } = this.state;
    const removeIndex = list.map(item => item["_id"]).indexOf(_get(response, "data._id"));
    const newList = [...list];
    if (removeIndex >= 0) {
      newList.splice(removeIndex, 1);
      this.setState({ list: newList });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.props?.handleValidation()) {
      try {
        const {
          formData: { referralEmail, referredEmail, status, expiration }
        } = this.props || {};

        this.setState({ isLoading: true });
        const response = await addReferral({
          referralEmail,
          referredEmail,
          expiration,
          status,
          referredDiscount: referral?.referredDiscount
        });
        if (_get(response, "status") === 200) {
          this.setState(prevState => {
            return { isLoading: false, list: [...prevState.list, _get(response, "data")], code: "", percentage: 0 };
          });
          successAlert("sent successfully!");
        }
      } catch (e) {
        errorAlert(e?.response?.data || e?.message);
        this.setState({ isLoading: false });
      }
    }
  };
  getStatusColor = status => {
    switch (status) {
      case "None":
        return "gray-light";
      case "Pending":
        return "warning";
      case "Cancelled":
        return "danger";
      case "Completed":
        return "success";
      default:
        return "gray";
    }
  };
  render() {
    const { isLoading, list } = this.state;
    const btnDisabled = isLoading;
    return (
      <PageLayout title="Добавяне на реферал" {...this.props}>
        <Box m={2}>{isLoading && <LinearProgress />}</Box>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Box mb={2} style={{ display: "flex", flexDirection: "column" }}>
              {this.props?.renderFields(this.props, fields)}
              {/*<UserSelect />*/}
              <br />
              <Button
                onClick={this.handleSubmit}
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: !btnDisabled ? success : gray,
                  color: "#fff",
                  width: "min-content",
                  alignSelf: "flex-end"
                }}
                endIcon={<Icon>arrow_right</Icon>}
              >
                Запиши
              </Button>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <List dense>
              {list.map(item => (
                <ListItem key={item["_id"]}>
                  <ListItemIcon>
                    <Icon style={{ color: danger }}>connect_without_contact_icon</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.referralEmail} -> ${item.referredEmail} (exp: ${item.expiration ? moment(item.expiration).format("DD-MM-YYYY") : " - "}) статус: ${
                      item.status
                    }`}
                    secondary={
                      <>
                        <Icon className={`status-icon ${this.getStatusColor(item.status)}`}>circle</Icon>{" "}
                        <span className={`status-text ${this.getStatusColor(item.status)}`}>{item.status}</span>
                      </>
                    }
                  />
                  <ListItemIcon>
                    <Icon style={{ color: danger, cursor: "pointer" }} onClick={() => this.handleRemove(_get(item, "_id", 0))}>
                      clear
                    </Icon>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

export default withValidation(AddReferal, { txtFields: fields });
