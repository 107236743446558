import React, { useEffect } from "react";
import { Box, Button, TextField, Grid, Select, MenuItem, FormControl, InputLabel, FormLabel, FormHelperText, Checkbox } from "@mui/material";
import { insuranceDetailsFields, extractFieldsFromSections } from "../../../../config/WizardOFF/InsuranceDetails.page.config";
import withValidation from "../../../HOC/withValidation";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { white } from "../../../../utils/colors";
import { connect, useDispatch } from "react-redux";
import { setOfflineFormData } from "../../../../store/reducers/form";
import _get from "lodash.get";

const extractedFields = extractFieldsFromSections(insuranceDetailsFields);
const InsuranceDetails = props => {
  const { nextStep } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    props?.setWidth(700);
  }, []);

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      dispatch(setOfflineFormData(_get(props, "formData")));
      nextStep();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {Object.keys(insuranceDetailsFields).map(key => {
            return (
              <React.Fragment key={key}>
                <p>{insuranceDetailsFields[key].text}</p>
                {insuranceDetailsFields[key].fields.map(f => {
                  switch (f.type) {
                    case "text":
                      return (
                        <TextField
                          sx={{ mb: 1, mt: 1 }}
                          key={f.name}
                          placeholder={f.placeholder}
                          fullWidth
                          label={f.label}
                          variant="outlined"
                          size="small"
                          name={f.name}
                          value={props?.formData[f.name]}
                          onChange={props?.handleChange}
                          helperText={props?.errors[f.name]}
                          error={!!props?.errors[f.name]}
                        />
                      );
                    case "select":
                      return (
                        <FormControl fullWidth key={f.name} error={!!props?.errors[f.name]}>
                          <InputLabel>{f.label}</InputLabel>
                          <Select
                            value={props?.formData[f.name]}
                            name={f.name}
                            onChange={e => props?.handleChange(e)}
                            sx={{ mb: 1, mt: 1 }}
                            size="small"
                            MenuProps={{ sx: { maxHeight: 300 } }}
                            SelectDisplayProps={{
                              style: { fontSize: 16 }
                            }}
                            labelId="demo-simple-select-placeholder-label-label"
                            id="outlined-size-small"
                            displayEmpty
                          >
                            {(f.options || []).map(o => (
                              <MenuItem key={o.value} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!props?.errors[f.name] && <FormHelperText>{props?.errors[f.name]}</FormHelperText>}
                        </FormControl>
                      );
                    case "radio":
                      return (
                        <FormControl fullWidth key={f.name}>
                          <RadioGroup className="vertical-align" row aria-labelledby="row-radio-buttons" name={f.name} value={props?.formData[f.name]}>
                            <FormLabel id="row-radio-buttons">{f.label}</FormLabel>
                            {(f.options || []).map(o => (
                              <FormControlLabel key={o.value} value={o.value} control={<Radio onChange={props?.handleChange} />} label={o.label} />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      );
                    case "dateRange":
                      return (
                        <LocalizationProvider dateAdapter={AdapterDayjs} key={f.name}>
                          <FormLabel>{f.label}</FormLabel>
                          {(f.options || []).map((o, index) => (
                            <DesktopDatePicker
                              // disableMaskedInput
                              key={o.name}
                              sx={{ mr: 2 }}
                              label={o.label}
                              value={props?.formData[f.name][o.name]}
                              onChange={newValue => props?.handleDateRangeChange(f.name, o.name, newValue)}
                              inputFormat="DD/MM/YYYY"
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  error={!!props?.errors[f.name]}
                                  style={{ marginRight: index === 1 ? 0 : 5, marginBottom: 5, backgroundColor: white, maxWidth: "49%" }}
                                  size="small"
                                />
                              )}
                            />
                          ))}
                          {!!props?.errors[f.name] && (
                            <p className="red size-6" style={{ margin: "4px 14px 0 14px" }}>
                              {props?.errors[f.name]}
                            </p>
                          )}
                        </LocalizationProvider>
                      );

                    case "checkbox":
                      return (
                        <FormControlLabel
                          key={f.name}
                          label={f.label}
                          // label="Полицата е включена в репортите"
                          control={<Checkbox name={f.name} checked={props?.formData[f.name]} onChange={props?.handleCheckbox} />}
                        />
                      );
                  }
                })}
              </React.Fragment>
            );
          })}
          <Box mb={2} mt={2} display="flex" justifyContent="center">
            <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
              Продължи
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(InsuranceDetails, { txtFields: extractedFields }));
