import React from "react";
import { error, success as successAlert } from "../../utils/notifications";
import { requestError } from "../../utils/helpers";
import moment from "moment";
import _get from "lodash.get";

const withConfirmationModal = (PassedComponent, data) => {
  const { generatePayloadRequest, apiRequest, modalName, successMessage } = data;
  return class WithModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        egn: "",
        comment: "",
        showEgnCheck: false,
        egnValidationError: false
      };
    }
    onChange = e => {
      this.setState({
        egn: e.target.value.trim(),
        egnValidationError: false
      });
    };
    handle = async props => {
      if (!this.state.showEgnCheck) {
        this.setState({
          showEgnCheck: true
        });
      } else {
        if (this.state.egn === this.props.objWithEgnAndRequestId.egn) {
          this.setState({
            egnValidationError: false,
            egn: ""
          });
          props.handleLoading(modalName, true);
          try {
            let requestPayload = generatePayloadRequest(props);
            // await timeout(3000);
            await apiRequest({
              ...requestPayload,
              lastModified: moment().format("YYYY-MM-DD HH:mm:ss"),
              lastModifiedUser: _get(props, "auth.user.email", ""),
              lastModifiedAction: modalName.replace("Modal", "")
            });
            successAlert(successMessage);
            this.props.refreshTable();
            props.handleLoading(modalName, false);
            this.props.close(modalName);
          } catch (err) {
            props.handleLoading(modalName, false);
            let errorMessage = requestError(err);
            error(errorMessage);
          }
        } else {
          this.setState({
            egnValidationError: true
          });
        }
      }
    };

    render() {
      return <PassedComponent {...this.props} {...this.state} modalName={modalName} handle={this.handle} onChange={this.onChange} />;
    }
  };
};

export default withConfirmationModal;
