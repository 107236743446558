import { Box, Button } from "@mui/material";
import { gray, primary, white } from "../../../utils/colors";
import * as React from "react";

const WizardButton = ({ disabled, loading, handleSubmit, btnText }) => {
  return (
    <Box mb={2} mt={2} display="flex" justifyContent="center">
      <Button
        className="size-6"
        type="button"
        variant="contained"
        color="primary"
        size="medium"
        sx={{ width: "fit-content", mb: 1, backgroundColor: disabled || loading ? gray : primary, color: white }}
        disabled={disabled}
        onClick={loading ? () => {} : handleSubmit}
      >
        {loading ? "loading..." : btnText || "Продължи"}
      </Button>
    </Box>
  );
};

export default WizardButton;
