import { request } from "./APIservice";
import { POST } from "../constants/api";
import _get from "lodash.get";

export const getStickers = async query => {
  const { filters, orderBy, orderDirection, page, pageSize, search } = query;
  let filtersArr = [];
  if (filters) {
    filters.forEach(f => {
      filtersArr.push({ [_get(f, "column.field")]: f.value });
    });
  }

  const getData = {
    filters: filtersArr,
    page,
    search,
    pageSize,
    orderBy: _get(orderBy, "field") ? { [_get(orderBy, "field")]: orderDirection } : undefined
  };
  return request(POST, `/stickers`, getData, null, true, true);
};
