import React from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { dotsMenuOptionIcon } from "../../utils/helpers";

// const ITEM_HEIGHT = 48;

export default function DotsMenu(props) {
  const { options } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAction = option => {
    setSelectedOption(option.name);
    if (option.handleAction) {
      return option.handleAction(option);
    }
    handleClose();
  };

  return (
    <Box display="flex" justifyContent="right" sx={{ px: 2, py: 1 }}>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: 0, width: 24, height: 24 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5
        //   }
        // }}
      >
        {options.map(option => {
          if (option.hidden) {
            return null;
          }
          return (
            <MenuItem disabled={option.disabled} key={option.id} selected={option.name === selectedOption} onClick={() => handleAction(option)}>
              {dotsMenuOptionIcon(option.id)}
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
