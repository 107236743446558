import React from "react";
import ekont from "../../images/deliveryCompany/ekont.png";
import speedy from "../../images/deliveryCompany/speedy.png";
import _get from "lodash.get";
import { formatDeliveryAddress } from "../../utils/functions";
import { danger } from "../../utils/colors";

const DeliveryInfo = props => {
  const { policy, variant } = props;
  const productId = _get(policy, "productId");
  const getDeliveryWay = policyData => {
    if (policyData?.deliveryData) {
      if (policyData.deliveryData?.deliveryType === 1 || policyData.deliveryData?.deliveryType === 3) {
        return "До офис";
      } else if (policyData.deliveryData?.deliveryType === 2 || policyData.deliveryData?.deliveryType === 4) {
        return "До адрес";
      }
    }
    return _get(props, "policy.policyData.deliveryWay");
  };

  const getDeliveryCity = policyData => {
    if (policyData?.deliveryData) {
      if (policyData.deliveryData?.deliveryType === 1 || policyData.deliveryData?.deliveryType === 2) {
        return policyData.deliveryData?.cityDeliverySpeedy;
      } else if (policyData.deliveryData?.deliveryType === 3 || policyData.deliveryData?.deliveryType === 4) {
        return policyData.deliveryData?.cityDeliveryEcont;
      }
    }

    return _get(props, "policy.policyData.deliveryCity");
  };
  const getDeliveryAddress = policyData => {
    if (policyData?.deliveryData) {
      if (policyData.deliveryData?.deliveryType === 1) {
        return policyData.deliveryData?.officeDeliverySpeedy;
      } else if (policyData.deliveryData?.deliveryType === 3) {
        return policyData.deliveryData?.officeDeliveryEcont;
      } else {
        return formatDeliveryAddress(policyData.deliveryData);
      }
    }

    return _get(props, "policy.policyData.deliveryAddress");
  };

  const deliveryAddress = getDeliveryAddress(policy?.policyData);
  const deliveryWay = getDeliveryWay(policy?.policyData);
  const deliveryCity = getDeliveryCity(policy?.policyData);
  const deliveryCompany = _get(props, "policy.policyData.deliveryCompany");
  const deliveryUser = _get(props, "policy.policyData.deliveryData.userDelivery") || _get(props, "policy.policyData.deliveryName");
  const deliveryPhone = _get(props, "policy.policyData.deliveryData.phoneDelivery") || _get(props, "policy.policyData.deliveryPhone");
  const deliveryType = _get(props, "policy.policyData.deliveryData.deliveryType");
  const isOffline = _get(props, "policy.isOffline");

  let sticker, greenCard, prefix;
  if (["policies", "queries"].includes(variant)) {
    sticker = _get(props, "policy.policyData.sticker");
    greenCard = _get(props, "policy.policyData.greenCard");
    prefix = _get(props, "policy.policyData.greenCardPrefix");
  } else if (variant === "installments") {
    sticker = _get(props, "policy.duePayment.otherData.sticker");
    greenCard = _get(props, "policy.duePayment.otherData.greenCard");
    prefix = _get(props, "policy.duePayment.otherData.greenCardPrefix");
  }

  let delivery = "";
  if (deliveryType && (deliveryType === 1 || deliveryType === 2)) {
    delivery = "Speedy";
  } else if (deliveryType && (deliveryType === 3 || deliveryType === 4)) {
    delivery = "Еконт";
  } else {
    delivery = deliveryCompany;
  }

  const deliveryContent = (
    <>
      <div className="vertical-align">
        <b>Куриер: </b>
        <span>
          <img alt="delivery" src={delivery === "Еконт" ? ekont : speedy} style={{ height: 30, marginLeft: 3 }} />
        </span>
      </div>
      <div style={{ marginBottom: 20 }}>
        <b>Начин: </b>
        <span>{deliveryWay}</span>
      </div>
      <div style={{ marginBottom: 20 }}>
        <b>Адрес: </b>
        <span>
          {deliveryCity ? `${deliveryCity},` : ""} {deliveryAddress}
        </span>
      </div>
      <div>
        <b>Получател: </b>
        <span>
          {deliveryUser} {deliveryPhone}
        </span>
      </div>
    </>
  );

  const stickerAndGreenCardContent = (
    <>
      <div>
        <b>Стикер: </b>
        {sticker ? <span style={{ color: danger }}>{sticker}</span> : <span> - </span>}
      </div>
      <div>
        <b>Зелена карта: </b>
        {greenCard ? <span style={{ color: danger }}>{`${prefix} ${greenCard}`}</span> : <span> - </span>}
      </div>
    </>
  );
  return (
    <>
      {["GO", "CSC"].includes(productId) && deliveryAddress && deliveryContent}
      {productId === "GO" && stickerAndGreenCardContent}
    </>
  );
};

export default DeliveryInfo;
