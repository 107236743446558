import _get from "lodash.get";

export const premiumFields = [
  {
    name: "fullAmountPremium",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.fullAmountPremium");
    },
    type: "text",
    label: "Нетна премия (без данък)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTax",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.fullAmountTax");
    },
    type: "text",
    label: "Данък (сума)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTotal",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.fullAmountTotal");
    },
    type: "text",
    label: "Брутна премия (с данък)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "hasPromo",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.hasPromo", false);
    },
    label: "Полицата е с допълнителна отстъпка",
    type: "checkbox"
  },
  {
    name: "fullAmountPromo",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.fullAmountPromo", "");
    },
    type: "text",
    label: "Брутна премия (с данък, след отстъпка)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"],
    hidden: props => {
      if (props?.formData?.hasPromo === undefined) {
        return !_get(props, "form.offlineFormData.hasPromo", false);
      }
      return !props?.formData?.hasPromo;
    }
  },
  {
    name: "commissionPercentage",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.commissionPercentage");
    },
    type: "percentage",
    label: "Комисионна в %",
    placeholder: "15",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  }
];
