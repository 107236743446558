const leftMenu = [
  { id: "GO", active: true },
  { id: "CSC", active: true },
  { id: "TRA", active: true },
  { id: "PRP", active: true },
  { id: "MTN", active: true },
  { id: "EXT", active: true },
  { id: "Refunds", active: true },
  { id: "Reports", active: true },
  { id: "Promo", active: true },
  { id: "Stickers", active: true }
];

const products = [
  {
    id: "GO",
    name: "Гражданска"
  },
  {
    id: "CSC",
    name: "Каско"
  },
  {
    id: "CSC+GO",
    name: "Каско + Гражданска"
  },
  {
    id: "TRA",
    name: "Пътуване в чужбина"
  },
  {
    id: "PRP",
    name: "Имущество"
  },
  {
    id: "MTN",
    name: "Планинска"
  },
  {
    id: "EXT",
    name: "Екстремни"
  }
];
const automaticStickers = [
  { id: "levins", active: true },
  { id: "dzi", active: true },
  { id: "euroins", active: true },
  { id: "dallbogg", active: true },
  { id: "bulstrad", active: true },
  { id: "allianz", active: true },
  { id: "uniqa", active: true }
];
export default { leftMenu, products, automaticStickers };
