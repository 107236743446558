import * as React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/pages/NotFound";
import routesConfig from "./routesConfig";
import BestDoctorsReport from "../components/pages/BestDoctorsReport";
import KFNReport from "../components/pages/KFNReport";
import Payments from "../components/pages/Payments";
import Refunds from "../components/pages/Refunds";
import Statistics from "../components/pages/Statistics";
import AddStickers from "../components/pages/AddStickers";
import Stickers from "../components/pages/Stickers";
import Login from "../components/pages/Login";
import PrivateRoute from "../components/PrivateRoute";
import OfflinePolicy from "../components/pages/OfflinePolicy";
import SalesReport from "../components/pages/SalesReport";
import EndingPolicies from "../components/pages/EndingPoliciesReport";
import EditPolicy from "../components/pages/EditPolicy";
import EndingPayments from "../components/pages/EndingPaymentsReport";
import ReportsForPeriod from "../components/pages/ReportsForPeriod";
import CommissionAndWeeklyReports from "../components/pages/CommissionAndWeeklyReports";
import ReportsTransactions from "../components/pages/ReportsTransactions";
import SendDocuments from "../components/pages/SendDocuments";
import SendDocumentsToInsurer from "../components/pages/SendDocumentsToInsurer";
import SendDocumentsToCSCPlusGO from "../components/pages/SendDocumentsToCSCPlusGO";
import SendPolicy from "../components/pages/SendPolicy";
import SigningPDF from "../components/pages/SigningPDF";
import AddPromoCodes from "../components/pages/AddPromoCodes";
import Reports from "../components/pages/Reports";
import CloneBestDoctors from "../components/pages/CloneBestDoctors";
import Purchases from "../components/pages/Purchases";
import Shipment from "../components/pages/Shipment";
import Casco from "../components/pages/Casco";
import CascoPlusGo from "../components/pages/CascoPlusGo";
import SendOffer from "../components/pages/SendOffer";
import SendOfferCSCGO from "../components/pages/SendOffer/SendOfferCSCGO";
import ForgottenPass from "../components/pages/Login/forgottenPass";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _get from "lodash.get";
import GO from "../components/pages/GO";
import PRP from "../components/pages/PRP";
import TRA from "../components/pages/TRA";
import MTN from "../components/pages/MTN";
import EXT from "../components/pages/EXT";
import HLT from "../components/pages/HLT";
import OFF from "../components/pages/OFF";
import MED from "../components/pages/MED";
import LFE from "../components/pages/LFE";
import ALL from "../components/pages/ALL";
import AddReferal from "../components/pages/AddReferral/AddReferal";

const RoutesComponent = () => {
  const { user } = useSelector(state => _get(state, "auth", {}));
  const roles = _get(user, "roles", []);
  const props = { navigate: useNavigate(), location: useLocation(), roles };
  return (
    <Routes>
      <Route
        path={routesConfig.home.route}
        element={
          <PrivateRoute allAllowed userRoles={roles} allowedRoles={[]}>
            <ALL {...props} />
          </PrivateRoute>
        }
      />
      <Route
        path={routesConfig.policies.route}
        element={
          <PrivateRoute allAllowed>
            <ALL {...props} />
          </PrivateRoute>
        }
      />
      <Route path={routesConfig.login.route} element={<Login {...props} />} />
      <Route path={routesConfig.payments.route} element={<Payments {...props} />} />
      <Route path={routesConfig.refunds.route} element={<Refunds {...props} />} />
      <Route path={routesConfig.statistics.route} element={<Statistics {...props} />} />
      <Route path={routesConfig.bestDoctorsReport.route} element={<BestDoctorsReport {...props} />} />
      <Route path={routesConfig.addStickers.route} element={<AddStickers {...props} />} />
      <Route path={routesConfig.stickers.route} element={<Stickers {...props} />} />
      <Route path={routesConfig.kfnReport.route} element={<KFNReport {...props} />} />
      <Route path={routesConfig.offlinePolicy.route} element={<OfflinePolicy {...props} />} />
      <Route path={routesConfig.salesReport.route} element={<SalesReport {...props} />} />
      <Route path={`${routesConfig.editPolicy.route}/:id`} element={<EditPolicy {...props} />} />
      <Route path={routesConfig.endingPoliciesReport.route} element={<EndingPolicies {...props} />} />
      <Route path={routesConfig.endingPaymentsReport.route} element={<EndingPayments {...props} />} />
      <Route path={routesConfig.reportsForPeriod.route} element={<ReportsForPeriod {...props} />} />
      <Route path={`${routesConfig.commissionAndWeeklyReports.route}/:product`} element={<CommissionAndWeeklyReports {...props} />} />
      <Route path={routesConfig.reportsTransactions.route} element={<ReportsTransactions {...props} />} />
      <Route path={`${routesConfig.sendDocuments.route}/:policyDBId/:installment/:productId/:defaultEmail/:defaultName`} element={<SendDocuments {...props} />} />
      <Route path={`${routesConfig.sendDocumentsToInsurer.route}/:policyDBId/:productId/:defaultName`} element={<SendDocumentsToInsurer {...props} />} />
      <Route path={`${routesConfig.sendDocumentsCascoPlusGO.route}/:policyDBId/:policyDBId2/:defaultName/:defaultEmail`} element={<SendDocumentsToCSCPlusGO {...props} />} />
      <Route path={`${routesConfig.sendPolicy.route}/:policyDBId/:productId/:defaultEmail/:defaultName`} element={<SendPolicy {...props} />} />
      <Route path={routesConfig.signingPDF.route} element={<SigningPDF {...props} />} />
      <Route path={routesConfig.cloneBestDoctors.route} element={<CloneBestDoctors {...props} />} />
      <Route path={routesConfig.addPromoCodes.route} element={<AddPromoCodes {...props} />} />
      <Route path={routesConfig.addReferral.route} element={<AddReferal {...props} />} />
      <Route
        path={routesConfig.reports.route}
        element={
          <PrivateRoute allowedRoles={["admin"]} userRoles={roles}>
            <Reports {...props} />
          </PrivateRoute>
        }
      />
      <Route path={routesConfig.purchases.route} element={<Purchases {...props} />} />
      <Route path={`${routesConfig.shipment.route}/:id`} element={<Shipment {...props} />} />
      <Route path={routesConfig.casco.route} element={<Casco {...props} />} />
      <Route path={routesConfig.cascoPlusGo.route} element={<CascoPlusGo {...props} />} />
      <Route path={routesConfig.go.route} element={<GO {...props} />} />
      <Route path={routesConfig.prp.route} element={<PRP {...props} />} />
      <Route path={routesConfig.tra.route} element={<TRA {...props} />} />
      <Route path={routesConfig.mtn.route} element={<MTN {...props} />} />
      <Route path={routesConfig.ext.route} element={<EXT {...props} />} />
      <Route path={routesConfig.hlt.route} element={<HLT {...props} />} />
      <Route path={routesConfig.off.route} element={<OFF {...props} />} />
      <Route path={routesConfig.med.route} element={<MED {...props} />} />
      <Route path={routesConfig.lfe.route} element={<LFE {...props} />} />
      <Route path={`${routesConfig.sendOffer.route}/:id`} element={<SendOffer {...props} />} />
      <Route path={`${routesConfig.sendOfferCascoPlusGO.route}/:id`} element={<SendOfferCSCGO {...props} />} />
      <Route path={`${routesConfig.forgottenPass.route}`} element={<ForgottenPass {...props} />} />
      <Route element={NotFound} />
    </Routes>
  );
};

export default RoutesComponent;
