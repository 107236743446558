import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import { Stepper, Step } from "react-form-stepper";
// import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import "./wizard.sass";
import { success, primary } from "../../utils/colors";
import _get from "lodash.get";
import { getCurrentWizardStep } from "../../utils/helpers";
import { setWizardStatus, setWizardStatusesByGroupRequestId } from "../../services/PoliciesService";
import { setWizardStatus as setWizardStatusAnnex } from "../../services/AnnexesService";

const StepWrapper = props => {
  const {
    stepComponent,
    isActive,
    currentStep,
    totalSteps,
    close,
    policy,
    annex,
    allStepsFulfilled,
    title,
    variant,
    hideContinueBtn,
    nextStep,
    setWidth,
    activeStep,
    currentProduct,
    id: stepId,
    setPassedData,
    passedData,
    setTitle
  } = props;

  const [canContinue, setCanContinue] = useState(false);
  const [wizardDBStep, setWizardDBStep] = useState("");
  if (allStepsFulfilled)
    return (
      <Grid container spacing={0} display="flex" justifyContent="center">
        <p>Всички стъпки са изпълнени</p>
      </Grid>
    );
  const canCurrentStepContinue = async (wDBstep, noUpdate) => {
    setCanContinue(true);
    setWizardDBStep(wDBstep || stepComponent?.props?.wizardStatus);
    if (!noUpdate) {
      if (currentStep === totalSteps) {
        if (variant === "installments") {
          await setWizardStatus({
            duePaymentId: policy?.duePaymentId,
            wizardStatus: wDBstep || stepComponent?.props?.wizardStatus
          });
        } else if (variant === "annex") {
          await setWizardStatusAnnex({
            annexDBId: annex?._id,
            wizardStatus: wDBstep || stepComponent?.props?.wizardStatus
          });
        } else {
          await setWizardStatus({
            policyDBId: policy?._id,
            wizardStatus: wDBstep || stepComponent?.props?.wizardStatus
          });
        }

        stepComponent?.props?.refreshData();
        return close();
      }
    }
  };
  const cantCurrentStepContinue = () => {
    setCanContinue(false);
  };

  const clonedStepComponent = isActive
    ? React.cloneElement(stepComponent, {
        canCurrentStepContinue,
        cantCurrentStepContinue,
        policy,
        annex,
        nextStep,
        close,
        setWidth,
        currentStep,
        currentProduct,
        setPassedData,
        passedData,
        setTitle
      })
    : null;
  return (
    <div style={{ display: isActive ? "block" : "none" }}>
      {title && <h2 className="modal-title-in-wizard">{title}</h2>}
      {clonedStepComponent}
      <div className="wizard-navigation">
        {/*<Button className="size-8" onClick={props.previousStep} startIcon={<ArrowBack />} type="button" variant="contained" color="primary" size="small" disabled={true}>*/}
        {/*  назад*/}
        {/*</Button>*/}
        {!hideContinueBtn && (
          <Button
            className="size-6"
            onClick={async () => {
              if (["CSC", "GO"].includes(policy?.productId)) {
                if (currentProduct === "CSC+GO" && ["shipment", "moveToIssued"].includes(stepId)) {
                  await setWizardStatusesByGroupRequestId({
                    groupRequestId: policy?.groupRequestId,
                    wizardStatus: stepComponent?.props?.wizardStatus,
                    wizardStatus2: stepComponent?.props?.wizardStatus2
                  });
                } else {
                  if (annex) {
                    await setWizardStatusAnnex({
                      annexDBId: annex?._id,
                      wizardStatus: wizardDBStep
                    });
                  } else {
                    if (variant === "installments") {
                      await setWizardStatus({ duePaymentId: policy?.duePaymentId, wizardStatus: wizardDBStep });
                    } else {
                      await setWizardStatus({ policyDBId: policy?._id, wizardStatus: wizardDBStep });
                    }
                  }
                }
                stepComponent?.props?.refreshData();
              } else {
                // todo just OFF product for now
              }
              return nextStep();
            }}
            // endIcon={<ArrowForward />}
            type="button"
            variant="contained"
            color="primary"
            size="medium"
            disabled={currentStep === totalSteps || !canContinue}
          >
            Продължи
          </Button>
        )}
      </div>
    </div>
  );
};

const Wizard = props => {
  const { isModalOpen, steps, close, setTitle, policy, title, variant, setWidth, currentProduct, annex } = props;
  const [activeStep, setActiveStep] = useState(getCurrentWizardStep((annex || policy)?.wizardStatus));
  const [passedData, setPassedData] = useState({});

  useEffect(() => {
    if (annex) {
      const currentWizardStep = getCurrentWizardStep(annex?.wizardStatus);
      setActiveStep(currentWizardStep);
    }
    setTitle(_get(steps, `[${activeStep - 1}].label`));
  }, [isModalOpen]);

  return (
    <>
      <Stepper
        className="stepper"
        activeStep={activeStep - 1}
        styleConfig={{
          activeBgColor: primary,
          completedBgColor: success,
          labelFontSize: "0.7rem"
        }}
      >
        {steps
          .filter(s => !s.noStepper)
          .map(sc => (
            <Step key={`stepLabel${sc?.id}`} label={sc.label} />
          ))}
      </Stepper>
      {/*<p className="size-5 has-text-centered">{title}</p>*/}
      <StepWizard
        initialStep={activeStep}
        transitions={{}}
        onStepChange={stepChange => {
          const { activeStep } = stepChange;
          setActiveStep(activeStep);
          setTitle(_get(steps, `[${activeStep - 1}].label`));
        }}
      >
        {steps
          .filter(s => s.component)
          .map(sc => (
            <StepWrapper
              key={sc?.id}
              stepComponent={sc?.component}
              close={close}
              id={sc?.id}
              policy={policy}
              annex={annex}
              allStepsFulfilled={steps?.length < activeStep}
              title={title}
              variant={variant}
              hideContinueBtn={sc?.hideContinueBtn}
              setWidth={setWidth}
              activeStep={activeStep}
              currentProduct={currentProduct}
              setPassedData={setPassedData}
              passedData={passedData}
              setTitle={setTitle}
            />
          ))}
      </StepWizard>
    </>
  );
};

export default Wizard;
