import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { addSeqNumbers } from "../../../services/PoliciesService";
import { Box, Button, Grid, TextField, Select, MenuItem, FormControl, LinearProgress, InputLabel, FormHelperText } from "@mui/material";
import withValidation from "../../HOC/withValidation";
import { useState } from "react";
import _get from "lodash.get";
import { stickerFields, extractFieldsFromSections } from "../../../config/AddStickers.page.config";

const extractedFields = extractFieldsFromSections(stickerFields);

const AddStickers = props => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (props?.handleValidation()) {
        const { insurerId, type, prefix, from, to } = _get(props, "formData");
        setLoading(true);
        const response = await addSeqNumbers({
          insurerId,
          type,
          prefix,
          from,
          to
        });
        if (response.status === 200) {
          successAlert("sent successfully!");
        }
      }
    } catch (e) {
      errorAlert(e?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Добавяне на стикери, зелени карти и др." {...props}>
      <Box m={2}>{loading && <LinearProgress />}</Box>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="center">
          <div style={{ width: "50%" }}>
            {Object.keys(stickerFields).map(key => {
              return (
                <React.Fragment key={key}>
                  <p>{stickerFields[key].text}</p>
                  {stickerFields[key].fields
                    .filter(f => !f.hidden?.(props))
                    .map(f => {
                      switch (f.type) {
                        case "text":
                          return (
                            <TextField
                              sx={{ mb: 1, mt: 1 }}
                              key={f.name}
                              placeholder={f.placeholder}
                              fullWidth
                              label={f.label}
                              variant="outlined"
                              size="small"
                              name={f.name}
                              value={props?.formData[f.name]}
                              onChange={props?.handleChange}
                              helperText={props?.errors[f.name]}
                              error={!!props?.errors[f.name]}
                            />
                          );
                        case "select":
                          return (
                            <FormControl fullWidth key={f.name} error={!!props?.errors[f.name]}>
                              <InputLabel>{f.label}</InputLabel>
                              <Select
                                value={props?.formData[f.name]}
                                name={f.name}
                                onChange={e => props?.handleChange(e)}
                                sx={{ mb: 1, mt: 1 }}
                                size="small"
                                MenuProps={{ sx: { maxHeight: 300 } }}
                                SelectDisplayProps={{
                                  style: { fontSize: 16 }
                                }}
                                labelId="demo-simple-select-placeholder-label-label"
                                id="outlined-size-small"
                                displayEmpty
                              >
                                {(f.options || []).map(o => (
                                  <MenuItem key={o.value} value={o.value}>
                                    {o.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {!!props?.errors[f.name] && <FormHelperText>{props?.errors[f.name]}</FormHelperText>}
                            </FormControl>
                          );
                      }
                    })}
                </React.Fragment>
              );
            })}
            <Box mb={2} mt={2} display="flex" justifyContent="center">
              <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
                Изпрати
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withValidation(AddStickers, { txtFields: extractedFields });
