import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import WbTwilight from "@mui/icons-material/WbTwilight";
import PersonOutline from "@mui/icons-material/PersonOutline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LeftMenu from "../LeftMenu";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated, selectUser, unsetUser } from "../../store/reducers/auth";
import AuthUserService from "../../services/AuthUserService";
import { rolesMatched } from "../PrivateRoute/PrivateRoute";
import { Icon } from "@mui/material";
import TenatSwitcher from "./TenatSwitcher";

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export default function MiniDrawer(props) {
  const { DrawerHeader, title } = props;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const { displayName, email, roles } = user || {};
  const dispatch = useDispatch();
  const isDrawerOpened = sessionStorage.getItem("drawerOpened");
  const [open, setOpen] = React.useState(isDrawerOpened ? JSON.parse(isDrawerOpened) === true : true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const siteConfig = window.localStorage.getItem("siteConfig") || "boleron";

  const logout = async () => {
    handleClose();
    await AuthUserService.logOut();
    dispatch(unsetUser());
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    sessionStorage.setItem("drawerOpened", true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem("drawerOpened", false);
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed" open={open} style={{ background: siteConfig === "vivacom" ? "black" : "" }}>
        <Toolbar
          sx={{
            minHeight: "32px !important"
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" })
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {!title && <WbTwilight sx={{ mr: 1 }} />} <span>{title || "JULY"}</span>
          </Typography>
          {isAuthenticated && (
            <div>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <PersonOutline sx={{ mr: 1 }} /> <Typography variant="body1">{displayName || email}</Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Icon>person</Icon> Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open("https://avocado.atlassian.net/servicedesk/customer/portal/1", "_blank");
                    return null;
                  }}
                >
                  <Icon>help</Icon>
                  Helpdesk
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Icon>lock</Icon>
                  Logout
                </MenuItem>
              </Menu>
              <TenatSwitcher />
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && rolesMatched(["admin", "employee"], roles) && <LeftMenu DrawerHeader={DrawerHeader} handleDrawerClose={handleDrawerClose} open={open} />}
    </>
  );
}
