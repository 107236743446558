import _get from "lodash.get";
import moment from "moment";

export const createPolicyFields = [
  {
    name: "policyNumber",
    label: "Въведи номер на полицата",
    placeholder: "",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    defaultValueFunction: props => {
      return _get(props, "policy.policyId", "");
    }
  },
  {
    name: "dateFrom",
    label: "Въведи дата",
    defaultValueFunction: props => {
      return moment(_get(props, "policy.policyData.validFrom", "")).format("DD-MM-YYYY");
    },
    placeholder: "24-01-2020",
    validators: ["required", "dateFromIsValid"],
    errorMessages: ["Полето е задължително", "Невалидна дата(формат ден-месец-година)"]
  }
];
