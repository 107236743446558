import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setOfflineFormData } from "../../../../store/reducers/form";

const Done = props => {
  const { close } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    props?.setWidth(700);
  }, []);
  const handleSubmit = async () => {
    dispatch(setOfflineFormData({}));
    close();
  };
  return (
    <>
      <h2 className="policy-created-title">Полицата е създадена</h2>
      <Box mb={2} mt={2} display="flex" justifyContent="center">
        <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
          Готово
        </Button>
      </Box>
    </>
  );
};

export default Done;
