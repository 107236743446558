import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { white } from "../../utils/colors";

export default function FromTo(props) {
  const { variant, subVariant, fromDays, toDays, refreshData, from, to } = props;
  const [selectedFromDate, setSelectedFromDate] = useState(moment().add(parseInt(fromDays), "days"));
  const [selectedToDate, setSelectedToDate] = useState(moment().add(toDays, "days"));
  const [fromD, setFrom] = useState(from && from !== undefined ? moment(from, "YYYY-MM-DD").format("DD MMMM YYYY") : "");
  const [toD, setTo] = useState(to && to !== undefined ? moment(to, "YYYY-MM-DD").format("DD MMMM YYYY") : "");
  const didMount = useRef(false);

  const [isDefaultFrom, setIsDefaultFrom] = useState(true);
  const [isDefaultTo, setIsDefaultTo] = useState(true);

  useEffect(() => {
    if (from && to) {
      setSelectedFromDate(from);
      setSelectedToDate(to);
      setIsDefaultFrom(true);
    }
    if (fromD && toD) {
      refreshData({ from: fromD, to: toD });
    } else {
      refreshData({ from: selectedFromDate?.format("YYYY-MM-DD"), to: selectedToDate?.format("YYYY-MM-DD") });
    }
  }, [from, to]);

  const handleFromCalendarChange = newValue => {
    setSelectedFromDate(newValue);
    setIsDefaultFrom(false);
  };

  const handleToCalendarChange = newValue => {
    setSelectedToDate(newValue);
    setIsDefaultTo(false);
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      handleSetPeriod();
    }
  }, [selectedToDate, selectedFromDate]);

  const handleSetPeriod = () => {
    setFrom(selectedFromDate);
    setTo(selectedToDate);
    refreshData({ from: selectedFromDate?.format("YYYY-MM-DD"), to: selectedToDate?.format("YYYY-MM-DD") });
  };
  const fromLabel = ((v, subVariant) => {
    if (["expiring", "renewed", "informed"].includes(subVariant)) return "Дата на изтичане от";
    if (v === "queries") return "Дата на заявка от";
    if (v === "online") return "Дата на издаване от";
    if (v === "policies") return "Дата на издаване от";
    if (v === "installments") return "Дата на падеж/плащене от";
    return "Начална дата";
  })(variant, subVariant);
  const toLabel = (v => {
    if (["expiring", "renewed", "informed"].includes(subVariant)) return "Дата на изтичане до";
    if (v === "queries") return "Дата на заявка до";
    if (v === "online") return "Дата на издаване до";
    if (v === "policies") return "Дата на издаване до";
    if (v === "installments") return "Дата на падеж/плащене до";
    return "Крайна дата";
  })(variant, subVariant);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          disableMaskedInput
          sx={{ mr: 2 }}
          label={fromLabel}
          value={selectedFromDate}
          onChange={newValue => handleFromCalendarChange(newValue)}
          inputFormat="DD MMMM YYYY"
          renderInput={params => <TextField {...params} style={{ marginRight: 5, marginBottom: 5, backgroundColor: white, maxWidth: 180 }} size="small" />}
        />
        <DesktopDatePicker
          disableMaskedInput
          label={toLabel}
          value={selectedToDate}
          onChange={newValue => handleToCalendarChange(newValue)}
          inputFormat="DD MMMM YYYY"
          renderInput={params => <TextField {...params} style={{ marginBottom: 5, backgroundColor: white, maxWidth: 180 }} size="small" />}
        />
      </LocalizationProvider>
    </>
  );
}
