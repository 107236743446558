import React, { useState } from "react";
import Modal from "../Modal";
import Wizard from "../Wizard";
import { getStepsForIssuePolicyWizardModal } from "../DetailPanel/TodoList/config";

const IssuePolicyWizardModal = props => {
  const [title, setTitle] = useState("");
  const [width, setWidth] = useState(undefined);
  const { isIssuePolicyWizardModalOpen, toggle, objWithEgnAndRequestId, close, variant, currentProduct } = props;
  const modalName = "issuePolicyWizardModal";
  const steps = getStepsForIssuePolicyWizardModal(props);

  return (
    <Modal open={isIssuePolicyWizardModalOpen} setOpen={() => toggle(modalName)} description="" icon noPadding width={width}>
      <Wizard
        isModalOpen={isIssuePolicyWizardModalOpen}
        policy={objWithEgnAndRequestId}
        steps={steps}
        close={() => close(modalName)}
        setTitle={setTitle}
        setWidth={setWidth}
        title={title}
        variant={variant}
        currentProduct={currentProduct}
      />
    </Modal>
  );
};

export default IssuePolicyWizardModal;
