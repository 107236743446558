import { default as boleron } from "./boleron";
import { default as vivacom } from "./vivacom";

export const siteConfig = window.localStorage.getItem("siteConfig") || "boleron";
const mainConfig = {
  vivacom,
  boleron
};

export default mainConfig[siteConfig];
