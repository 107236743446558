import React, { useEffect, useState } from "react";
import withValidation from "../../../HOC/withValidation";
import { Grid } from "@mui/material";
import WizardButton from "../../common/WizardButton";
import { attachmentFields } from "../../../../config/WizardAnnexes/AttachFiles.page.config";
import _get from "lodash.get";
import { getVehicleData, uploadFile, uploadTalon } from "../../../../services/PoliciesService";
import { setWizardStatus } from "../../../../services/AnnexesService";
import { getBase64 } from "../../../../utils/functions";
import _find from "lodash.find";
import mergeByKey from "array-merge-by-key";

const AttachFiles = props => {
  const [loading, setLoading] = useState(false);
  const [container, setContainer] = useState();
  const [uploadedFs, setUploadedFs] = useState([]);

  const { policy, nextStep, refreshData, annex, wizardStatus } = props;
  const requestObjCar = {
    carNo: _get(policy, "policyData.savedCar.carNo", ""),
    talonNo: _get(policy, "policyData.savedCar.talonNo", "")
  }; // todo not sure if this are the correct values for carNo and talonNo

  useEffect(() => {
    props?.setWidth(500);
    const fetchData = async () => {
      const response = await getVehicleData({
        carNo: requestObjCar.carNo,
        talonNo: requestObjCar.talonNo
      });
      const carDBId = _get(response, "data.vehicleDBId", "");
      setContainer(carDBId);
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      let { policyDBId, policyId } = policy || {};

      let uploadCalls = [];
      let keyArr = [];
      for (const [key, value] of Object.entries(props.formData)) {
        if (value && !_get(_find(uploadedFs, { key: key }), "uploaded")) {
          keyArr.push(key);
          if (key === "talonAttachment") {
            const file = value;
            const formData = new FormData();
            let documentNameD = "big"; //other possible values front/back
            const base64Result = await getBase64(file);
            const base64WithoutType = base64Result.split(",")[1];
            const extension = file.name.split(".").pop().toLowerCase();
            const documentName = `${requestObjCar.carNo}_${requestObjCar.talonNo}_${documentNameD}.${extension}`;
            formData.append("container", container);
            formData.append("file", base64WithoutType);
            formData.append("containerType", "vehicle");
            formData.append("documentType", documentNameD);
            formData.append("documentName", documentName);
            formData.append("extension", extension);
            uploadCalls.push({ method: uploadTalon, data: formData });
          } else {
            const data = {
              file: value,
              policyNumber: policyId,
              policy: { _id: policyDBId },
              typeDocument: "annex",
              annexId: props.annex.annexId,
              isHidden: false,
              isFromWizard: true
            };
            uploadCalls.push({ method: uploadFile, data });
          }
        }
      }
      setLoading(true);
      const results = await Promise.all([...uploadCalls.map(call => _get(call, "method")(_get(call, "data")))].map(p => p.catch(e => e)));
      const validResults = results.filter(result => !(result instanceof Error));
      const uploadedFiles = [];

      results.forEach((r, index) => {
        if (!(r instanceof Error)) {
          // successfull
          uploadedFiles.push({
            key: keyArr[index],
            uploaded: true,
            message: "качването е успешно!"
          });
        } else {
          //not sucessfull
          uploadedFiles.push({
            key: keyArr[index],
            uploaded: false,
            message: r?.message
          });
        }
      });
      setUploadedFs(mergeByKey("key", uploadedFs, uploadedFiles));
      setLoading(false);
      if (validResults.length === uploadCalls.length) {
        await setWizardStatus({ annexDBId: annex?._id?.trim(), wizardStatus });
        refreshData();
        nextStep();
      } else {
        let outerErrors = {};
        uploadedFiles.forEach(({ key, message, uploaded }) => {
          if (!uploaded) {
            const error = { [key]: message };
            outerErrors = { ...outerErrors, ...error };
          }
        });
        props?.outerErrorsSet(outerErrors);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {props?.renderFields(props, attachmentFields, uploadedFs)}
          <WizardButton disabled={loading} loading={loading} handleSubmit={handleSubmit} />
        </div>
      </Grid>
    </Grid>
  );
};

export default withValidation(AttachFiles, { txtFields: attachmentFields });
