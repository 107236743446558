import React from "react";
import { Grid, Icon } from "@mui/material";
import "./status.sass";

const getStatusColor = status => {
  switch (status) {
    case "active":
    case "renewed":
    case "renewal":
      return "success";
    case "pending":
    case "pending-offer":
    case "expiring":
      return "warning";
    case "cancelled":
    case "partially-cancelled":
      return "danger";
    case "expired":
      return "gray-light";
    case "informed":
      return "info";
    default:
      return "gray";
  }
};
const getBgTranslation = status => {
  switch (status) {
    case "active":
    case "renewal":
      return "Активна";
    case "pending":
      return "Обработва се";
    case "cancelled":
      return "Прекратена полица";
    case "rejected":
      return "отказана";
    case "expired":
      return "Изтекла";
    case "expiring":
      return "Изтичаща";
    case "renewed":
      return "Подновена";
    case "informed":
      return "Информиран";
    case "pending-offer":
      return "Чакащ оферта";
    case "partially-cancelled":
      return "Частично прекратена";
    case "just-pending":
      return "Необработено";
    case "other":
      return "Друг";
    default:
      return "";
  }
};

const Status = ({ status }) => {
  const statusText = getBgTranslation(status);
  return (
    <Grid container alignItems="center" justifyContent="flex-start" direction="row">
      <Icon className={`status-icon ${getStatusColor(status)}`}>circle</Icon> <span className={`status-text ${getStatusColor(status)}`}>{statusText}</span>
    </Grid>
  );
};

export default Status;
