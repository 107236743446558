import React, { useEffect, useState } from "react";
import { Box, Button, Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import { gray, grayBorder, grayLight } from "../../../../utils/colors";
import { attachmentFields } from "../../../../config/WizardLFE/Attachment.page.config";
import withValidation from "../../../HOC/withValidation";
import { setWizardStatus, uploadFile, uploadPolicy } from "../../../../services/PoliciesService";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectOfflineFormData, selectOfflineUploadedFiles, setOfflineUploadedFiles } from "../../../../store/reducers/form";
import _get from "lodash.get";
import _find from "lodash.find";
import mergeByKey from "array-merge-by-key";
import WizardButton from "../../common/WizardButton";

const Attachments = props => {
  const { nextStep, refreshData } = props;
  const dispatch = useDispatch();
  const formData = useSelector(selectOfflineFormData);
  const [loading, setLoading] = useState(false);
  const uploadedFiles = _get(useSelector(selectOfflineUploadedFiles), `[${props?.policy.policyDBId || formData?.policyOid}]`, []);

  useEffect(() => {
    //
  }, []);

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      let { policyDBId, policyId } = props.policy || {};

      if (!policyDBId) {
        policyDBId = formData?.policyOid;
        policyId = formData?.policyNumber;
      }
      let uploadCalls = [];
      let keyArr = [];
      for (const [key, value] of Object.entries(props.formData)) {
        if (value && !_get(_find(uploadedFiles, { key: key }), "uploaded")) {
          keyArr.push(key);
          if (key === "policy") {
            const data = { file: value, policy: { _id: policyDBId }, isFromWizard: true };
            uploadCalls.push({ method: uploadPolicy, data });
          } else {
            const data = {
              file: value,
              policyNumber: policyId,
              policy: { _id: policyDBId },
              typeDocument: key === "vdog" ? "vdog" : "otherFile",
              isHidden: false,
              isFromWizard: true
            };
            uploadCalls.push({ method: uploadFile, data });
          }
        }
      }
      setLoading(true);
      const results = await Promise.all([...uploadCalls.map(call => _get(call, "method")(_get(call, "data")))].map(p => p.catch(e => e)));
      const validResults = results.filter(result => !(result instanceof Error));
      const offlineUploadedFiles = [];

      results.forEach((r, index) => {
        if (!(r instanceof Error)) {
          // successfull
          offlineUploadedFiles.push({
            key: keyArr[index],
            uploaded: true,
            message: "качването е успешно!"
          });
        } else {
          //not sucessfull
          offlineUploadedFiles.push({
            key: keyArr[index],
            uploaded: false,
            message: r?.message
          });
        }
      });
      dispatch(setOfflineUploadedFiles({ [policyDBId]: mergeByKey("key", uploadedFiles, offlineUploadedFiles) }));
      setLoading(false);
      if (validResults.length === uploadCalls.length) {
        await setWizardStatus({ policyDBId, wizardStatus: props?.wizardStatus });
        dispatch(setOfflineUploadedFiles({ [policyDBId]: undefined })); // todo
        refreshData();
        nextStep();
      } else {
        let outerErrors = {};
        offlineUploadedFiles.forEach(({ key, message, uploaded }) => {
          if (!uploaded) {
            const error = { [key]: message };
            outerErrors = { ...outerErrors, ...error };
          }
        });
        props?.outerErrorsSet(outerErrors);
      }
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          {attachmentFields.map(f => (
            <div key={f.name}>
              <InputLabel
                sx={{
                  fontSize: ".7rem",
                  marginLeft: "15px"
                }}
              >
                {f.label}
              </InputLabel>
              <TextField
                label=""
                id="outlined-start-adornment"
                readOnly
                style={{ width: "100%", marginBottom: "16px" }}
                value={props?.formData[f.name]?.name}
                placeholder=""
                disabled={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                inputProps={{
                  style: {
                    padding: 9,
                    color: gray
                  }
                }}
                FormHelperTextProps={{
                  className: _get(_find(uploadedFiles, { key: f.name }), "uploaded", false) ? "has-text-success" : "has-text-danger"
                }}
                helperText={props?.errors[f.name]}
                error={!!props?.errors[f.name]}
                InputProps={{
                  sx: {
                    padding: 0
                  },
                  endAdornment: (
                    <InputAdornment sx={{ height: "100%" }} position="end">
                      <Button
                        variant="contained"
                        color="inherit"
                        component="label"
                        disabled={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                        sx={{
                          ml: 1,
                          py: "8px",
                          backgroundColor: grayLight,
                          boxShadow: "none",
                          borderLeft: `1px solid ${grayBorder}`
                        }}
                      >
                        Прикачи
                        <input type="file" hidden name={f.name} onChange={props?.handleFileChange} />
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          ))}
          <WizardButton disabled={loading} handleSubmit={handleSubmit} loading={loading} />
        </div>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(Attachments, { txtFields: attachmentFields }));
