import _get from "lodash.get";

export const txtFields = [
  {
    name: "policyNumber",
    label: "Въведи номер на полицата",
    placeholder: "",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    defaultValueFunction: props => {
      return _get(props, "objWithEgnAndRequestId.policyId", "");
    }
  }
];
