import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { primary, white } from "../../../utils/colors";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

const SearchInput = props => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setSearchText(props?.searchQuery);
  }, []);

  return (
    <>
      <TextField
        style={{ width: 350 }}
        onChange={e => {
          const value = e?.target?.value;
          setSearchText(value);
          // tableRef?.current?.onSearchChangeDebounce(value);
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            if (searchText?.trim()) window.open(`${location?.pathname}?query=${searchText}`, "_blank");
          }
        }}
        variant="outlined"
        size="small"
        value={searchText}
        placeholder="Търсене"
        InputProps={{
          sx: { backgroundColor: white, pt: "1px", pb: "1px" },
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Clear
                htmlColor={primary}
                fontSize="small"
                onClick={() => {
                  setSearchText("");
                  // tableRef?.current?.onSearchChangeDebounce("");
                }}
              />
            </InputAdornment>
          )
        }}
      />
      <Button
        onClick={() => {
          window.open(`${location?.pathname}?query=${searchText}`, "_blank");
        }}
        variant="contained"
        size="small"
        className="size-6"
        sx={{ ml: 2 }}
        style={{
          maxHeight: 42
        }}
      >
        Търси
      </Button>
    </>
  );
};

export default SearchInput;
