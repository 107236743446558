import React from "react";
import { Grid } from "@mui/material";
import { primary } from "../../utils/colors";
import _get from "lodash.get";
import { cscServiceName, installmentsTxt, round } from "../../utils/helpers";

const Offers = ({ policy }) => (
  <>
    {!!Object.keys(policy?.policyData?.payments)?.length && (
      <div className="offers-side-tab-gray-box">
        <Grid container spacing={0}>
          <Grid className="offer-box right-border" item xs={3}>
            <p className="detail-panel-section-title" style={{ color: primary }}>
              Оферта №1
            </p>
            <p>
              <span className="bold">Застрахователна сума</span> <span>{_get(policy, "policyData.cscCarPrice").toFixed(2)} лв.</span>
            </p>
            <p>
              <span className="bold">Вид сервиз</span> <span>{cscServiceName(_get(policy, "policyData.cscService"))}</span>
            </p>
          </Grid>
          <Grid className="offer-box right-border" item xs={3}>
            <p className="detail-panel-section-title">Еднократно</p>
            <p>
              <span className="bold">Стандартна цена:</span> <span>{round(_get(policy, "policyData.payments[1].original", 0)).toFixed(2)} лв.</span>
            </p>
            <p>
              <span className="bold has-text-primary">Цена с отстъпка:</span>{" "}
              <span className="has-text-primary">{round(_get(policy, "policyData.payments[1].sum", 0)).toFixed(2)} лв.</span>
            </p>
          </Grid>
          <Grid className="offer-box right-border" item xs={3}>
            <p className="detail-panel-section-title">2 вноски</p>
            {Object.keys(_get(policy, "policyData.payments[2]", {}))
              .filter(Number)
              .map(k => (
                <p key={`offer-${k}`}>
                  <span className="bold">{installmentsTxt(k)} вноска:</span> <span>{_get(policy, `policyData.payments[2][${k}].premium_with_tax`, 0).toFixed(2)} лв.</span>
                </p>
              ))}
            <p>
              <span className="bold">Обща цена:</span> <span>{round(_get(policy, "policyData.payments[2].original", 0)).toFixed(2)} лв.</span>
            </p>
            <p>
              <span className="bold has-text-primary">Обща цена с отстъпка:</span>{" "}
              <span className="has-text-primary">{round(_get(policy, "policyData.payments[2].sum", 0)).toFixed(2)} лв.</span>
            </p>
          </Grid>
          <Grid className="offer-box" item xs={3}>
            <p className="detail-panel-section-title">4 вноски</p>
            {Object.keys(_get(policy, "policyData.payments[4]", {}))
              .filter(Number)
              .map(k => (
                <p key={`offer-${k}`}>
                  <span className="bold">{installmentsTxt(k)} вноска:</span> <span>{_get(policy, `policyData.payments[4][${k}].premium_with_tax`, 0).toFixed(2)} лв.</span>
                </p>
              ))}
            <p>
              <span className="bold">Обща цена:</span> <span>{round(_get(policy, "policyData.payments[4].original", 0)).toFixed(2)} лв.</span>
            </p>
            <p>
              <span className="bold has-text-primary">Обща цена с отстъпка:</span>{" "}
              <span className="has-text-primary">{round(_get(policy, "policyData.payments[4].sum", 0)).toFixed(2)} лв.</span>
            </p>
          </Grid>
        </Grid>
      </div>
    )}
  </>
);

export default Offers;
