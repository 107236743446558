import React from "react";

import SendDocuments from "../../../SendDocuments";
import { sendDocumentsFields } from "../../../../config/WizardLFE/SendDocuments.page.config";
import { sendDocuments, setWizardStatus } from "../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";

const SendDocumentsLFE = props => {
  const { nextStep, refreshData } = props;
  const handleSubmit = async data => {
    const { policyDBId, email, additionalEmails, files } = data;
    try {
      const response = await sendDocuments({ policyDBId, email, additionalEmails, files });
      if (response.status === 200) {
        await setWizardStatus({ policyDBId, wizardStatus: props?.wizardStatus });
        successAlert("sent successfully!");
        refreshData();
        nextStep();
      }
    } catch (e) {
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  };

  return (
    <SendDocuments
      {...props}
      includeHiddenFiles={false}
      withLayout={false}
      withCheckboxes={false}
      filtering={["policy"]}
      ordering={["policy", "otherFile", "installment"]}
      txtFields={sendDocumentsFields}
      handleSubmit={handleSubmit}
    />
  );
};

export default SendDocumentsLFE;
