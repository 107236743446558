import React from "react";
import Tabs from "@mui/material/Tabs";
import { grayLight } from "../../utils/colors";
import Tab from "@mui/material/Tab";
import "./inner.tabs.sass";

const InnerTabs = ({ selected, handleChange, options }) => {
  return (
    <Tabs value={selected} indicatorColor="" variant="standard" textColor="primary" onChange={handleChange} aria-label="disabled tabs example" sx={{ backgroundColor: grayLight }}>
      {options.map((option, index) => (
        <Tab key={`tab-${index}`} label={option.label} value={option?.value} className={`inner-tab-item ${selected === option?.value ? "selected" : ""}`} />
      ))}
    </Tabs>
  );
};

export default InnerTabs;
