import React, { useContext, useEffect, useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import { Divider } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { LinearProgress } from "@mui/material";
import { dotsMenuOptionIcon } from "../../../utils/helpers";
import { ActionsContext } from "../../ActionsProvider/ActionsProvider";

const ActionsList = ({ options }) => {
  const { optionLoading, setOptionLoading, selectedOption, setSelectedOption } = useContext(ActionsContext);

  const handleAction = async option => {
    setSelectedOption(option.id);
    if (option.handleAction) {
      setOptionLoading(true);
      await option.handleAction(option);
      setOptionLoading(false);
    }
  };
  const filteredOptions = options.filter(o => !o?.hidden);

  return (
    <List
      sx={{ width: "100%", pb: 0 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Actions
        </ListSubheader>
      }
    >
      <Divider />
      {!filteredOptions?.length && <p className="has-text-centered">Няма actions</p>}
      {filteredOptions.map((option, index, filteredOptions) => {
        return (
          <React.Fragment key={option.id}>
            <ListItemButton disabled={option.disabled || optionLoading} selected={option.id === selectedOption} onClick={() => handleAction(option)}>
              <ListItemIcon>{dotsMenuOptionIcon(option.id)}</ListItemIcon>
              <ListItemText
                primary={
                  optionLoading && selectedOption === option.id ? (
                    <>
                      {option.name}
                      <LinearProgress />
                    </>
                  ) : (
                    <>{option.name}</>
                  )
                }
              />
            </ListItemButton>
            {index + 1 !== filteredOptions.length && <Divider variant="middle" />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ActionsList;
