import * as React from "react";

const NotFound = () => {
  return (
    <div>
      <h2>Page not found</h2>
      <p>Error 404! Page not found</p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default NotFound;
