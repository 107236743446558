import React from "react";
import { Grid, Icon } from "@mui/material";
import "./variant.status.sass";

const getStatusColor = status => {
  switch (status) {
    case "process":
      return "gray-light";
    case "overdue":
      return "warning";
    case "informed":
      return "info";
    case "confirmed":
      return "primary";
    case "rejected":
      return "danger";
    case "cancelled":
    case "partiallyPaid":
      return "danger";
    case "issued":
      return "success";
    case "paidElsewhere":
      return "danger";
    case "not-covered-variant":
      return "gray";
    default:
      return "gray";
  }
};
const getBgTranslation = (status, productId) => {
  switch (status) {
    case "process":
      return "Предстояща";
    case "overdue":
      return "Просрочена";
    case "informed":
      return "Информиран";
    case "confirmed":
      if (productId === "CSC") {
        return "Платена";
      } else return "Потвърдена";
    case "rejected":
      return "Отказана";
    case "cancelled":
      return "Прекратена";
    case "partiallyPaid":
      return "Отказано плащане";
    case "issued":
      return "Издадена";
    case "paidElsewhere":
      return "Платена другаде";
    case "not-covered-variant":
      return "Oбработено";
    default:
      return "";
  }
};

const VariantStatus = ({ status, productId }) => {
  const statusText = getBgTranslation(status, productId);
  return (
    <Grid container alignItems="center" justifyContent="flex-start" direction="row">
      <Icon className={`status-icon ${getStatusColor(status)}`}>circle</Icon> <span className={`status-text ${getStatusColor(status)}`}>{statusText}</span>
    </Grid>
  );
};

export default VariantStatus;
