import React, { useState } from "react";
import { siteConfig } from "../../config/mainConfig";

const CopyToClipboard = props => {
  const { link } = props;
  const [copied, setCopied] = useState(false);
  let domain;
  if (process.env.NODE_ENV === "production") {
    if (siteConfig === "vivacom") {
      domain = "https://insurance.vivacom.bg/";
    } else {
      domain = "https://www.boleron.bg/";
    }
  } else if (process.env.NODE_ENV === "staging") {
    if (siteConfig === "vivacom") {
      domain = "https://boleron-webdemo.azurewebsites.net/";
    } else {
      domain = "https://www.staging.boleron.bg/";
    }
  } else if (process.env.NODE_ENV === "development") {
    domain = "http://localhost:8000/";
  }
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`${domain}${link}`)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div>
      <a className="link" onClick={handleCopyClick}>
        Копирай линк за плащане
      </a>
      {copied && <span style={{ marginLeft: "10px", color: "green" }}>Копирано!</span>}
    </div>
  );
};

export default CopyToClipboard;
