import React, { useState } from "react";
import { sendDocumentsFields } from "../../../config/SendPolicy.page.config";
import SendDocuments from "../../SendDocuments";
import { sendDocuments } from "../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import withParams from "../../HOC/withParams";

const SendPolicy = props => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async data => {
    const { policyDBId, email, files } = data;
    try {
      setLoading(true);
      const response = await sendDocuments({ policyDBId, email, files });
      if (response.status === 200) {
        setLoading(false);
        successAlert("sent successfully!");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  };
  return (
    <SendDocuments
      {...props}
      withLayout
      withCheckboxes={false}
      filtering={["policy"]}
      txtFields={sendDocumentsFields}
      handleSubmit={handleSubmit}
      title="Изпрати документи на застрахователя"
      loading={loading}
    />
  );
};

export default withParams(SendPolicy);
