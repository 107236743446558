import _get from "lodash.get";

export const sendDocumentsFields = [
  {
    name: "email",
    label: "Имейл клиент",
    placeholder: "kristian@boleron.com",
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"],
    defaultValueFunction: props => {
      return _get(props, "defaultEmail");
    }
  }
];
