import React, { useEffect, useState } from "react";
import withValidation from "../../../HOC/withValidation";
import { fields } from "../../../../config/WizardAnnexes/DataChange.page.config";
import { Grid } from "@mui/material";
import WizardButton from "../../common/WizardButton";
import { setWizardStatus, updateAnnex } from "../../../../services/AnnexesService";
import { error as errorAlert } from "../../../../utils/notifications";

const DataChange = props => {
  const [loading, setLoading] = useState(false);
  const { nextStep, passedData, wizardStatus } = props;
  const { annex } = passedData || {};

  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      const { formData } = props;
      setLoading(true);

      try {
        const annexDBId = annex._id;
        await updateAnnex({ data: formData, annexDBId: annexDBId });
        await setWizardStatus({ annexDBId, wizardStatus });
        nextStep();
      } catch (e) {
        errorAlert(e?.response?.data);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {props?.renderFields(props, fields)}
      <WizardButton disabled={loading} loading={loading} handleSubmit={handleSubmit} />
    </>
  );
};

export default withValidation(DataChange, { txtFields: fields });
