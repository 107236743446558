import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import StickersTable from "./StickersTable";
import { Grid } from "@mui/material";
import _get from "lodash.get";
import qs from "qs";
import SearchInput from "./SearchInput";

const Stickers = props => {
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");

  return (
    <PageLayout title="Стикери и зелени карти" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <SearchInput searchQuery={searchQuery} />
        </Grid>
      </Grid>
      <StickersTable searchQuery={searchQuery} />
    </PageLayout>
  );
};

export default Stickers;
