import React, { createElement, useRef, useState } from "react";
import { Alert, Paper } from "@mui/material";
import _get from "lodash.get";
import MaterialTable from "material-table";
import { getStickers } from "../../../services/StickersService";
import { capitalizeFirstLetter, insurerImage, stickerOrGreenCardTxt } from "../../../utils/helpers";
import { grayLight } from "../../../utils/colors";
import StickerStatus from "../../StickerStatus";
import DotsMenu from "../../DotsMenu";
import withMultipleModals from "../../HOC/withMultipleModals";
import * as AllActionModals from "../../ActionModals";
import _find from "lodash.find";
import { getBgTranslation } from "../../StickerStatus/StickerStatus";

const StickersTable = props => {
  const tableRef = useRef();
  const [seqNumber, setSeqNumber] = useState({});
  const searchQuery = _get(props, "searchQuery", "");
  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });

  const handleAction = (seqNumber, option) => {
    if (option.hasModal) {
      setSeqNumber(seqNumber);
      props.closeAllExceptOne(`${option.id}Modal`);
    }
  };
  const refreshData = () => {
    const query = _get(tableRef, "current.state.query", {});
    tableRef.current && tableRef.current.onQueryChange(query);
  };

  return (
    <>
      {Object.keys(modals).map(k => {
        let modal = modals[k];
        return createElement(modal, {
          key: k,
          [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
          toggle: props.toggle,
          close: props.close,
          refreshTable: refreshData,
          objWithEgnAndRequestId: ["changeStatusStickerModal"].includes(k) ? { ...seqNumber, egn: "123456789" } : undefined
        });
      })}

      <MaterialTable
        title="Полици"
        tableRef={tableRef}
        columns={[
          {
            title: "",
            field: "insurerId",
            sorting: false,
            filtering: true,
            filterPlaceholder: "Застраховател",
            lookup: {
              allianz: "Алианц",
              armeec: "Армеец",
              bulins: "Булинс",
              bulstrad: "Булстрад",
              dallbogg: "ДаллБогг",
              dzi: "ДЗИ",
              euroins: "Евроинс",
              generali: "Дженерали",
              levins: "Левинс",
              ozk: "ОЗК",
              uniqa: "Уника"
            },
            filterCellStyle: {
              display: "block",
              position: "relative",
              top: "-51px",
              zIndex: 10,
              borderBottom: "none"
            },
            width: null,
            cellStyle: {
              width: 200,
              minWidth: 200
            },
            render: sticker => (
              <>
                <img src={insurerImage(sticker.insurerId)} alt={sticker.insurerId} style={{ width: 70 }} />
              </>
            )
          },
          {
            title: "",
            field: "type",
            filtering: true,
            filterPlaceholder: "Тип",
            sorting: false,
            lookup: {
              goSticker: stickerOrGreenCardTxt("goSticker"),
              goGreenCard: stickerOrGreenCardTxt("goGreenCard")
            },
            filterCellStyle: {
              display: "block",
              position: "relative",
              top: "-51px",
              left: "calc(200px + 3px)",
              zIndex: 10,
              borderBottom: "none"
            },
            width: null,
            cellStyle: {
              width: 200,
              minWidth: 200
            },
            render: sticker => (
              <div className="horizontally-centered vertical-align">
                <span className="bold">{stickerOrGreenCardTxt(sticker.type)}</span>
              </div>
            )
          },
          {
            title: "",
            field: "status",
            filtering: true,
            filterPlaceholder: "Статус",
            sorting: false,
            lookup: {
              Free: getBgTranslation("Free", "goSticker"),
              Used: getBgTranslation("Used", "goSticker"),
              Reserved: getBgTranslation("Reserved", "goSticker"),
              Cancelled: getBgTranslation("Cancelled", "goSticker")
            },
            filterCellStyle: {
              display: "block",
              position: "relative",
              top: "-51px",
              left: "calc(400px + 6px)",
              zIndex: 10,
              borderBottom: "none"
            },
            width: null,
            cellStyle: {
              width: 200,
              minWidth: 200
            },
            render: sticker => <StickerStatus status={sticker?.status} type={sticker?.type} />
          },
          {
            title: "Префикс",
            width: null,
            cellStyle: {
              width: 100
            },
            field: "prefix",
            sorting: false,
            filtering: false
          },
          {
            title: "Пореден номер",
            width: null,
            cellStyle: {
              minWidth: 150
            },
            field: "seq",
            filtering: false,
            render: sticker => <div className="horizontally-centered vertical-align">{sticker?.seq}</div>
          },
          {
            title: "Email",
            width: null,
            cellStyle: {
              minWidth: 150
            },
            field: "email",
            filtering: false,
            sorting: false,
            render: sticker => <div className="horizontally-centered vertical-align">{sticker?.email || " - "}</div>
          },
          {
            title: "Вноска",
            width: null,
            cellStyle: {
              minWidth: 150
            },
            field: "installment",
            sorting: false,
            filtering: false,
            render: sticker => <div className="horizontally-centered vertical-align">{sticker?.installment || " - "}</div>
          },
          {
            title: "Автомобил",
            width: null,
            cellStyle: {
              minWidth: 150
            },
            field: "carNo",
            filtering: false,
            sorting: false,
            render: sticker => <div className="horizontally-centered vertical-align">{sticker?.carNo || " - "}</div>
          },
          {
            title: "RequestId",
            width: null,
            cellStyle: {
              minWidth: 150
            },
            field: "requestId",
            filtering: false,
            sorting: false,
            render: sticker => <div className="horizontally-centered vertical-align">{sticker?.requestId || " - "}</div>
          },
          {
            title: "",
            field: "",
            width: null,
            cellStyle: {
              width: 100
            },
            sorting: false,
            filtering: false,

            render: seqNumber => {
              return (
                <DotsMenu
                  options={[
                    {
                      id: "changeStatusSticker",
                      name: "Промяна на статус",
                      data: seqNumber,
                      handleAction: option => handleAction(seqNumber, option),
                      hasModal: true
                    }
                  ]}
                />
              );
            }
          }
        ]}
        data={query =>
          new Promise(resolve => {
            getStickers(query).then(result => {
              resolve({
                data: _get(result, "data.seqNumbers"),
                page: _get(result, "data.page") ? _get(result, "data.page") : 0,
                totalCount: _get(result, "data.count") ? _get(result, "data.count") : 0
              });
            });
          })
        }
        options={{
          sorting: true,
          filtering: true,
          search: true,
          searchText: searchQuery,
          pageSize: 50,
          pageSizeOptions: [20, 50, 100, 200, 500],
          padding: "dense",
          debounceInterval: 0,
          emptyRowsWhenPaging: false,
          tableLayout: "auto",
          headerStyle: {
            padding: "6px 2px 20px 2px"
          },
          rowStyle: {
            overflowWrap: "normal"
          }
        }}
        components={{
          Toolbar: () => (
            <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: grayLight }}>
              <div style={{ backgroundColor: grayLight, minWidth: 380, display: "flex", justifyContent: "flex-end", alignItems: "center" }}></div>
            </div>
          ),
          Container: props => <Paper {...props} elevation={0} />
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} от {count}",
            labelRowsSelect: "реда"
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
            searchTooltip: "Търси",
            searchPlaceholder: "Търси"
          },
          body: {
            emptyDataSourceMessage: (
              <Alert severity="info">
                <b>Не са открити съответстващи записи.</b>
              </Alert>
            ),
            filterRow: {
              filterTooltip: "Филтър"
            }
          }
        }}
      />
    </>
  );
};

export default withMultipleModals(StickersTable, [{ id: "changeStatusStickerModal" }]);
