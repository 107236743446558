import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Grid } from "@mui/material";
import { getStatisticsCasco, getStatisticsGO, getStatisticsFines } from "../../../services/PoliciesService";
import { error } from "../../../utils/notifications";
import _get from "lodash.get";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import _orderBy from "lodash.orderby";
import { danger } from "../../../utils/colors";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      statisticsGo: [],
      statisticsCasco: [],
      statisticsFines: [],
      totalCountGo: 0,
      totalCountCasco: 0,
      totalCountFines: 0
    };
  }
  async componentDidMount() {
    let statisticsGo = [];
    let totalCountGo = 0;
    let statisticsCasco = [];
    let totalCountCasco = 0;
    let statisticsFines = [];
    let totalCountFines = 0;
    try {
      const response = await getStatisticsGO();
      statisticsGo = _get(response, "data.value.segments");
      totalCountGo = statisticsGo.reduce(function (result, item) {
        return result + _get(item, '["requests/count"].sum');
      }, 0);
    } catch (err) {
      let errorMessage = "";
      if (err.response) {
        errorMessage = err.response.data ? _get(err, "response.data.message") : JSON.stringify(err.response);
      } else if (err.request) {
        errorMessage = "The request was made but no response was received";
      } else {
        errorMessage = "Something happened in setting up the request that triggered an Error";
      }
      error(errorMessage);
    }

    try {
      const response = await getStatisticsCasco();
      statisticsCasco = _get(response, "data.value.segments");
      totalCountCasco = statisticsCasco.reduce(function (result, item) {
        return result + _get(item, '["requests/count"].sum');
      }, 0);
    } catch (err) {
      let errorMessage = "";
      if (err.response) {
        errorMessage = err.response.data ? _get(err, "response.data.message") : JSON.stringify(err.response);
      } else if (err.request) {
        errorMessage = "The request was made but no response was received";
      } else {
        errorMessage = "Something happened in setting up the request that triggered an Error";
      }
      error(errorMessage);
    }

    try {
      const response = await getStatisticsFines();
      statisticsFines = _get(response, "data.value.segments");
      totalCountFines = statisticsFines.reduce(function (result, item) {
        return result + _get(item, '["requests/count"].sum');
      }, 0);
    } catch (err) {
      let errorMessage = "";
      if (err.response) {
        errorMessage = err.response.data ? _get(err, "response.data.message") : JSON.stringify(err.response);
      } else if (err.request) {
        errorMessage = "The request was made but no response was received";
      } else {
        errorMessage = "Something happened in setting up the request that triggered an Error";
      }
      error(errorMessage);
    }

    this.setState({
      statisticsGo: _orderBy(statisticsGo, ["start"], ["desc"]),
      statisticsCasco: _orderBy(statisticsCasco, ["start"], ["desc"]),
      statisticsFines: _orderBy(statisticsFines, ["start"], ["desc"]),
      totalCountGo,
      totalCountCasco,
      totalCountFines
    });
  }

  handleChange = (event, selectedTab) => {
    this.setState({
      selectedTab
    });
  };

  render() {
    const { statisticsGo, statisticsCasco, statisticsFines, totalCountGo, totalCountCasco, totalCountFines, selectedTab } = this.state;
    return (
      <PageLayout title="Статистика" {...this.props}>
        <br />
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} indicatorColor="primary" textColor="primary" centered onChange={this.handleChange} aria-label="disabled tabs example">
            <Tab label="Гражданска" index={0} />
            <Tab label="Каско" index={1} />
            <Tab label="Глоби" index={2} />
          </Tabs>
        </AppBar>
        {selectedTab === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <h2>Гражданска</h2>
            <p>
              Общо заявки за последните <b>30 дни</b> -&gt;
              <b style={{ color: danger }}> {totalCountGo}</b>
            </p>
            {statisticsGo.length ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Начало</TableCell>
                    <TableCell>Край</TableCell>
                    <TableCell align="right">Брой</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statisticsGo.map((st, index) => {
                    const { start, end } = st;
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {start ? moment(start).format("DD-MM-YYYY HH:mm") : "-"}
                        </TableCell>
                        <TableCell>{end ? moment(end).format("DD-MM-YYYY HH:mm") : "-"}</TableCell>
                        <TableCell align="right">{_get(st, '["requests/count"].sum') ? _get(st, '["requests/count"].sum') : "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <p style={{ textAlign: "center" }}>Няма резултати</p>
            )}
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid item xs={12} sm={12} md={12}>
            <h2>Каско</h2>
            <p>
              Общо заявки за последните <b>30 дни</b> -&gt;
              <b style={{ color: danger }}> {totalCountCasco}</b>
            </p>
            {statisticsCasco.length ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Начало</TableCell>
                    <TableCell>Край</TableCell>
                    <TableCell align="right">Брой</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statisticsCasco.map((st, index) => {
                    const { start, end } = st;
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {start ? moment(start).format("DD-MM-YYYY HH:mm") : "-"}
                        </TableCell>
                        <TableCell>{end ? moment(end).format("DD-MM-YYYY HH:mm") : "-"}</TableCell>
                        <TableCell align="right">{_get(st, '["requests/count"].sum') ? _get(st, '["requests/count"].sum') : "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <p style={{ textAlign: "center" }}>Няма резултати</p>
            )}
          </Grid>
        )}
        {selectedTab === 2 && (
          <Grid item xs={12} sm={12} md={12}>
            <h2>Глоби</h2>
            <p>
              Общо заявки за последните <b>30 дни</b> -&gt;
              <b style={{ color: danger }}> {totalCountFines}</b>
            </p>
            {statisticsFines.length ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Начало</TableCell>
                    <TableCell>Край</TableCell>
                    <TableCell align="right">Брой</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statisticsFines.map((st, index) => {
                    const { start, end } = st;
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {start ? moment(start).format("DD-MM-YYYY HH:mm") : "-"}
                        </TableCell>
                        <TableCell>{end ? moment(end).format("DD-MM-YYYY HH:mm") : "-"}</TableCell>
                        <TableCell align="right">{_get(st, '["requests/count"].sum') ? _get(st, '["requests/count"].sum') : "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <p style={{ textAlign: "center" }}>Няма резултати</p>
            )}
          </Grid>
        )}
      </PageLayout>
    );
  }
}
export default Statistics;
