import React, { useState } from "react";
import { sendDocumentsFields } from "../../../config/SendDocumentsToInsurer.page.config";
import SendDocuments from "../../SendDocuments";
import { sendDocumentsToInsurer } from "../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import withParams from "../../HOC/withParams";
import _get from "lodash.get";
import mainConfig from "../../../config/mainConfig/index";

const SendDocumentsToInsurer = props => {
  const [loading, setLoading] = useState(false);
  const productId = _get(props, "params.productId");
  const insurer = mainConfig.productIdInsurerMap[productId];

  const handleSubmit = async data => {
    const { policyDBId, email, files } = data;
    try {
      setLoading(true);
      const response = await sendDocumentsToInsurer({ policyDBId, email, files });
      if (response.status === 200) {
        setLoading(false);
        successAlert("sent successfully!");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  };
  const sortAdditionalFiles = files => {
    const compareFn = (a, b) => {
      if (a.name === undefined || b.name === undefined) {
        return 1;
      }
      a = /^[\d]+$/.exec(a.name.split(".")[0]);
      b = /^[\d]+$/.exec(b.name.split(".")[0]);
      if (a && a[0] && b && b[0]) {
        const indexA = parseInt(a[0]);
        const indexB = parseInt(b[0]);
        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      }
      return 1;
    };
    return files.sort(compareFn);
  };
  return (
    <SendDocuments
      {...props}
      includeHiddenFiles
      withLayout
      withCheckboxes
      filtering={["policy", "receipt", "otherFile", "identification", "vdogDoubleSigned", "statementDoubleSigned", "declaration", "otherFileSigned", "birthCertificate"]}
      allSelected
      orderFuntion={sortAdditionalFiles}
      txtFields={sendDocumentsFields.filter(f => f.insurer === insurer && (f.products ? f.products.includes(productId) : true))}
      handleSubmit={handleSubmit}
      title="Изпрати документи на застрахователя"
      loading={loading}
    />
  );
};

export default withParams(SendDocumentsToInsurer);
