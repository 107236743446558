import { request } from "./APIservice";
import { POST } from "../constants/api";
import _get from "lodash.get";

export const getOrCreateAnnex = async data => {
  const response = await request(POST, `/get-or-create-annex`, data, {}, true, true);
  return response;
};
export const setWizardStatus = async data => {
  const response = await request(POST, `/annex/set-wizard-status`, data, {}, true, true);
  return response;
};
export const updateAnnex = async data => {
  const response = await request(POST, `/update-annex`, data, {}, true, true);
  return response;
};

export const updateVehicleData = async data => {
  const response = await request(POST, `/update-vehicle-data`, data, {}, true, true);
  return response;
};
