import React, { useState } from "react";
import { Delete as DeleteIcon, Close as CloseIcon } from "@mui/icons-material";
import { Typography, Box, Grid, TextField, Button } from "@mui/material";
import Modal from "../../Modal";
import { danger } from "../../../utils/colors";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { cancelPolicySticker } from "../../../services/PoliciesService";

const CancelPolicyStickerModal = ({ open, onClose, onSuccess, id, installmentNumber }) => {
  const [stickerValue, setStickerValue] = useState("");
  const [greenCardPrefix, setGreenCardPrefix] = useState("");
  const [greenCardValue, setGreenCardValue] = useState("");

  const greenCardNumber = `${greenCardPrefix}${greenCardValue}`;
  const numberPattern = /^[0-9]*$/;
  const characterPattern = /^[A-Z]*$/;
  const validSticker = Boolean((stickerValue?.length === 8 || stickerValue?.length === 9) && stickerValue.match(numberPattern));
  const validPrefix = Boolean(greenCardPrefix?.length === 2 && greenCardPrefix.match(characterPattern));
  const validNumber = Boolean(greenCardValue?.length === 6 && greenCardValue.match(numberPattern));

  async function handleCancelSticker(e) {
    e.preventDefault();
    try {
      const response = await cancelPolicySticker(id, installmentNumber, "sticker", stickerValue);
      if (response.status === 200) {
        successAlert("sent successfully!");
        onSuccess(response.data);
      }
    } catch (e) {
      errorAlert(e.message);
    }
  }

  async function handleCancelGreenCard(e) {
    e.preventDefault();
    try {
      const response = await cancelPolicySticker(id, installmentNumber, "greenCard", greenCardNumber);
      if (response.status === 200) {
        successAlert("sent successfully!");
        onSuccess(response.data);
      }
    } catch (e) {
      errorAlert(e.message);
    }
  }

  const content = (
    <>
      <Typography textAlign="flex-start">Анулирай стикер</Typography>
      <Box mt={3} mb={3}>
        <form onSubmit={e => handleCancelSticker(e)}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                label="Номер"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                size="small"
                onChange={e => setStickerValue(e.target.value)}
              />
              {!validSticker && <p style={{ color: danger }}>Невалиден Стикер - Трябва да е 8 или 9 цифри. </p>}
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" startIcon={<DeleteIcon />} type="submit" color="error" disabled={!validSticker}>
                Анулирай
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Typography textAlign="flex-start" mb={3}>
          Анулирай зелена карта
        </Typography>
        <form onSubmit={e => handleCancelGreenCard(e)}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField label="Префикс" InputLabelProps={{ shrink: true }} size="small" onChange={e => setGreenCardPrefix(e.target.value)} />
              {!validPrefix && <p style={{ color: danger }}>Невалиден Префикс на Зелена карта- Трябва да е 2 ГЛАВНИ букви. </p>}
            </Grid>
            <Grid item xs={4}>
              <TextField label="Номер" InputLabelProps={{ shrink: true }} size="small" onChange={e => setGreenCardValue(e.target.value)} />
              {!validNumber && <p style={{ color: danger }}>Невалиден номер на Зелена карта - Трябва да е 6 цифри.</p>}
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" startIcon={<DeleteIcon />} type="submit" color="error" disabled={!validPrefix || !validNumber}>
                Анулирай
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<CloseIcon />} onClick={onClose}>
          Затвори
        </Button>
      </Box>
    </>
  );

  return <Modal open={open}>{content}</Modal>;
};

export default CancelPolicyStickerModal;
