import React, { useState, useEffect } from "react";
import { TextField, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";

const AsynchronousAutocomplete = ({ name, label, error, autoFocus, autocompleteService, minSearchChars = 3, initialValue, onChange, ...rest }) => {
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeHandle = async value => {
    if (value.length >= minSearchChars) {
      setLoading(true);
      const options = await autocompleteService(value);
      setLoading(false);
      setOptions(options);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue);
    }
  }, []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      clearOnEscape
      open={open}
      onOpen={() => {
        if (options.length) setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => {
        const hasPostCode = Boolean(option?.postCode);
        const hasFullAddressSpeedy = Boolean(option?.address?.localAddressString);
        const hasFullAddressEcont = Boolean(option?.address?.fullAddress);
        const type = option?.type || "";
        if (hasPostCode) {
          return `${type} ${option?.name} (п.к ${option?.postCode})`;
        }
        if (!hasPostCode && hasFullAddressSpeedy) {
          return `${type} ${option?.name} (${option?.address?.localAddressString})`;
        }
        if (!hasPostCode && hasFullAddressEcont) {
          return `${type} ${option?.name} (${option?.address?.fullAddress})`;
        }
        return `${type} ${option?.name}`;
        // return option?.postCode ? `${option?.name} (п.к ${option?.postCode})` : `${option?.name}`;
      }}
      options={options}
      loading={loading}
      value={selected}
      onChange={(e, newValue) => {
        setSelected(newValue);
        onChange({ target: { name, type: "autocomplete", value: newValue?.id, data: newValue } });
      }}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          error={error}
          size="small"
          fullWidth
          label={label}
          variant="outlined"
          onChange={ev => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            // autoComplete: "off",
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          {...rest}
        />
      )}
    />
  );
};

export default AsynchronousAutocomplete;
