import React from "react";
import _get from "lodash.get";
import { checkGOStatus } from "../../services/PoliciesService";
import withSimpleModal from "../HOC/withSimpleModal";
import ResultModal from "../Modals/ResultModal";
import { Grid, Icon } from "@mui/material";
import { primary, success, danger } from "../../utils/colors";
import moment from "moment";

class CheckGoStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { modalName, isCheckGOStatusModalOpen, toggle, objWithEgnAndRequestId, handle, responseData } = this.props;
    const { hasActiveGO, insurer, validFrom, validTo, stickersPaid } = responseData || {};
    const carNo = _get(objWithEgnAndRequestId, "policyData.goRegNumber");
    const modalTitle = "Проверка на статуса на ГО";
    const btnTxt = "Провери";

    return (
      <ResultModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        isModalOpen={isCheckGOStatusModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={objWithEgnAndRequestId}
        handle={handle}
      >
        {responseData ? (
          <>
            <p style={styles.carNo}>{carNo}</p>
            <p>
              {hasActiveGO ? (
                <>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Icon style={styles.successIcon}>check</Icon> има гражданска
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Icon style={styles.primaryIcon}>security</Icon> {insurer || "-"}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Icon style={styles.primaryIcon}>event</Icon>от: {moment(validFrom).utc().format("DD-MM-YYYY")}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Icon style={styles.primaryIcon}>event</Icon>до: {moment(validTo).utc().format("DD-MM-YYYY")}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Icon style={styles.primaryIcon}>label</Icon> платени стикери: {stickersPaid || 0}
                  </Grid>
                </>
              ) : (
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Icon style={styles.dangerIcon}>close</Icon> няма гражданска
                </Grid>
              )}
            </p>
          </>
        ) : null}
      </ResultModal>
    );
  }
}
const generatePayloadRequest = props => {
  const requestPayload = {
    carNo: _get(props, "objWithEgnAndRequestId.policyData.goRegNumber")
  };
  return requestPayload;
};

export default withSimpleModal(CheckGoStatusModal, {
  generatePayloadRequest,
  apiRequest: checkGOStatus,
  modalName: "checkGOStatusModal",
  successMessage: "Проверката приключи!",
  closeAfterRequest: false,
  refreshAfterRequest: false
});

const styles = {
  carNo: { textAlign: "center", fontWeight: "bold" },
  successIcon: { color: success, marginRight: 10 },
  primaryIcon: { color: primary, marginRight: 10 },
  dangerIcon: { color: danger, marginRight: 10 }
};
