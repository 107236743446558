import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Icon,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Chip,
  Checkbox,
  FormControlLabel,
  Stack
} from "@mui/material";
import { AddHome as AddHomeIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import {
  getIndividualPolicy,
  updatePolicy,
  addDuePayments,
  calculatePolicyCommission,
  calculateInstallmentCommission,
  getAdditionalPolicyFiles
} from "../../../services/PoliciesService";
import _get from "lodash.get";
import moment from "moment";
import { connect } from "react-redux";
import StatusBadge from "../Policies/StatusBadge.js";
import PageLayout from "../../layouts/PageLayout";
import { round } from "../../../utils/helpers";
import { txtFields } from "../../../config/EditPolicy.page.config";
import { PDF_SERVICE_URL, URL_GET_ADDITIONAL_FILE } from "../../../utils/config";
import { success, gray, warning } from "../../../utils/colors";
import withParams from "../../../components/HOC/withParams";
import CancelPolicyStickerModal from "./CancelPolicyStickerModal";

export const insurerObj = {
  colonnade: "Колонад",
  coface: "Coface",
  armeec: "Армеец",
  euroins: "Евроинс",
  levins: "Лев Инс",
  generali: "Дженерали",
  instinct: "Инстинкт",
  dzi: "ДЗИ",
  bulstrad: "Булстрад",
  bulstradlife: "Булстрад Живот",
  bulins: "Бул Инс",
  allianz: "Алианц",
  dallbogg: "ДаллБогг",
  uniqa: "ЗК Уника АД",
  uniqaLife: "Уника Живот",
  ozk: "ОЗК",
  metlife: "МетЛайф"
};

const nonCommissionProducts = ["LFE", "LFI", "CI", "OFF"];

const insurerNameEdited = (insurer, product) => {
  if (insurer !== "uniqa") {
    return insurerObj[insurer];
  } else {
    if (product !== "MTN" || product !== "EXT" || product !== "LFE") {
      return "ЗК Уника АД";
    } else {
      return "Уника Живот";
    }
  }
};

class EditPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPromo: false,
      policy: {},
      selectedTab: 0,
      id: props.params.id,
      loading: true,
      insurerId: "",
      installments: 0,
      validFrom: "",
      validTo: "",
      premium: 0,
      tax: 0,
      total: 0,
      insurerName: "",
      requestId: "",
      payments: [],
      product: "",
      paymentsCount: "",
      withFirst: true,
      fullAmountPremium: "",
      fullAmountTax: "",
      fullAmountTotal: "",
      fullAmountPromo: "",
      totalPromo: "",
      fullAmountCommission: "",
      contractDate: "",
      policyId: "",
      numberOfInstallment: 1
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { id } = this.state;
    const response = await getIndividualPolicy(id);
    const response2 = (await getAdditionalPolicyFiles(id)) || {};
    const policy = _get(response, "data");
    const additionalPolicyFiles = _get(response2, "data");
    const additionalPolicyFileNames = additionalPolicyFiles.map(file => file.name);
    const sortedDuePayments = policy?.duepayments?.sort((a, b) => parseFloat(a.paymentId) - parseFloat(b.paymentId));

    const sortedDuePaymentsWithReceiptLink = sortedDuePayments.map(payment => ({
      ...payment,
      receiptLink:
        payment.paymentId === "1"
          ? `${PDF_SERVICE_URL}/${policy._id}/receipt`
          : payment.receiptId && additionalPolicyFileNames.indexOf(`${policy.userId}-${payment.receiptId}.pdf`) !== -1
          ? `${URL_GET_ADDITIONAL_FILE}/${policy._id}/${policy.userId}-${payment.receiptId}.pdf`
          : ""
    }));
    this.setState({
      policy,
      loading: false,
      insurerId: policy.insurerId,
      installments: policy.policyData.installments,
      premium: policy.premium,
      tax: policy.tax,
      total: policy.total,
      insurerName: insurerNameEdited(policy.insurerId, policy.productId),
      requestId: policy.requestId,
      product: policy.productId,
      validFrom: policy.validFrom,
      validTo: policy.validTo,
      payments: sortedDuePaymentsWithReceiptLink,
      fullAmountPremium: policy.fullAmountPremium,
      fullAmountTax: policy.fullAmountTax,
      fullAmountTotal: policy.fullAmountTotal,
      fullAmountPromo: policy.fullAmountPromo,
      totalPromo: policy.totalPromo,
      fullAmountCommission: policy.fullAmountCommission,
      contractDate: policy.contractDate || "",
      isPromo: policy.fullAmountTotal !== policy.fullAmountPromo,
      policyId: policy.policyId
    });
  }

  handleTxtChange = (e, name) => {
    if (name !== "insurerId") {
      this.setState({
        [name]: e.target.value
      });
    } else {
      this.setState({
        insurerName: insurerObj[e.target.value],
        insurerId: e.target.value === "uniqaLife" ? "uniqa" : e.target.value
      });
    }
  };

  validateCurrency = s => {
    const rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  };

  handlePaymentsChange = (e, name, index) => {
    const { payments, insurerId } = this.state;
    let value = e.target.value;
    if (name === "amount" && !this.validateCurrency(value)) {
      value = payments[index][name];
    }
    let newPayments = [
      ...payments.slice(0, index),
      {
        ...payments[index],
        [name]: value
      },
      ...payments.slice(index + 1)
    ];
    if (!nonCommissionProducts.includes(insurerId)) {
      newPayments = [
        ...newPayments.slice(0, index),
        {
          ...newPayments[index],
          ["premium"]: value / 1.02
        },
        ...newPayments.slice(index + 1)
      ];
    }
    this.setState({ payments: newPayments });
  };

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const {
        id,
        insurerId,
        installments,
        validFrom,
        validTo,
        contractDate,
        premium,
        tax,
        total,
        insurerName,
        payments,
        product,
        fullAmountPremium,
        fullAmountTax,
        fullAmountTotal,
        fullAmountPromo,
        totalPromo,
        fullAmountCommission,
        isPromo,
        policyId
      } = this.state;
      const paymentss = payments;
      const user = _get(this.props, "auth.user");
      const fullAmountTotalValue = installments === 1 ? total : fullAmountTotal;
      const fullAmountTotalPromoValue = installments === 1 ? totalPromo : fullAmountPromo;
      const response = await updatePolicy({
        id,
        insurerId,
        installments,
        validTo,
        contractDate,
        validFrom,
        premium,
        tax,
        total,
        insurerName,
        paymentss,
        product,
        fullAmountPremium: installments === 1 ? premium : fullAmountPremium,
        fullAmountTax: installments === 1 ? tax : fullAmountTax,
        fullAmountTotal: fullAmountTotalValue,
        fullAmountPromo: isPromo ? fullAmountTotalPromoValue : fullAmountTotalValue,
        totalPromo: isPromo ? totalPromo : total,
        fullAmountCommission,
        lastModified: moment().format("YYYY-MM-DD HH:mm:ss"),
        lastModifiedUser: _get(user, "email"),
        lastModifiedAction: "editPolicy",
        policyId: policyId.trim()
      });
      if (response.status === 200) {
        this.setState({
          policy: response.data
        });
        successAlert("sent successfully!");
      }
    } catch (e) {
      errorAlert(e.message);
    }
  }

  addDuePayments = async () => {
    const { paymentsCount, withFirst, policy } = this.state;
    try {
      this.setState({ loading: true });
      const response = await addDuePayments({ policyId: policy["_id"], paymentsCount, withFirst });
      this.setState({
        loading: false,
        payments: _get(response, "data")
      });
      successAlert("added successfully!");
    } catch (e) {
      errorAlert(e.message);
    }
  };

  addOneGoInstallment = async e => {
    e.preventDefault();
    const { policy, withFirst, numberOfInstallment } = this.state;
    try {
      const response = await addDuePayments({
        policyId: policy["_id"],
        paymentsCount: 1,
        withFirst,
        paymentIdParam: numberOfInstallment
      });
      // eslint-disable-next-line no-unsafe-optional-chaining
      const newPayments = [...this.state.payments, ...response?.data];
      this.setState({ payments: newPayments });
      successAlert("added successfully!");
    } catch (e) {
      errorAlert(e.message);
    }
  };

  installmentCommission = async (index, installmentTotal, installmentPremium) => {
    const { policy, installments, payments } = this.state;
    const isForeignGOInstallment = !!_get(policy, "policyData.goPolicyNumber");
    const response = await calculateInstallmentCommission({
      policy: {
        insurerId: policy.insurerId,
        productId: policy.productId,
        total: round(installmentTotal),
        premium: round(installmentPremium),
        isForeignGOInstallment: isForeignGOInstallment,
        paymentId: index + 1
      }
    });
    const newPayments = [
      ...payments.slice(0, index),
      {
        ...payments[index],
        commission: response.data
      },
      ...payments.slice(index + 1)
    ];
    this.setState({ payments: newPayments });
  };

  calculate = async () => {
    const { policy, fullAmountPremium, fullAmountTotal, installments, total, premium } = this.state;

    const isForeignGOInstallment = !!_get(policy, "policyData.goPolicyNumber");

    const response = await calculatePolicyCommission({
      policy: {
        insurerId: policy.insurerId,
        productId: policy.productId,
        fullAmountTotal: round(installments === 1 ? total : fullAmountTotal),
        fullAmountPremium: round(installments === 1 ? premium : fullAmountPremium),
        isForeignGOInstallment: isForeignGOInstallment,
        installments,
        total
      }
    });
    this.setState({ fullAmountCommission: _get(response, "data", 0) });
  };

  render() {
    const { policy, loading, insurerId, validFrom, validTo, contractDate, installments, payments, paymentsCount, withFirst, isPromo, policyId, product, numberOfInstallment } =
      this.state;
    const isActive = new Date(policy.validTo).getTime() > new Date().getTime();
    const btnDisabled = new Date(validFrom).getTime() > new Date(validTo).getTime();
    const btnCalculateDisabled = product === "OFF";
    const user = _get(this.props, "auth.user", {});
    const { uid } = user || ""; // back-office user id

    const isAnyCivilLiability = product === "GO";
    const isOnlyCivilLiability = policy?.policyData?.productName === "Гражданска отговорност";
    const goInstallmentIsAdded = Boolean(payments?.find(payment => payment?.paymentId == numberOfInstallment));

    return (
      <PageLayout title="Промяна на полица" {...this.props}>
        {!loading ? (
          <Grid>
            <div style={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid style={{ border: "1px solid #d3d4d5", borderRadius: 12, marginTop: 20 }} item xs={12} sm={3}>
                  <div style={{ marginTop: 10 }}>
                    <Typography style={{ fontSize: "20px", textAlign: "center" }}>Полица</Typography>
                    <p style={useStyles.paragraph}>Полица: {policy.policyId || " - "}</p>
                    <p style={useStyles.paragraph}>Продукт: {policy.policyData.productName}</p>
                    <p style={useStyles.paragraph}>Застраховател: {policy.policyData.insurerName}</p>
                    <p style={useStyles.paragraph}>Вноски: {policy.policyData.installments}</p>
                    <p style={useStyles.paragraph}>Застраховащ: {policy.name}</p>
                    <p style={useStyles.paragraph}>ЕГН: {policy.egn}</p>
                    <p style={useStyles.paragraph}>Телефон: {policy.phone}</p>
                    <p style={useStyles.paragraph}>Имейл: {policy.email}</p>
                    <p style={useStyles.paragraph}>Премия: {policy.premium} лв.</p>
                    <p style={useStyles.paragraph}>Данък: {policy.tax} лв.</p>
                    <p style={useStyles.paragraph}>Общо: {policy.total} лв.</p>
                    <p style={useStyles.paragraph}>От: {moment(policy.validFrom).locale("bg").format("DD.MM.YYYY")}</p>
                    <p style={useStyles.paragraph}>До: {moment(policy.validTo).locale("bg").format("DD.MM.YYYY")}</p>
                    {contractDate && <p style={useStyles.paragraph}>Дата на сключване: {moment(policy.contractDate).locale("bg").format("DD.MM.YYYY")}</p>}
                    <span style={{ float: "right" }}>
                      <StatusBadge isPending={policy.isPending} isActive={isActive} isCancelled={policy.isCancelled} />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography style={{ fontSize: "20px", textAlign: "center" }}>Промяна на полицата:</Typography>
                  <form onSubmit={this.handleSubmit}>
                    <Box mb={2}>
                      <FormControl style={{ minWidth: 350 }} variant="standard">
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                          Застраховател
                        </InputLabel>
                        <Select
                          SelectDisplayProps={{
                            style: { fontSize: 16 }
                          }}
                          labelId="demo-simple-select-placeholder-label-label"
                          id="outlined-size-small"
                          value={insurerId}
                          onChange={e => this.handleTxtChange(e, "insurerId")}
                          displayEmpty
                        >
                          {Object.entries(insurerObj).map(([key, value]) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      mb={2}
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <TextField
                        style={{ minWidth: 350 }}
                        variant="outlined"
                        size="small"
                        label={"Номер на полицата"}
                        value={this.state["policyId"]}
                        onChange={e => this.handleTxtChange(e, "policyId")}
                      />
                    </Box>
                    <Box mb={2}>
                      <FormControl style={{ minWidth: 350 }} variant="standard">
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                          Вноски
                        </InputLabel>
                        <Select
                          SelectDisplayProps={{
                            style: { fontSize: 16 }
                          }}
                          labelId="demo-simple-select-placeholder-label-label"
                          id="outlined-size-small"
                          value={installments}
                          onChange={e => this.handleTxtChange(e, "installments")}
                          displayEmpty
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={12}>12</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box mb={2}>
                      <TextField
                        style={{ minWidth: 350 }}
                        // error={!!_get(formData, `validTo.error`)}
                        onChange={e => this.handleTxtChange(e, "validFrom")}
                        id="datetime-local-from"
                        label="Начална дата"
                        type="date"
                        value={moment(validFrom).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        style={{ minWidth: 350 }}
                        // error={!!_get(formData, `validTo.error`)}
                        onChange={e => this.handleTxtChange(e, "validTo")}
                        id="datetime-local-to"
                        label="Крайна дата"
                        type="date"
                        value={moment(validTo).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        style={{ minWidth: 350 }}
                        onChange={e => this.handleTxtChange(e, "contractDate")}
                        label="Дата на сключване"
                        type="date"
                        value={moment(contractDate).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="standard"
                      />
                    </Box>
                    <FormControlLabel
                      label="Полицата е с допълнителна отстъпка"
                      control={
                        <Checkbox
                          checked={isPromo}
                          onChange={() => {
                            this.setState(prevState => {
                              return {
                                isPromo: !prevState.isPromo
                              };
                            });
                          }}
                        />
                      }
                    />
                    {txtFields.map((field, index) => {
                      if (field.name.includes("fullAmount") && field.name !== "fullAmountCommission" && installments === 1) {
                        return null;
                      }
                      if (field.name.includes("Promo") && !isPromo) {
                        return null;
                      }
                      return (
                        <Box
                          key={index}
                          mb={2}
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <TextField
                            style={{ minWidth: 350 }}
                            variant="outlined"
                            size="small"
                            label={field.label}
                            value={this.state[field.name]}
                            onChange={e => this.handleTxtChange(e, field.name)}
                          />
                          {field.name.includes("fullAmountCommission") && (
                            <Button
                              onClick={this.calculate}
                              variant="contained"
                              size="small"
                              disabled={btnCalculateDisabled}
                              style={{
                                background: !btnCalculateDisabled ? success : gray,
                                color: "#fff",
                                width: "min-content",
                                marginLeft: 15
                              }}
                              endIcon={<Icon>arrow_right</Icon>}
                            >
                              изчисли
                            </Button>
                          )}
                        </Box>
                      );
                    })}
                  </form>
                  {!payments.length && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        marginTop: 25,
                        flexDirection: "column"
                      }}
                    >
                      <Typography fontSize={20} textAlign="center">
                        Добави вноски:
                      </Typography>
                      <Box mb={2}>
                        {[1, 2, 4, 12].map(installment => (
                          <Chip
                            key={installment}
                            onClick={() => {
                              this.setState({
                                paymentsCount: installment
                              });
                            }}
                            size="medium"
                            label={<Typography fontSize={14}>{installment}</Typography>}
                            style={{
                              background: paymentsCount === installment ? warning : gray,
                              color: "white",
                              float: "none",
                              marginRight: 10
                            }}
                          />
                        ))}
                      </Box>
                      <Box mb={2}>
                        {["Включително първата вноска", "Без първата вноска"].map((el, index) => (
                          <Chip
                            key={index}
                            onClick={() => {
                              this.setState({
                                withFirst: index !== 1
                              });
                            }}
                            size="medium"
                            label={<Typography fontSize={14}>{el}</Typography>}
                            style={{
                              background: (index !== 1) === withFirst ? warning : gray,
                              color: "white",
                              float: "none",
                              marginRight: 10
                            }}
                          />
                        ))}
                      </Box>
                      <Button
                        onClick={this.addDuePayments}
                        disabled={!paymentsCount || loading}
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{
                          background: paymentsCount && !loading ? success : gray,
                          color: "#fff",
                          width: "min-content",
                          marginRight: 15,
                          alignSelf: "flex-end"
                        }}
                        endIcon={<Icon>arrow_right</Icon>}
                      >
                        Добави
                      </Button>
                    </div>
                  )}

                  {isOnlyCivilLiability && (
                    <Box mt={6} mb={3}>
                      <form
                        onSubmit={e => {
                          this.addOneGoInstallment(e);
                        }}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FormControl style={{ minWidth: 350 }} variant="standard">
                            <InputLabel shrink>Номер на вноска</InputLabel>
                            <Select
                              SelectDisplayProps={{
                                style: { fontSize: 16 }
                              }}
                              value={this.state.numberOfInstallment}
                              onChange={e => {
                                this.setState({ numberOfInstallment: e.target.value });
                              }}
                              displayEmpty
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                            </Select>
                          </FormControl>
                          <Button variant="contained" size="medium" type="submit" disabled={goInstallmentIsAdded} endIcon={<AddHomeIcon />}>
                            Добави вноска
                          </Button>
                        </Stack>
                      </form>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </div>
            <div style={{ flexGrow: 1, marginTop: 25 }}>
              {payments.map((payment, index) => {
                const calculatedCommision = payment.commissionCoefficient * _get(payment, "amount", 0);
                const commission = _get(payment, "commission", 0);
                const btnCalculateCommissionDisabled = nonCommissionProducts.includes(product);
                return (
                  <Grid
                    key={`payment-info-item-${index}`}
                    container
                    spacing={3}
                    style={{
                      border: "1px solid #d3d4d5",
                      borderRadius: 12,
                      paddingBottom: 15,
                      marginBottom: 25
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <div style={{ border: "1px solid black", borderRadius: 12, marginTop: 20 }}>
                        <p style={{ textAlign: "center" }}>Вноска: {payment.paymentId}</p>
                        <p style={useStyles.paragraph}>Сума: {round(_get(payment, "amount", 0))}</p>
                        <p style={useStyles.paragraph}>Дата на плащане 1: {`${moment(payment.dueDate).format("DD.MM.YYYY")} г.`}</p>
                        <p style={useStyles.paragraph}>Дата на падеж: {`${moment(payment.contractDueDate).format("DD.MM.YYYY")} г.`}</p>
                        <p style={useStyles.paragraph}>Начин на плащане: {payment.isOffline ? "Наложен платеж" : "Карта"}</p>
                        <p style={useStyles.paragraph}>Платена: {payment.toProcess ? "Не" : "Да"}</p>
                        {payment?.cancelledDocuments?.cancelledGreenCards?.map(cancelledGreenCard => (
                          <p
                            style={useStyles.paragraph}
                            key={`canceled-green-card-${cancelledGreenCard?._id}`}
                          >{`Анулиран БСО: номер ${cancelledGreenCard?.greenCardWithPrefix}, ${cancelledGreenCard?.price}лв`}</p>
                        ))}
                        {payment?.cancelledDocuments?.cancelledStickers?.map(cancelledSticker => (
                          <p
                            style={useStyles.paragraph}
                            key={`canceled-sticker-${cancelledSticker?._id}`}
                          >{`Анулиран БСО: номер ${cancelledSticker?.stickerNumber}, ${cancelledSticker?.price}лв`}</p>
                        ))}
                        {!payment.toProcess && payment?.receiptLink && (
                          <p style={useStyles.paragraph}>
                            <a href={payment.receiptLink} target="_blank" rel="noopener noreferrer">
                              Разписка
                            </a>
                          </p>
                        )}
                        <p style={useStyles.paragraph}>Комисионна в проценти: {`${payment.commissionCoefficient * 100}%`}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography fontSize={20} mb={2} textAlign="center">
                        Промяна на вноскa: {payment.paymentId}
                      </Typography>
                      <div>
                        <Box mb={2}>
                          <TextField
                            style={{ minWidth: 350 }}
                            onChange={e => this.handlePaymentsChange(e, "amount", index)}
                            variant="outlined"
                            size="small"
                            label="Сума"
                            value={_get(payment, "amount", 0)}
                          />
                        </Box>
                        <Box mb={2}>
                          <TextField
                            style={{ minWidth: 350 }}
                            onChange={e => this.handlePaymentsChange(e, "commission", index)}
                            variant="outlined"
                            size="small"
                            label="commission"
                            name="commission"
                            value={_get(payment, "commission", 0)}
                          />
                          <Button
                            onClick={e => this.installmentCommission(index, _get(payment, "amount", 0), _get(payment, "premium", 0))}
                            variant="contained"
                            size="small"
                            disabled={btnCalculateCommissionDisabled}
                            style={{
                              background: btnCalculateCommissionDisabled ? gray : success,
                              color: "#fff",
                              width: "min-content",
                              marginLeft: 15
                            }}
                            endIcon={<Icon>arrow_right</Icon>}
                          >
                            изчисли
                          </Button>
                        </Box>
                        <Box mb={2}>
                          <TextField
                            style={{ minWidth: 350 }}
                            onChange={e => this.handlePaymentsChange(e, "promoAmount", index)}
                            variant="outlined"
                            size="small"
                            label="promoAmount"
                            value={_get(payment, "promoAmount", 0)}
                          />
                        </Box>
                        <Box mb={2}>
                          <FormControl style={{ minWidth: 350 }} variant="standard">
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                              Наложен платеж
                            </InputLabel>
                            <Select
                              SelectDisplayProps={{
                                style: { fontSize: 16 }
                              }}
                              labelId="demo-simple-select-placeholder-label-label"
                              value={payment.isOffline}
                              onChange={e => this.handlePaymentsChange(e, "isOffline", index)}
                              displayEmpty
                            >
                              <MenuItem value={true}>Да</MenuItem>
                              <MenuItem value={false}>Не</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box mb={2}>
                          <TextField
                            style={{ minWidth: 350 }}
                            // error={!!_get(formData, `validTo.error`)}
                            onChange={e => this.handlePaymentsChange(e, "dueDate", index)}
                            id="datetime-local-from"
                            label="Дата на плащане"
                            type="date"
                            value={moment(payment.dueDate).format("YYYY-MM-DD")}
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="standard"
                          />
                        </Box>
                        <Box mb={2}>
                          <TextField
                            style={{ minWidth: 350 }}
                            // error={!!_get(formData, `validTo.error`)}
                            onChange={e => this.handlePaymentsChange(e, "contractDueDate", index)}
                            id="datetime-local-from"
                            label="Дата на падеж"
                            type="date"
                            value={moment(payment.contractDueDate).format("YYYY-MM-DD")}
                            InputLabelProps={{
                              shrink: true
                            }}
                            variant="standard"
                          />
                        </Box>
                      </div>
                      {isAnyCivilLiability && (
                        <Box>
                          <Button
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            size="small"
                            disabled={!policy.policyId}
                            onClick={() =>
                              this.setState({
                                cancelPolicyStickerModal: true,
                                cancelInstallmentNumber: payment.paymentId
                              })
                            }
                          >
                            Анулирай БСО
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              <Button
                onClick={this.handleSubmit}
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: !btnDisabled ? success : gray,
                  color: "#fff",
                  width: "min-content",
                  float: "right",
                  marginRight: 15
                }}
                startIcon={<Icon>save</Icon>}
              >
                Запази
              </Button>
              <br />
            </div>
            <CancelPolicyStickerModal
              open={this.state.cancelPolicyStickerModal}
              onClose={() => this.setState({ cancelPolicyStickerModal: false })}
              onSuccess={data => {
                const allPayments = this.state.payments;
                const paymentToUpdate = allPayments.find(payment => payment.paymentId === this.state.cancelInstallmentNumber);
                if (paymentToUpdate) {
                  paymentToUpdate.cancelledDocuments = data.cancelledDocuments;
                }
                this.setState({ payments: allPayments, cancelPolicyStickerModal: false });
              }}
              id={this.state.id}
              installmentNumber={this.state.cancelInstallmentNumber}
            />
          </Grid>
        ) : (
          <div style={{ color: "grey", fontSize: 14, textAlign: "center" }}>
            {loading && (
              <Typography gutterBottom variant="body1" component="span">
                <p>Зареждане на полица ...</p>
              </Typography>
            )}
            {loading && <CircularProgress size={20} />}{" "}
          </div>
        )}
      </PageLayout>
    );
  }
}

const useStyles = {
  paragraph: {
    paddingLeft: 10,
    fontSize: 18
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(withParams(EditPolicy));
