import _get from "lodash.get";
export const fields = [
  {
    name: "talonNo",
    label: "НОМЕР НА ТАЛОН",
    placeholder: "001122345",
    validators: ["required", "checkTalon"],
    readOnly: true,
    errorMessages: ["Полето е задължително", "Въведете валиден номер на талон"],
    type: "text",
    defaultValueFunction: props => {
      return _get(props, "passedData.annex.annexData.savedCar.talonNo", "");
    }
  },
  {
    name: "carNo",
    label: "РЕГ. НОМЕР",
    placeholder: "CB1111AA",
    readOnly: true,
    validators: ["required", "checkRegNumber"],
    errorMessages: ["Полето е задължително", "Въведете валиден номер на автомобил"],
    type: "text",
    defaultValueFunction: props => {
      return _get(props, "passedData.annex.annexData.savedCar.carNo", "");
    }
  },
  {
    name: "names",
    type: "text",
    label: "ИМЕНА",
    placeholder: "",
    defaultValueFunction: props => {
      const savedCar = _get(props, "passedData.annex.annexData.savedCar", {});
      const { ownerData } = savedCar;
      return `${ownerData?.firstName || ""} ${ownerData?.middleName || ""} ${ownerData?.lastName || ""}`;
    },
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "cityName",
    type: "text",
    label: "НАСЕЛЕНО МЯСТО",
    placeholder: "",
    defaultValueFunction: props => {
      const savedCar = _get(props, "passedData.annex.annexData.savedCar", {});
      const { ownerData } = savedCar;
      return `${ownerData?.cityName || ""}`;
    },
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "address",
    type: "text",
    label: "АДРЕС",
    placeholder: "",
    defaultValueFunction: props => {
      const savedCar = _get(props, "passedData.annex.annexData.savedCar", {});
      const { ownerData } = savedCar;
      return `${ownerData?.address || ""}`;
    },
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  }
];
