import React, { useState, useEffect } from "react";
import { Box, Divider, TextField, Typography, Grid } from "@mui/material";

const PaymentsCSCGO = props => {
  const [payments, setPayments] = useState({});
  useEffect(() => {
    setPayments(props.payments);
  });
  const handleChange = e => {
    props.handleChange(e);
  };
  const handleBlur = e => {
    props.handleBlur(e);
  };
  let i = 0;
  if (payments && Object.keys(payments).length) {
    return (
      <Grid container spacing={3}>
        {Object.entries(payments).map(v => {
          const [key, value] = v;
          return (
            <Grid item xs={4} key={key}>
              <Divider />
              <Typography fontSize={15} mb={2} textAlign="center">
                <b>{key === "1" ? "1 Вноска" : `${key} Вноски`}</b>
              </Typography>
              {Object.keys(value).map(k => {
                let installment = value[k];
                if (["sum", "active", "original"].indexOf(k) !== -1) {
                  return;
                }
                i = i + 1;
                return (
                  <div key={i}>
                    <Divider />
                    <Typography fontSize={12} mb={2} textAlign="left">
                      <b>Вноска {installment.number}</b>
                    </Typography>
                    <Box mb={2}>
                      <TextField
                        type="number"
                        name={`originalPriceCSC:r${i}:`}
                        style={{ minWidth: 75 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        size="small"
                        label="Стандартна цена (каско)"
                        value={installment.original_price_csc}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        type="number"
                        name={`originalPriceGO:r${i}:`}
                        style={{ minWidth: 75 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        size="small"
                        label="Стандартна цена (гражданска)"
                        value={installment.original_price_go}
                      />
                    </Box>
                    <Box>Обща цена: {parseFloat(installment.original_price).toFixed(2)}</Box>
                    <Box>Цена с отстъпка плюс такса: {parseFloat(installment.premium_with_tax).toFixed(2)}</Box>
                    <br />
                  </div>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export default PaymentsCSCGO;
