import _get from "lodash.get";

export const sendDocumentsFields = [
  {
    name: "email",
    label: "ИМЕЙЛ",
    placeholder: "test@boleron.com",
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"],
    defaultValueFunction: props => {
      return props?.params?.defaultEmail ?? props?.defaultEmail;
    }
  }
];
