import React from "react";
import { ADDITONAL_PDF_SERVICE_URL, PDF_SERVICE_URL } from "../../utils/config";
import { danger, gray, primary, success } from "../../utils/colors";
import { getAdditionalPolicyFiles } from "../../services/PoliciesService";
import _get from "lodash.get";
import { downloadPdfByPolicyId } from "../../services/Shipment/Shipment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyOid: _get(props, "policy._id", ""),
      isPending: props.policy.isPending,
      additionalFiles: [],
      policyFolderExists: false,
      receiptFileExists: false,
      vDogFileExists: false,
      policyFileExists: false,
      identificationFileExists: false
    };
  }

  sortAdditionalFiles = files => {
    const compareFn = (a, b) => {
      if (a.name === undefined || b.name === undefined) {
        return 1;
      }
      a = /^[\d]+$/.exec(a.name.split(".")[0]);
      b = /^[\d]+$/.exec(b.name.split(".")[0]);
      if (a && a[0] && b && b[0]) {
        const indexA = parseInt(a[0]);
        const indexB = parseInt(b[0]);
        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      }
      return 1;
    };
    return files.sort(compareFn);
  };

  componentDidMount = async () => {
    const { policyOid } = this.state;
    const response = (await getAdditionalPolicyFiles(policyOid)) || {};
    const policyAllFiles = response.data || [];
    const policyFolderExists = policyAllFiles.length > 0;
    const receiptFileExists = policyAllFiles.some(object => object.type === "receipt");
    const vDogFileExists = policyAllFiles.some(object => object.type === "vdog");
    const policyFileExists = policyAllFiles.some(object => object.type === "policy");
    const shipmentFileExists = policyAllFiles.some(object => object.type === "shipment");
    const identificationFileExists = policyAllFiles.some(object => object.type === "identification");
    const annexFileExists = policyAllFiles.some(object => object.type === "annex");
    const birthCertificateExists = policyAllFiles.some(object => object.type === "birthCertificate");
    let receipt = {};
    let vdog = {};
    let shipment = {};
    let identification = {};
    let annex = {};
    let birthCertificate = {};
    const files = policyAllFiles.filter(
      fileBlob =>
        fileBlob.hidden === false &&
        ["otherFile", "installment", "vdogDoubleSigned", "statementDoubleSigned", "declaration", "otherFileSigned", "annex", "birthCertificate", "receiptInsurer"].includes(
          fileBlob.type
        )
    );
    const hiddenFiles = policyAllFiles.filter(fileBlob => fileBlob.hidden === true && ["otherFile", "receiptInsurer", "greencard"].includes(fileBlob.type));
    if (receiptFileExists) {
      receipt = policyAllFiles.find(fileBlob => fileBlob.type === "receipt") || {};
    }
    if (vDogFileExists) {
      vdog = policyAllFiles.find(fileBlob => fileBlob.type === "vdog") || {};
    }
    if (shipmentFileExists) {
      shipment = policyAllFiles.find(fileBlob => fileBlob.type === "shipment") || {};
    }
    if (identificationFileExists) {
      identification = policyAllFiles.find(fileBlob => fileBlob.type === "identification") || {};
    }
    if (annexFileExists) {
      annex = policyAllFiles.find(fileBlob => fileBlob.type === "annex") || {};
    }
    if (birthCertificateExists) {
      birthCertificate = policyAllFiles.find(fileBlob => fileBlob.type === "birthCertificate") || {};
    }

    const additionalFiles = this.sortAdditionalFiles([...files, ...hiddenFiles, receipt, vdog, shipment, identification]);
    this.setState({ additionalFiles, policyFolderExists, receiptFileExists, vDogFileExists, policyFileExists, identificationFileExists, annexFileExists, birthCertificate });
  };
  checkFileExtAndReturnLink = (fileName, policy, uid) => {
    const re = /(?:\.([^.]+))?$/;
    let ext = re.exec(fileName)[1];
    if (ext === "xlsx") {
      return `${ADDITONAL_PDF_SERVICE_URL}/${policy._id}/${fileName}`;
    }
    return `${ADDITONAL_PDF_SERVICE_URL}/${policy._id}/${fileName}/${uid}`;
  };
  render() {
    const { policy, uid } = this.props;
    const { additionalFiles, receiptFileExists, vDogFileExists, policyFileExists } = this.state;
    const pdfIcon = <PictureAsPdfIcon sx={{ mr: 1 }} />;
    return (
      <>
        {policyFileExists && (
          <div className="additionalFiles vertical-align">
            {pdfIcon}
            <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${policy._id}/policy`} target="_blank" rel="noopener noreferrer" className="document-link">
              Полица
            </a>
          </div>
        )}
        {additionalFiles.length
          ? additionalFiles.map((file, index) => {
              if (
                ["otherFile", "receiptInsurer", "greencard", "installment", "vdogDoubleSigned", "statementDoubleSigned", "declaration", "otherFileSigned", "annex"].includes(
                  file.type
                )
              ) {
                return (
                  <div className="additionalFiles vertical-align" key={`additionaFile_${index}`}>
                    {pdfIcon}
                    <a
                      style={{ color: file.hidden ? gray : primary }}
                      href={this.checkFileExtAndReturnLink(file.name, policy, uid)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="document-link"
                    >
                      {file.name}
                    </a>
                  </div>
                );
              } else if (file.type === "receipt") {
                return (
                  <div className="additionalFiles vertical-align" key={`receipt_${index}`}>
                    {pdfIcon}
                    <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${policy._id}/${file.type}/${uid}`} target="_blank" rel="noopener noreferrer" className="document-link">
                      Разписка
                    </a>
                  </div>
                );
              } else if (file.type === "vdog") {
                return (
                  <div className="additionalFiles vertical-align" key={`vDog_${index}`}>
                    {pdfIcon}
                    <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${policy._id}/${file.type}/${uid}`} target="_blank" rel="noopener noreferrer" className="document-link">
                      Възлагателен договор
                    </a>
                  </div>
                );
              } else if (["shipment"].includes(file.type)) {
                return (
                  <div className="additionalFiles vertical-align" key={`shipment_${index}`}>
                    {pdfIcon}
                    <span
                      style={{ color: primary }}
                      onClick={() => downloadPdfByPolicyId({ policyId: file?.shipment?.policyId, requestId: file?.shipment?.requestId, courier: file?.shipment?.requestId })}
                      className="document-link"
                    >
                      {file?.name}
                    </span>
                  </div>
                );
              } else if (file.type === "identification") {
                return (
                  <div className="additionalFiles vertical-align" key={`vDog_${index}`}>
                    {pdfIcon}
                    <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${policy._id}/${file.type}/${uid}`} target="_blank" rel="noopener noreferrer" className="document-link">
                      Идентификация
                    </a>
                  </div>
                );
              } else if (file.type === "birthCertificate") {
                return (
                  <div className="additionalFiles vertical-align" key={`birthCertificate_${index}`}>
                    {pdfIcon}
                    <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${file.name}/birthCertificate`} target="_blank" rel="noopener noreferrer" className="document-link">
                      {file.displayname}
                    </a>
                  </div>
                );
              }
            })
          : null}
        {!receiptFileExists && (
          <div className="additionalFiles vertical-align">
            {pdfIcon}
            <p style={{ color: danger, textDecoration: "line-through", margin: 0 }}>Разписка</p>
          </div>
        )}
        {!vDogFileExists && (
          <div className="additionalFiles vertical-align">
            {pdfIcon}
            <p style={{ color: danger, textDecoration: "line-through", margin: 0 }}>Възлагателен договор</p>
          </div>
        )}
        {!policyFileExists && (
          <div className="additionalFiles vertical-align">
            {pdfIcon}
            <p style={{ color: danger, textDecoration: "line-through", margin: 0 }}>Полица</p>
          </div>
        )}
      </>
    );
  }
}

export default Files;
