import PolicyIssuanceGO from "../../Wizard/GO/PolicyIssuance";
import PolicyIssuanceCSC from "../../Wizard/CSC/PolicyIssuance";
import PolicyIssuanceCSCGO from "../../Wizard/CSCGO/PolicyIssuance";
import UploadFileForm from "../UploadFileForm";
import Shipment from "../../pages/Shipment";
import SendDocuments from "../../pages/SendDocuments";
import SendDocumentsLFE from "../../Wizard/LFE/SendDocuments";
import MoveToIssued from "../../Wizard/GO/MoveToIssued";
import MoveToIssuedLFE from "../../Wizard/LFE/MoveToIssued";

import InsurerData from "../../Wizard/OFF/InsurerData";
import InsuranceDetails from "../../Wizard/OFF/InsuranceDetails";
import Premium from "../../Wizard/OFF/Premium";
import Installments from "../../Wizard/OFF/Installments";
import AttachmentsOFF from "../../Wizard/OFF/Attachments";
import AttachmentsLFE from "../../Wizard/LFE/Attachments";
import CreatePolicy from "../../Wizard/LFE/CreatePolicy";
import Done from "../../Wizard/OFF/Done";
import ShipmentCSCGO from "../../Wizard/CSCGO/Shipment";
import SendDocumentsCSCGO from "../../Wizard/CSCGO/SendDocuments";

import React from "react";
import _get from "lodash.get";

export const getOptions = (policy, variant, subVariant, currentProduct = "") => {
  let productId = _get(policy, "productId");

  if (currentProduct === "CSC+GO") {
    const cscPolicyOfferStatus = _get(policy, "offerStatus");
    const isOffer = _get(policy, "isOffer");
    if (productId === "GO" && variant === "queries")
      return [
        { id: "1", name: "Издаване на полица", disabled: false, hidden: false },
        { id: "2", name: "Прикачи файл", disabled: false, hidden: false },
        { id: "3", name: "Доставка", disabled: false, hidden: false },
        { id: "4", name: "Изпрати документи", disabled: false, hidden: false }
      ];
    if (productId === "CSC" && variant === "queries" && (cscPolicyOfferStatus === "offerstatusconfirmed" || !isOffer))
      return [
        { id: "1", name: "Издаване на полица", disabled: false, hidden: false },
        { id: "2", name: "Доставка", disabled: false, hidden: false },
        { id: "3", name: "Изпрати документи", disabled: false, hidden: false }
      ];
  }

  if (productId === "GO" && variant === "queries" && !subVariant)
    return [
      { id: "1", name: "Издаване на полица", disabled: false, hidden: false },
      { id: "2", name: "Прикачи файл", disabled: false, hidden: false },
      { id: "3", name: "Доставка", disabled: false, hidden: false },
      { id: "4", name: "Изпрати документи", disabled: false, hidden: false }
    ];

  if (productId === "GO" && variant === "installments")
    return [
      { id: "1", name: "Издаване на вноска", disabled: false, hidden: false },
      { id: "2", name: "Прикачи файл", disabled: false, hidden: false },
      { id: "3", name: "Доставка", disabled: false, hidden: false },
      { id: "4", name: "Изпрати документи", disabled: false, hidden: false }
    ];
  if (productId === "CSC" && variant === "queries" && subVariant === "confirmed")
    return [
      { id: "1", name: "Прикачи полица", disabled: false, hidden: false },
      { id: "2", name: "Доставка", disabled: false, hidden: false },
      { id: "3", name: "Изпрати документи", disabled: false, hidden: false }
    ];
  if (productId === "PRP" && variant === "installments" && subVariant === "confirmed") return [{ id: "1", name: "Прикачи разписка", disabled: false, hidden: false }];
  if (productId === "CSC" && variant === "installments" && subVariant === "confirmed")
    return [
      { id: "1", name: "Прикачи разписка", disabled: false, hidden: false }
      // { id: "2", name: "Изпрати документи", disabled: false, hidden: false }
    ];
  if (productId === "OFF" && variant === "policies" && subVariant === "active")
    return [
      { id: "1", name: "Данни на застраховащ", disabled: false, hidden: false },
      { id: "2", name: "Данни за застраховката", disabled: false, hidden: false },
      { id: "3", name: "Застрахователна премия", disabled: false, hidden: false },
      { id: "4", name: "Вноски по полицата", disabled: false, hidden: false },
      { id: "5", name: "Прикачи документи", disabled: false, hidden: false }
    ];
  if (["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(productId) && variant === "queries" && subVariant === "confirmed")
    return [
      { id: "1", name: "Създай полица", disabled: false, hidden: false },
      { id: "2", name: "Прикачи полица", disabled: false, hidden: false },
      { id: "3", name: "Изпрати документи", disabled: false, hidden: false }
    ];

  return [];
};

export const getStepsForIssuePolicyWizardModal = props => {
  const { objWithEgnAndRequestId, refreshTable, variant, subVariant, currentProduct } = props;
  const { policyDBId, paymentId, productId, email, name } = objWithEgnAndRequestId || {};
  let steps;
  if (productId === "GO" && variant === "queries")
    steps = [
      {
        id: "policyIssuance",
        label: "Издаване на полица",
        component: <PolicyIssuanceGO isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} />,
        hideContinueBtn: true
      },
      {
        id: "uploadFileForm",
        label: "Прикачи файл",
        component: (
          <UploadFileForm
            policy={objWithEgnAndRequestId}
            refreshData={refreshTable}
            type="policies"
            subVariant={subVariant}
            typeDocument="policy"
            isFromWizard
            wizardStatus="step2"
          />
        )
      },
      {
        id: "shipment",
        label: "Подготви пратка",
        component: <Shipment policyDBId={policyDBId} isFromWizard wizardStatus="step3" refreshData={refreshTable} variant={variant} />
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: (
          <SendDocuments
            policyDBId={policyDBId}
            installment={paymentId}
            productId={productId}
            defaultEmail={email}
            defaultName={name}
            isFromWizard
            refreshData={refreshTable}
            wizardStatus="step4"
            variant={variant}
          />
        )
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step5" variant={variant} />,
        noStepper: true
      }
    ];

  if (productId === "GO" && variant === "queries" && currentProduct === "CSC+GO")
    steps = [
      {
        id: "policyIssuance",
        label: "Издаване на полица",
        component: <PolicyIssuanceGO isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} />
      },
      {
        id: "uploadFileForm",
        label: "Прикачи файл",
        component: (
          <UploadFileForm
            policy={objWithEgnAndRequestId}
            refreshData={refreshTable}
            type="policies"
            subVariant={subVariant}
            typeDocument="policy"
            isFromWizard
            wizardStatus="step2"
          />
        )
      },
      {
        id: "shipment",
        label: "Подготви пратка",
        component: <ShipmentCSCGO policy={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step2" wizardStatus2="step3" />
        // hideContinueBtn: true
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: <SendDocumentsCSCGO policy={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step3" wizardStatus2="step4" />,
        hideContinueBtn: true
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step5" wizardStatus2="step5" variant={variant} />,
        noStepper: true,
        hideContinueBtn: true
      }
    ];
  if (productId === "GO" && variant === "installments")
    steps = [
      {
        id: "policyIssuance",
        label: "Издаване на вноска",
        component: <PolicyIssuanceGO isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} />,
        hideContinueBtn: true
      },
      {
        id: "uploadFileForm",
        label: "Прикачи файл",
        component: (
          <UploadFileForm
            policy={objWithEgnAndRequestId}
            refreshData={refreshTable}
            type="installments"
            subVariant={subVariant}
            typeDocument="installment"
            isFromWizard
            wizardStatus="step2"
          />
        )
      },
      {
        id: "shipment",
        label: "Подготви пратка",
        component: <Shipment policyDBId={policyDBId} isFromWizard wizardStatus="step3" refreshData={refreshTable} variant={variant} />
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: (
          <SendDocuments
            policyDBId={policyDBId}
            installment={paymentId}
            productId={productId}
            defaultEmail={email}
            defaultName={name}
            isFromWizard
            refreshData={refreshTable}
            wizardStatus="step4"
            variant={variant}
          />
        )
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step5" variant={variant} />,
        noStepper: true
      }
    ];
  if (productId === "CSC" && variant === "queries" && currentProduct !== "CSC+GO")
    steps = [
      {
        id: "policyIssuance",
        label: "Прикачи полица",
        component: <PolicyIssuanceCSC isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} variant={variant} refreshData={refreshTable} subVariant={subVariant} />,
        hideContinueBtn: true
      },
      {
        id: "shipment",
        label: "Подготви пратка",
        component: <Shipment policyDBId={policyDBId} isFromWizard wizardStatus="step2" refreshData={refreshTable} variant={variant} />
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: (
          <SendDocuments
            policyDBId={policyDBId}
            installment={paymentId}
            productId={productId}
            defaultEmail={email}
            defaultName={name}
            isFromWizard
            refreshData={refreshTable}
            wizardStatus="step3"
            variant={variant}
          />
        )
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step4" variant={variant} />,
        noStepper: true
      }
    ];

  if (productId === "CSC" && variant === "queries" && currentProduct === "CSC+GO")
    steps = [
      {
        id: "policyIssuance",
        label: "Издаване на полица",
        component: <PolicyIssuanceCSCGO isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} variant={variant} refreshData={refreshTable} subVariant={subVariant} />,
        hideContinueBtn: true
      },
      {
        id: "shipment",
        label: "Подготви пратка",
        component: <ShipmentCSCGO policy={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step2" wizardStatus2="step3" />
        // hideContinueBtn: true
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: <SendDocumentsCSCGO policy={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step3" wizardStatus2="step4" />,
        hideContinueBtn: true
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step4" wizardStatus2="step5" variant={variant} />,
        noStepper: true,
        hideContinueBtn: true
      }
    ];

  if (productId === "PRP" && variant === "installments")
    steps = [
      {
        id: "uploadFileForm",
        label: "Прикачи файл",
        component: (
          <UploadFileForm
            policy={objWithEgnAndRequestId}
            refreshData={refreshTable}
            type="installments"
            subVariant={subVariant}
            typeDocument="installment"
            isFromWizard
            wizardStatus="step1"
          />
        )
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step3" variant={variant} />,
        noStepper: true
      }
    ];

  if (productId === "CSC" && variant === "installments")
    steps = [
      {
        id: "uploadFileForm",
        label: "Прикачи файл",
        component: (
          <UploadFileForm
            policy={objWithEgnAndRequestId}
            refreshData={refreshTable}
            type="installments"
            subVariant={subVariant}
            typeDocument="installment"
            isFromWizard
            wizardStatus="step1"
          />
        )
      },
      // {
      //   id: "sendDocuments",
      //   label: "Изпрати документи",
      //   component: (
      //     <SendDocuments
      //       policyDBId={policyDBId}
      //       installment={paymentId}
      //       productId={productId}
      //       defaultEmail={email}
      //       defaultName={name}
      //       isFromWizard
      //       refreshData={refreshTable}
      //       wizardStatus="step2"
      //       variant={variant}
      //     />
      //   )
      // },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssued policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step3" variant={variant} />,
        noStepper: true
      }
    ];
  if (productId === "OFF")
    steps = [
      {
        id: "insurerData",
        label: "Данни на застраховащ",
        component: <InsurerData isFromWizard />,
        hideContinueBtn: true
      },
      {
        id: "insuranceDetails",
        label: "Данни за застраховката",
        component: <InsuranceDetails isFromWizard />,
        hideContinueBtn: true
      },
      {
        id: "premium",
        label: "Застрахователна премия",
        component: <Premium isFromWizard />,
        hideContinueBtn: true
      },
      {
        id: "installments",
        label: "Вноски по полицата",
        component: <Installments isFromWizard refreshData={refreshTable} wizardStatus="step4" />,
        hideContinueBtn: true
      },
      {
        id: "attachments",
        label: "Прикачи документи",
        component: <AttachmentsOFF isFromWizard policy={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step5" />,
        hideContinueBtn: true
      },
      {
        id: "done",
        label: "",
        component: <Done isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step6" />,
        hideContinueBtn: true,
        noStepper: true
      }
    ];
  if (["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(productId))
    steps = [
      {
        id: "creatPolicy",
        label: "Създай полица",
        component: <CreatePolicy isFromWizard refreshData={refreshTable} />,
        hideContinueBtn: true
      },
      {
        id: "attachPolicy",
        label: "Прикачи полица",
        component: <AttachmentsLFE isFromWizard wizardStatus="step2" refreshData={refreshTable} />,
        hideContinueBtn: true
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: (
          <SendDocumentsLFE
            policyDBId={policyDBId}
            installment={paymentId}
            productId={productId}
            defaultEmail={email}
            defaultName={name}
            refreshData={refreshTable}
            wizardStatus="step3"
            variant={variant}
            filtering={["policy"]}
            ordering={["policy"]}
          />
        ),
        hideContinueBtn: true
      },
      {
        id: "moveToIssued",
        label: "",
        component: <MoveToIssuedLFE policy={objWithEgnAndRequestId} isFromWizard refreshData={refreshTable} wizardStatus="step3" variant={variant} />,
        noStepper: true,
        hideContinueBtn: true
      }
    ];
  return steps;
};
