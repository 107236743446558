import React, { useEffect } from "react";
import withValidation from "../../../../HOC/withValidation";
import { individualFields } from "../../../../../config/WizardOFF/Individaul.page.config";
import { Box, Button, TextField } from "@mui/material";
import _get from "lodash.get";
import { connect, useDispatch } from "react-redux";
import { setOfflineFormData } from "../../../../../store/reducers/form";

const Individual = props => {
  const { nextStep } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    //
  }, []);

  const handleSubmit = () => {
    if (props?.handleValidation()) {
      dispatch(setOfflineFormData(_get(props, "formData")));
      nextStep();
    }
  };
  return (
    <div>
      {individualFields.map(f => (
        <TextField
          sx={{ mb: 1, mt: 1 }}
          key={f.name}
          placeholder={f.placeholder}
          fullWidth
          label={f.label}
          variant="outlined"
          size="small"
          name={f.name}
          value={props?.formData[f.name]}
          onChange={props?.handleChange}
          helperText={props?.errors[f.name]}
          error={!!props?.errors[f.name]}
        />
      ))}
      <Box mb={2} mt={2} display="flex" justifyContent="center">
        <Button className="size-3" type="submit" variant="contained" color="primary" size="large" sx={{ width: "fit-content", mb: 1 }} onClick={handleSubmit}>
          Продължи
        </Button>
      </Box>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(Individual, { txtFields: individualFields }));
