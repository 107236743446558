import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import _get from "lodash.get";

import { moveToIssued, setConfirmationStatusPayment, setWizardStatusesByGroupRequestId } from "../../../../services/PoliciesService";
import { success as successAlert } from "../../../../utils/notifications";
import { timeout } from "../../../../utils/helpers";

const MoveToIssued = props => {
  const { variant, policy, currentProduct } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const moveToIssuedCall = async () => {
      if (variant === "installments") {
        if (["GO", "CSC", "PRP"].includes(policy?.productId)) {
          const requestPayload = {
            duePaymentId: _get(policy, "duePaymentId"),
            status: "Issued"
          };
          await setConfirmationStatusPayment(requestPayload);
          successAlert("issued successfully!");

          await timeout(2000);
          props.close();
          props.refreshData();
        }
      } else {
        if (["GO", "CSC"].includes(policy?.productId) && currentProduct === "CSC+GO") {
          if (policy?.goPolicy) {
            await moveToIssued({ policyId: policy?._id, secondaryPolicyId: policy?.goPolicy?._id });
          } else if (policy?.cscPolicy) {
            await moveToIssued({ policyId: policy?._id, secondaryPolicyId: policy?.cscPolicy?._id });
          }
          await setWizardStatusesByGroupRequestId({
            groupRequestId: policy?.groupRequestId,
            wizardStatus: props?.wizardStatus,
            wizardStatus2: props?.wizardStatus2
          });
          successAlert("moved successfully!");

          await timeout(2000);
          props.close();
          props.refreshData();
        } else {
          await moveToIssued({ policyId: policy?._id });
          successAlert("moved successfully!");

          await timeout(2000);
          props?.canCurrentStepContinue();
        }
      }
      setLoading(false);
    };

    moveToIssuedCall();
  }, []);

  return (
    <>
      <h2 className="move-to-issued-title">Полицата е издадена</h2>

      <Grid container spacing={0} display="flex" justifyContent="center">
        <Typography>{loading ? "loading..." : "Преместено в издадени"}</Typography>
      </Grid>
    </>
  );
};

export default MoveToIssued;
