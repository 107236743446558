import React from "react";
import _sortBy from "lodash.sortby";

const withMultipleModals = (PassedComponent, data) =>
  class WithModals extends React.Component {
    constructor(props) {
      super(props);
      const dataIds = data && data.length ? data.map(el => el.id) : [];
      const arrLength = dataIds.length;
      const dataStatuses = [];
      for (let i = 0; i < arrLength; i++) {
        dataStatuses[i] = { id: dataIds[i], opened: false };
      }

      this.state = {
        dataStatuses,
        data,
        refreshData: null
      };
    }

    open = id => {
      const dataStatuses = this.state.dataStatuses;
      const indexInDataStatuses = this.state.dataStatuses.findIndex(el => el.id === id);
      dataStatuses[indexInDataStatuses].opened = true;
      this.setState({
        dataStatuses
      });
    };

    setRefreshMethod = refreshDataMethod => {
      this.setState({
        refreshData: refreshDataMethod
      });
    };

    closeAllExceptOne = id => {
      const dataStatuses = this.state.dataStatuses;
      const indexInDataStatuses = this.state.dataStatuses.findIndex(el => el.id === id);
      dataStatuses.forEach(ds => {
        ds.opened = false;
      });
      dataStatuses[indexInDataStatuses].opened = true;
      this.setState({
        dataStatuses
      });
    };
    close = id => {
      const dataStatuses = this.state.dataStatuses;
      const indexInDataStatuses = this.state.dataStatuses.findIndex(el => el.id === id);
      dataStatuses[indexInDataStatuses].opened = false;
      this.setState({
        dataStatuses
      });
    };

    toggle = id => {
      const dataStatuses = this.state.dataStatuses;
      // const stateDataStatusObj = _find(this.state.dataStatuses, { id });
      const indexInDataStatuses = this.state.dataStatuses.findIndex(el => el.id === id);
      dataStatuses[indexInDataStatuses].opened = !dataStatuses[indexInDataStatuses].opened;
      this.setState({
        dataStatuses
      });
    };

    render() {
      const { dataStatuses, data, refreshData } = this.state;
      const { sort } = this.props;
      const dataSorted = sort ? _sortBy(data, sort) : data;

      return (
        <PassedComponent
          {...this.props}
          open={this.open}
          close={this.close}
          toggle={this.toggle}
          setRefreshMethod={this.setRefreshMethod}
          closeAllExceptOne={this.closeAllExceptOne}
          dataStatuses={dataStatuses}
          data={dataSorted}
          refreshData={refreshData}
        />
      );
    }
  };

export default withMultipleModals;
