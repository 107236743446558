// Based on ReportsForPeriod - src\components\pages\ReportsForPeriod\ReportsForPeriod.js
import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Grid, Icon, TextField, FormGroup, FormControlLabel, Checkbox, LinearProgress } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { sendDocuments, getDocuments } from "../../../services/PoliciesService";
import { primary, gray, white } from "../../../utils/colors";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import _get from "lodash.get";
import { connect } from "react-redux";
import withParams from "../../../components/HOC/withParams";
import "./send.documents.sass";
import { displayFileName } from "../../SendDocuments/SendDocuments";

class SendDocuments extends React.Component {
  constructor(props) {
    super(props);
    /// console.log("props");
    /// console.log(props);
    this.state = {
      email: _get(props, "params.defaultEmail", _get(this.props, "defaultEmail")),
      policyDBId: _get(props, "params.policyDBId", _get(props, "policyDBId")),
      files: [],
      filesChecked: [],
      installment: _get(props, "params.installment", _get(props, "installment")),
      productId: _get(props, "params.productId", _get(props, "productId")),
      isLoading: false
    };

    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { policyDBId, productId, installment } = this.state;
    const { variant } = this.props;
    this.setState({ isLoading: true });
    const response = await getDocuments({ policyDBId });
    const files = _get(response, "data", []);
    let selectedFiles = [];
    let filesChecked = [];
    // Cases: Defining the files that will be shown in a sorted way
    // addFiles(files, selectedFiles, "policy");
    // addFiles(files, selectedFiles, "receipt");
    // addFiles(files, selectedFiles, "vdog");
    // addFiles(files, selectedFiles, "otherFile");
    if (["GO", "CSC", "LFE"].includes(productId)) {
      addFiles(files, selectedFiles, "policy");
      addFiles(files, selectedFiles, "otherFile");
      addFiles(files, selectedFiles, "installment");
      if (this.props?.isFromWizard) {
        const policyFile = files.find(x => x.type === "policy");
        const currentInstallmentFile = files.find(x => x.type === "installment" && x.name?.includes(`_${installment}`));
        if (policyFile && variant === "queries") filesChecked = [policyFile];
        if (currentInstallmentFile && variant === "installments") filesChecked = [currentInstallmentFile];
      }
    } else {
      if (installment === "1") {
        addFiles(files, selectedFiles, "policy");
        addFiles(files, selectedFiles, "receipt");
        addFiles(files, selectedFiles, "vdog");
      }
      addFiles(files, selectedFiles, "otherFile");
    }

    this.setState({
      files: selectedFiles,
      isLoading: false,
      filesChecked
    });
  }

  handleTxtChange(e) {
    this.setState({
      [e?.target?.name]: e?.target?.value
    });
  }

  handleChange(e) {
    let arr = [];
    const fileExists = this.state.filesChecked.find(x => x.name === e.target.name);
    if (fileExists && !e.target.checked) {
      arr = this.state.filesChecked.filter(x => x.name !== e.target.name);

      //remove file from filesChecked
    } else if (e.target.checked) {
      const fileToAdd = this.state.files.filter(x => x.name === e.target.name);
      if (fileToAdd) {
        arr = [...fileToAdd, ...this.state.filesChecked];
      }
    }
    this.setState({ filesChecked: arr });
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const { policyDBId, email, filesChecked, installment } = this.state;
      const response = await sendDocuments({ policyDBId, email, installment, files: filesChecked });
      if (response.status === 200) {
        this.setState({
          email: ""
        });
        if (this.props?.isFromWizard) {
          this.props?.canCurrentStepContinue();
        }
        successAlert("sent successfully!");
      }
    } catch (e) {
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  }

  render() {
    const { email, files, isLoading, filesChecked } = this.state;
    const btnDisabled = !email;
    const content = (
      <Grid container spacing={0} display="flex" justifyContent="center">
        <Grid item xs={12} lg={this.props?.isFromWizard ? 8 : 6}>
          <Box mb={1} display="flex" justifyContent="center">
            <p className="size-5">Клиент: {_get(this.props, "params.defaultName", _get(this.props, "defaultName"))}</p>
          </Box>
          <form onSubmit={this.handleSubmit}>
            <Box mb={1}>
              <TextField name="email" fullWidth label="Имейл клиент" id="outlined-size-small" variant="outlined" size="small" value={email} onChange={this.handleTxtChange} />
            </Box>
            <Box mt={1} mb={1} display="flex" justifyContent="center">
              <p className="size-5">Моля изберете документи за изпращане</p>
            </Box>
            {isLoading && <LinearProgress />}
            {files?.map((file, index) => (
              <Grid container key={`grid-item-${index}`} justifyContent="space-between" className="send-documents-gray-box">
                <Grid item sm={12} sx={{ overflow: "hidden" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name={file?.name} onChange={e => this.handleChange(e)} checked={!!filesChecked.find(x => x?.name === file?.name)} />}
                      label={displayFileName(file)}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            ))}
            <Box mb={0} mt={1} display="flex" justifyContent="center">
              <Button
                className="size-3"
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "fit-content", mb: 1, backgroundColor: !btnDisabled ? primary : gray, color: white }}
                size="large"
                disabled={btnDisabled}
              >
                Изпрати
              </Button>

              <div style={{ clear: "both" }} />
            </Box>
          </form>
        </Grid>
      </Grid>
    );
    if (_get(this.props, "params.policyDBId"))
      return (
        <PageLayout title="Изпрати документи за застраховка по имейл" {...this.props}>
          {content}
        </PageLayout>
      );
    return content;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

// Adding files from the given fileType to the selectedFiles
const addFiles = (files, selectedFiles, fileType) => {
  for (const file of files) {
    if (file.hidden) continue;
    if (file.type != fileType) continue;

    selectedFiles.push(file);
  }
};

export default connect(mapStateToProps)(withParams(SendDocuments));
