import _get from "lodash.get";
import AddAnnex from "../../Wizard/Annexes/AddAnnex";
import CarData from "../../Wizard/Annexes/CarData";
import Payment from "../../Wizard/Annexes/Payment";
import React from "react";
import Shipment from "../../Wizard/Annexes/Shipment";
import SendDocuments from "../../Wizard/Annexes/SendDocuments";
import AttachFiles from "../../Wizard/Annexes/AttachFiles";
import Done from "../../Wizard/Annexes/Done";

export const getStepsForAddAnnexWizardModal = props => {
  const { objWithEgnAndRequestId, refreshTable, variant, subVariant, currentProduct } = props;
  const { policyDBId, paymentId, productId, email, name } = objWithEgnAndRequestId || {};
  let steps;
  if (["GO", "CSC"].includes(productId)) {
    steps = [
      {
        id: "addAnnex",
        label: "Добавяне на анекс",
        component: <AddAnnex isFromWizard policy={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} wizardStatus="step1" />,
        hideContinueBtn: true
      },
      {
        id: "carData",
        label: "Данни на МПС",
        component: <CarData isFromWizard policy={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} wizardStatus="step2" />,
        hideContinueBtn: true
      },
      {
        id: "payment",
        label: "Доплащане",
        component: <Payment isFromWizard policy={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} wizardStatus="step3" />,
        hideContinueBtn: true
      },
      {
        id: "attachFiles",
        label: "Прикачи документи",
        component: <AttachFiles isFromWizard policy={objWithEgnAndRequestId} refreshData={refreshTable} variant={variant} wizardStatus="step4" />,
        hideContinueBtn: true
      },

      {
        id: "shipment",
        label: "Подготви пратка",
        component: <Shipment policyDBId={policyDBId} isFromWizard refreshData={refreshTable} variant={variant} wizardStatus="step5" />
      },
      {
        id: "sendDocuments",
        label: "Изпрати документи",
        component: (
          <SendDocuments
            policyDBId={policyDBId}
            installment={paymentId}
            productId={productId}
            defaultEmail={email}
            defaultName={name}
            refreshData={refreshTable}
            filtering={["annex"]}
            ordering={["annex"]}
            wizardStatus="step6"
          />
        ),
        hideContinueBtn: true
      },
      {
        id: "done",
        label: "",
        component: <Done isFromWizard objWithEgnAndRequestId={objWithEgnAndRequestId} refreshData={refreshTable} wizardStatus="step7" />,
        hideContinueBtn: true,
        noStepper: true
      }
    ];
  }

  return steps;
};
