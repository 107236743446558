import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Collapse, Grid, FormHelperText, LinearProgress } from "@mui/material";
import { FieldInput, FieldCheckbox, FieldAutocomplete, FieldCalendar } from "./Field";
import { GetApp as GetAppIcon } from "@mui/icons-material";
import { addShipment, downloadPdfByPolicyId, checkShipment } from "../../../services/Shipment/Shipment";
import { formatBGPhone } from "../../../utils/functions";

export const FormContext = React.createContext({
  form: {}
});

function Form(props) {
  const {
    initialValues,
    autocompleteServices,
    choosenCity,
    choosenOffice,
    choosenComplex,
    choosenStreet,
    isLoading,
    officeIsLoading,
    complexIsLoading,
    streetIsLoading,
    deliveryData,
    deliveryHolidays
  } = props;
  const initialFormValues = {
    pickupOffice: Boolean(deliveryData?.officeDelivery),
    site: deliveryData?.cityDelivery,
    office: deliveryData?.officeDelivery,
    officeData: deliveryData?.officeDeliveryData,
    complex: deliveryData?.ulDelivery,
    street: deliveryData?.ulDelivery,
    streetNo: deliveryData?.noDelivery,
    blockNo: deliveryData?.blDelivery,
    entranceNo: deliveryData?.vhDelivery,
    floorNo: deliveryData?.etDelivery,
    apartmentNo: deliveryData?.apDelivery,
    poi: "",
    note: deliveryData?.messageDelivery,
    deliveryDate: null,
    deliveryHolidays
  };
  const { id } = useParams();
  const [form, setForm] = useState({ ...initialValues, ...initialFormValues });
  const [dirty, setDirty] = useState(true);
  const [formSaved, setFormSaved] = useState(false);
  const [message, setMessage] = useState();
  const [shipmentNumber, setShipmentNumber] = useState(null);
  const [canAddShipment, setCanAddShipment] = useState(true);
  const deliveryPhoneField = form["deliveryPhone"];
  const deliveryNameField = form["deliveryName"];
  const hasDeliveryPhoneAndName = Boolean(deliveryPhoneField && deliveryNameField);

  const handleFormChange = e => {
    const { name, type, value, checked, data } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
      officeData: data || form?.officeData
    });
    setDirty(true);
  };

  const handleSaveShipment = () => {
    let f = form;
    if (typeof f.street !== "number") {
      f = { ...f, street: "" };
    }
    if (typeof f.complex !== "number") {
      f = { ...f, complex: "" };
    }
    addShipment(f)
      .then(async data => {
        // After success request get the message number, appear the chip with the tracking number, and disable the send shipment button.
        if (data?.code === 200) {
          const trackingNumber = data?.text?.replace("Tracking code: ", "");
          setShipmentNumber(trackingNumber);
          setCanAddShipment(false);
          refreshOpener();
        }
        setMessage(data);
        setDirty(false);
        setFormSaved(true);
      })
      .catch(error => {
        if (error.response?.data.text) {
          setMessage({ code: 500, text: error.response?.data.text });
        } else if (error.message) {
          setMessage({ code: 500, text: error.message });
        }
      });
  };
  const refreshOpener = () => {
    if (window.opener && !window.opener.closed) {
      window.opener.location.reload();
    }
  };
  const handlePrintShipment = async () => {
    // console.log(form);
    await downloadPdfByPolicyId(form);
    await props?.canCurrentStepContinue();
  };

  useEffect(() => {
    if (id) {
      checkShipment(id, form.duePaymentId).then(data => {
        if (data?.data?.response?.id) {
          setShipmentNumber(data?.data?.response?.id);
          setCanAddShipment(false);

          // Set the state of dirty to false and formSaved to true, so you can print shipment if shipment number is already genereated.
          setDirty(false);
          setFormSaved(true);
        }
      });
    }
  }, []);

  // console.log(form);
  return (
    <>
      {isLoading ? (
        <LinearProgress sx={{ mt: 2, mr: 2 }} />
      ) : (
        <>
          <FormContext.Provider
            value={{
              form,
              handleFormChange,
              autocompleteServices
            }}
          >
            <FormFields
              form={form}
              setForm={setForm}
              choosenCity={choosenCity}
              choosenOffice={choosenOffice}
              choosenComplex={choosenComplex}
              choosenStreet={choosenStreet}
              officeIsLoading={officeIsLoading}
              complexIsLoading={complexIsLoading}
              streetIsLoading={streetIsLoading}
              deliveryNameField={deliveryNameField}
              deliveryPhoneField={deliveryPhoneField}
            />
          </FormContext.Provider>
          {shipmentNumber && (
            <Box sx={{ mb: 3, mt: 3 }} className="shipment-gray-box">
              <p className="size-4" style={{ margin: 0 }}>
                <span className="bold">Tracking code</span>: <span className="size-4 has-text-primary">{shipmentNumber}</span>
              </p>
            </Box>
          )}
          <Box mt={2} display="flex" alignItems="center" flexDirection="column">
            <Button
              className="size-3"
              onClick={() => handleSaveShipment()}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={!canAddShipment || !hasDeliveryPhoneAndName}
              sx={{ width: "fit-content", mb: 1 }}
            >
              Запази
            </Button>
            <Button
              className="size-7"
              onClick={() => handlePrintShipment()}
              endIcon={<GetAppIcon />}
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              disabled={dirty || !formSaved}
              sx={{ width: "fit-content" }}
            >
              Товарителница
            </Button>
          </Box>
          {/* {message && message.code <= 200 && <Box>{message.text}</Box>} */}
          {message && message.code > 200 && <Box style={{ color: `red` }}>{message.text}</Box>}
          {}
        </>
      )}
    </>
  );
}

const FormFields = ({
  form,
  setForm,
  choosenCity,
  choosenOffice,
  choosenComplex,
  choosenStreet,
  officeIsLoading,
  complexIsLoading,
  streetIsLoading,
  deliveryPhoneField,
  deliveryNameField
}) => {
  const { site, pickupOffice, courier } = form;
  const [fieldsDisabled, setFieldsDisabled] = useState(true);

  useEffect(() => {
    if (site) {
      setFieldsDisabled(false);
    } else {
      setForm({ ...form, office: "", complex: "", street: "" });
      setFieldsDisabled(true);
    }
  }, [site]);

  // TODO: The payload for fetching office was incorect, because siteId was a string, not the id of the city. This will fix autocomplete, but i have to check if it is correct.

  useEffect(() => {
    if (choosenCity) {
      setForm({ ...form, site: choosenCity?.id, office: "", complex: "", street: "", deliveryPhone: `+359${formatBGPhone(deliveryPhoneField)}` });
      setFieldsDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (choosenStreet) {
      setForm({ ...form, street: choosenStreet.id });
      setFieldsDisabled(false);
    }
  }, [choosenStreet]);

  useEffect(() => {
    if (choosenComplex) {
      setForm({ ...form, complex: choosenComplex.id });
      setFieldsDisabled(false);
    }
  }, [choosenComplex]);

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <FieldAutocomplete name="site" label="Населено място" autoFocus initialValue={choosenCity} />
        </Grid>
      </Grid>
      <FieldCheckbox name="pickupOffice" value={pickupOffice} label="До поискване в офис" />
      <fieldset disabled={fieldsDisabled} style={{ border: 0, margin: 0, padding: 0 }}>
        <Collapse in={pickupOffice}>
          <Grid container>
            <Grid item sm={12}>
              <Box mt={1}>{!officeIsLoading && <FieldAutocomplete name="office" minSearchChars={0} label="Офис" initialValue={choosenOffice} />}</Box>
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={!pickupOffice}>
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                {!complexIsLoading && <FieldAutocomplete name="complex" label="жк" initialValue={choosenComplex} />}
              </Grid>
              <Grid item sm={4}>
                {!streetIsLoading && <FieldAutocomplete name="street" label="ул./бул." initialValue={choosenStreet} />}
              </Grid>
              <Grid item sm={4}>
                <FieldInput name="streetNo" label="No" />
              </Grid>
            </Grid>
          </Box>
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item sm={3}>
                <FieldInput name="blockNo" label="бл." />
              </Grid>
              <Grid item sm={3}>
                <FieldInput name="entranceNo" label="вх." />
              </Grid>
              <Grid item sm={3}>
                <FieldInput name="floorNo" label="ет." />
              </Grid>
              <Grid item md={3}>
                <FieldInput name="apartmentNo" label="ап." />
              </Grid>
            </Grid>
          </Box>
          <Box mt={1}>
            <Grid container>
              <Grid item sm={12}>
                <FieldInput name="poi" label="Хар.обект." fullWidth />
              </Grid>
            </Grid>
          </Box>
          <Box mt={1}>
            <Grid container>
              <Grid item sm={12}>
                <FieldInput name="note" label="Уточнение" fullWidth />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FieldInput name="deliveryName" label="Име за доставка" fullWidth error={!deliveryNameField} />
              {!deliveryNameField && <FormHelperText error>Име за доставка е задължително поле</FormHelperText>}
            </Grid>
            <Grid item sm={4}>
              <FieldInput name="deliveryPhone" label="Телефон за доставка" fullWidth error={!deliveryPhoneField} />
              {!deliveryPhoneField && <FormHelperText error>Телефон е задължително поле</FormHelperText>}
            </Grid>
            <Grid item sm={8}>
              <Collapse in={!pickupOffice}>
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <FieldCheckbox name="deliveryHolidays" label="Доставка събота/празници" />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item sm={4}>
              <FieldCalendar name="pickupDate" label="Дата на изпращане" fullWidth />
            </Grid>
          </Grid>
        </Box>
      </fieldset>
    </>
  );
};

export default Form;
