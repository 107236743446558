import _get from "lodash.get";
import { isAutomaticStickersActive } from "../mainConfig/helpers";

export const txtFields = [
  {
    name: "policyNumber",
    label: "Въведи номер на полицата",
    placeholder: "BG/02/...",
    validators: ["required", "validateGONumber"],
    errorMessages: ["Полето е задължително", "Невалиден номер на полицата"],
    defaultValueFunction: props => {
      return _get(props, "objWithEgnAndRequestId.policyId", "");
    },
    hidden: props => {
      return props?.variant === "installments";
    }
  },
  {
    name: "sticker",
    label: "Въведи стикер",
    placeholder: "Ex: 00012678",
    validators: ["required", "stickerIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Стикер - трябва да е 8 или 9 цифри."],
    defaultValueFunction: props => {
      return _get(props, "sticker.seq", "");
    },
    hidden: props => {
      return !isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId);
    }
  },
  {
    name: "greenCard",
    label: "Въведи номер на Зелена карта",
    placeholder: "Ex. 001278",
    validators: ["required", "greenCardIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден номер на Зелена карта - трябва да е 6 цифри."],
    defaultValueFunction: props => {
      return _get(props, "greenCard.seq", "");
    },
    hidden: props => {
      return !isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId);
    }
  },
  {
    name: "prefix",
    label: "Въведи префикс на Зелена карта",
    placeholder: "Ex. BG/XY...",
    validators: ["required", "prefixIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Префикс на Зелена карта- трябва да има 2 ГЛАВНИ букви."],
    defaultValueFunction: props => {
      return _get(props, "greenCard.prefix", "");
    },
    hidden: props => {
      return !isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId);
    }
  }
];
