import React, { useState } from "react";
import { sendDocumentsFields } from "../../../config/SendDocumentsToCSCPlusGO.page.config";
import SendDocuments from "../../SendDocuments";
import { sendDocuments, setWizardStatusesByGroupRequestId } from "../../../services/PoliciesService";
import withParams from "../../../components/HOC/withParams";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";

const SendDocumentsToCSCPlusGO = props => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async data => {
    const { policyDBId, email, files, policyDBIdCSC, policyDBIdGO } = data;
    try {
      setLoading(true);
      files.forEach(f => {
        if (f.policyDBId === policyDBIdGO && f.displayName && !f.displayName?.includes(" - Гражданска")) {
          f.displayName = `${f.displayName} - Гражданска`;
        }
        if (f.policyDBId === policyDBIdCSC && f.displayName && !f.displayName?.includes(" - Каско")) {
          f.displayName = `${f.displayName} - Каско`;
        }
      });
      const response = await sendDocuments({ policyDBId, email, files, policyDBIdCSC, policyDBIdGO });
      // const response = { status: 200 };
      if (response.status === 200) {
        if (props?.isFromWizard) {
          const { wizardStatus, groupRequestId, wizardStatus2, nextStep, refreshData } = props;
          await setWizardStatusesByGroupRequestId({
            groupRequestId,
            wizardStatus,
            wizardStatus2
          });
          setLoading(false);
          successAlert("sent successfully!");
          refreshData();
          nextStep();
        } else {
          setLoading(false);
          successAlert("sent successfully!");
        }
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data) errorAlert(e.response.data);
      else errorAlert(e.message);
    }
  };

  return (
    <SendDocuments
      withLayout
      {...props}
      allSelected
      withCheckboxes={true}
      filtering={["policy"]}
      ordering={["policy", "receipt", "vdog", "shipment", "otherFile"]}
      txtFields={sendDocumentsFields}
      handleSubmit={handleSubmit}
      title="Изпрати документи Каско + Гражданска"
      loading={loading}
    />
  );
};

export default withParams(SendDocumentsToCSCPlusGO);
