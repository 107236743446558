import React from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";

class DetailPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { purchase } = this.props || {};
    const { purchaseData } = purchase || {};
    return (
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={3}>
          <Box m={2}>дата: {moment(purchase.timestamp).format("DD-MM-YYYY")}</Box>
        </Grid>
        <Grid item xs={5}>
          <Box m={2}>
            Поръчка:
            {purchaseData.map((el, index) => {
              return (
                <p key={index}>
                  {index + 1}. {el.productType} {el.carNo} {`${el.price} лв.`}
                </p>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    );
  }
}

export default DetailPanel;
