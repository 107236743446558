import React from "react";
import { gray, primary, danger, warning } from "../../utils/colors";
import moment from "moment/moment";
import _get from "lodash.get";
import PersonAddAlt from "@mui/icons-material/PersonAddAlt";

const UserData = props => {
  const { policy } = props;
  const userId = _get(policy, "userId", "");
  const userCreationDate = _get(policy, "userCreationDate", "");
  const riskStatus = _get(policy, "riskStatus", "low");
  const diffDays = moment().diff(moment(userCreationDate), "days");
  const statusBg = (status => {
    if (status === "low") return "Нисък";
    if (status === "medium") return "Среден";
    if (status === "high") return "Висок";
    return "";
  })(riskStatus);

  const statusColor = (status => {
    if (status === "low") return primary;
    if (status === "medium") return warning;
    if (status === "high") return danger;
    return "";
  })(riskStatus);

  return (
    <div className="detail-panel-gray-color-section">
      <b>User Id:</b>
      <span className="font-small" style={{ color: gray, display: "block" }}>
        {userId}
      </span>

      <b>User data: </b>
      {userCreationDate && (
        <>
          <span>{moment(userCreationDate).locale("bg").format("DD MMM YYYY")}</span>
          <span className="vertical-align" style={{ color: primary }}>
            {diffDays <= 30 ? (
              <>
                <PersonAddAlt /> <span style={{ marginLeft: 5 }}>нов потребител</span>
              </>
            ) : (
              ""
            )}
          </span>
        </>
      )}
      <div className="vertical-align">
        <span className="mr-5">Рисков профил: </span>
        <span style={{ color: statusColor }}>{statusBg}</span>
      </div>
    </div>
  );
};

export default UserData;
