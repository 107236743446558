import _get from "lodash.get";

export const individualFields = [
  {
    name: "name",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.name");
    },
    label: "Три имена *",
    placeholder: "Анна Христова Иванова ",
    validators: ["required", "validateName"],
    errorMessages: ["Полето е задължително", "Невалидно име"]
  },
  {
    name: "egnEik",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.egnEik");
    },
    label: "ЕГН *",
    validators: ["required", "validateEGN"],
    errorMessages: ["Полето е задължително", "Невалидно ЕГН"]
  },
  {
    name: "email",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.email");
    },
    label: "Имейл",
    placeholder: "kristian@boleron.com",
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "phone",
    defaultValueFunction: props => {
      return _get(props, "form.offlineFormData.phone");
    },
    label: "Телефон",
    placeholder: "0884286747",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  }
];

export const legalEntityFields = {
  section1: {
    text: "",
    fields: [
      {
        name: "companyName",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.companyName");
        },
        label: "Име на фирма *",
        placeholder: "Флай2скай ЕООД - Анна Христова Иванова ",
        validators: ["required", "validateName"],
        errorMessages: ["Полето е задължително", "Невалидно име на фирма"]
      },
      {
        name: "egnEik",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.egnEik");
        },
        label: "EИК *",
        validators: ["required", "validateEIK"],
        errorMessages: ["Полето е задължително", "Невалидно ЕИК"]
      }
    ]
  },
  section2: {
    text: "Данни за контакт",
    fields: [
      {
        name: "name",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.name");
        },
        label: "Три имена *",
        placeholder: "Анна Христова Иванова ",
        validators: ["required", "validateName"],
        errorMessages: ["Полето е задължително", "Невалидно име"]
      },
      {
        name: "email",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.email");
        },
        label: "Имейл",
        placeholder: "kristian@boleron.com",
        validators: ["required", "validateEmail"],
        errorMessages: ["Полето е задължително", "Невалиден email адрес"],
        value: "office@boleron.com"
      },
      {
        name: "phone",
        defaultValueFunction: props => {
          return _get(props, "form.offlineFormData.phone");
        },
        label: "Телефон",
        placeholder: "0884286747",
        validators: ["required"],
        errorMessages: ["Полето е задължително"]
      }
    ]
  }
};

export const extractFieldsFromSections = sectionsObj => {
  let fields = [];
  Object.keys(sectionsObj).forEach(key => {
    fields = [...fields, ..._get(sectionsObj, `[${key}].fields`)];
  });
  return fields;
};
