import React from "react";
import { Button, Grid } from "@mui/material";
import SendDocumentsToCSCPlusGO from "../../../pages/SendDocumentsToCSCPlusGO";

const SendDocumentsCSCGO = props => {
  const { policy, ...rest } = props;
  let goPolicy, cscPolicy;
  if (policy?.productId === "CSC") {
    goPolicy = policy?.goPolicy;
    cscPolicy = policy;
  }
  if (policy?.productId === "GO") {
    goPolicy = policy;
    cscPolicy = policy?.cscPolicy;
  }
  return (
    <Grid container spacing={0} display="flex" justifyContent="center">
      <SendDocumentsToCSCPlusGO
        policyDBId={cscPolicy?._id}
        policyDBId2={goPolicy?._id}
        withLayout={false}
        isFromWizard
        defaultName={policy?.name}
        defaultEmail={policy?.email}
        groupRequestId={policy?.groupRequestId}
        {...rest}
      />
    </Grid>
  );
};

export default SendDocumentsCSCGO;
