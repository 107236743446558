import React from "react";
import _get from "lodash.get";
import { getYearsByEgn } from "../../utils/functions";

const TitularAndInsuredPersons = props => {
  const { policy } = props;
  const productId = _get(policy, "productId");
  const persons = _get(props, "policy.policyData.persons", []);
  const adults = _get(props, "policy.policyData.adults");
  const children = _get(props, "policy.policyData.children");
  const firstName = _get(props, "policy.policyData.firstName");
  const middleName = _get(props, "policy.policyData.middleName");
  const lastName = _get(props, "policy.policyData.lastName");
  const egn = _get(props, "policy.policyData.egn");
  const titularEgn = _get(props, "policy.policyData.titularegn");
  const lkNum = _get(props, "policy.policyData.lkNum");
  const lkDate = _get(props, "policy.policyData.lkDate");
  const titularFirstName = _get(props, "policy.policyData.titularfirstName");
  const titularMiddleName = _get(props, "policy.policyData.titularmiddleName");
  const titularLastName = _get(props, "policy.policyData.titularlastName");
  const ownerData = _get(props, "policy.policyData.savedCar.ownerData");

  const insuredPersons = [];
  if (persons) {
    persons.forEach(p => {
      if (p.isInsured === undefined || p.isInsured) {
        const { firstName, middleName, lastName, egn } = p;
        insuredPersons.push({
          firstName,
          middleName,
          lastName,
          egn
        });
      }
    });
  }
  let insurerPerson = _get(persons, "[0].isTitular")
    ? { firstName: _get(persons, "[0].firstName"), middleName: _get(persons, "[0].middleName"), lastName: _get(persons, "[0].lastName"), egn: _get(persons, "[0].egn") }
    : null;
  let oneLineOnly;
  let owner;
  if (["GO", "CSC"].includes(productId)) {
    oneLineOnly = insuredPersons.length === 1 && _get(ownerData, "egn") === _get(insurerPerson, "egn");
    if (ownerData) {
      const { egn, firstName, middleName, lastName, eik, companyName } = ownerData || {};
      owner = {
        eik,
        companyName,
        egn,
        firstName,
        middleName,
        lastName
      };
    } else {
      owner = _get(insuredPersons, "[0]", {});
    }
    if (oneLineOnly && owner) {
      insurerPerson = { ...insurerPerson, ...owner };
    }
  } else {
    oneLineOnly = insuredPersons.length === 1 && _get(insuredPersons, "[0].egn") === _get(insurerPerson, "egn");
  }
  const compareFn = (a, b) => {
    if (getYearsByEgn(a.egn) > getYearsByEgn(b.egn)) {
      return -1;
    } else if (getYearsByEgn(a.egn) < getYearsByEgn(b.egn)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };
  return (
    <>
      {["KID", "MED"].includes(productId) && (
        <div>
          <b>Титуляр:</b>
          <p style={{ fontSize: "11px" }}>
            {titularFirstName
              ? `${titularFirstName} ${titularMiddleName} ${titularLastName} ${titularEgn} лк. № ${lkNum} дата на издаване ${lkDate}`
              : `${firstName} ${middleName} ${lastName} ${egn} лк. № ${lkNum} дата на издаване ${lkDate}`}
          </p>
        </div>
      )}
      {insurerPerson && !["KID", "MED", "OFF"].includes(productId) && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>{oneLineOnly ? `${["GO", "CSC"].includes(productId) ? "Титуляр и Собственик:" : "Титуляр и Застрахован:"}` : "Титуляр:"}</b>
            <span>
              {insurerPerson.firstName} {insurerPerson.middleName} {insurerPerson.lastName}
            </span>
          </div>
          <div>
            <b>ЕГН:</b>
            <span>{insurerPerson.egn}</span>
          </div>
          <div>
            <b>Тел:</b>
            <span>{policy?.phone}</span>
          </div>
          <div>
            <b>E-mail:</b>
            <span>{policy?.email}</span>
          </div>
        </div>
      )}

      {policy?.policyData?.eik && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>Застрахован: </b>
            <span>{policy?.policyData?.companyName}</span>
          </div>
          <div>
            <b>ЕИК: </b>
            <span>{policy?.policyData?.eik}</span>
          </div>
        </div>
      )}
      {["GO", "CSC"].includes(productId) && !oneLineOnly && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <b>Собственик: </b>
            {owner?.egn && (
              <span>
                {owner?.firstName} {owner?.middleName} {owner?.lastName}
              </span>
            )}
            {owner?.eik && <span>{owner?.companyName}</span>}
          </div>
          <div>
            {owner?.egn && (
              <>
                <b>ЕГН:</b>
                <span>{owner?.egn}</span>
              </>
            )}
            {owner?.eik && (
              <>
                <b>ЕИК:</b>
                <span>{owner?.eik}</span>
              </>
            )}
          </div>
        </div>
      )}
      {!!insuredPersons.length && !["GO", "CSC"].includes(productId) && !oneLineOnly && (
        <>
          <b>Застраховани: </b>
          {insuredPersons.sort(compareFn).map((person, index) => {
            return (
              <p key={index}>
                {person.firstName} {person.middleName} {person.lastName} {`ЕГН: ${person.egn}`}
                {index + 1 <= adults ? <b>- възрастен</b> : ""}
                {index + 1 - adults > 0 && index + 1 - adults <= children ? <b>- дете</b> : ""}
              </p>
            );
          })}
        </>
      )}
      {productId === "OFF" && (
        <div style={{ marginBottom: 20 }}>
          {_get(props, "policy.policyData.personType") === 1 && (
            <>
              <div>
                <b>Застраховащ: </b>
                <span>{policy?.name}</span>
              </div>
              <div style={{ marginBottom: 20 }}>
                <b>Лице за контакт: </b>
                <span>{_get(props, "policy.policyData.titularfirstName")}</span>
              </div>
              <div>
                <b>ЕИК: </b>
                <span>{_get(props, "policy.policyData.eik")}</span>
              </div>
            </>
          )}
          {_get(props, "policy.policyData.personType") === 2 && (
            <>
              <div>
                <b>Застраховащ: </b>
                <span>{_get(props, "policy.policyData.titularfirstName")}</span>
              </div>
              <div>
                <b>ЕГН: </b>
                <span>{_get(props, "policy.policyData.egn")}</span>
              </div>
            </>
          )}
          <div>
            <b>Тел: </b>
            <span>{policy?.phone}</span>
          </div>
          <div>
            <b>E-mail: </b>
            <span>{policy?.email}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default TitularAndInsuredPersons;
