import React from "react";
import { error, success as successAlert } from "../../utils/notifications";
import { requestError } from "../../utils/helpers";
import _get from "lodash.get";

const withSimpleModal = (PassedComponent, data) => {
  const { generatePayloadRequest, apiRequest, modalName, successMessage, closeAfterRequest, refreshAfterRequest } = data;
  return class WithModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        responseData: null,
        _id: _get(props, "objWithEgnAndRequestId._id")
      };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      if (_get(nextProps, "objWithEgnAndRequestId._id") !== prevState?._id) {
        return { responseData: null };
      }
      return prevState;
    }

    handle = async props => {
      props.handleLoading(modalName, true);
      try {
        let requestPayload = generatePayloadRequest(props);
        const response = await apiRequest(requestPayload);
        this.setState({
          responseData: _get(response, "data"),
          _id: _get(props, "objWithEgnAndRequestId._id")
        });
        successAlert(successMessage);
        if (refreshAfterRequest) {
          this.props.refreshTable();
        }
        props.handleLoading(modalName, false);
        if (closeAfterRequest) {
          this.props.close(modalName);
        }
      } catch (err) {
        props.handleLoading(modalName, false);
        let errorMessage = requestError(err);
        error(errorMessage);
      }
    };

    render() {
      return <PassedComponent {...this.props} {...this.state} modalName={modalName} handle={this.handle} />;
    }
  };
};
export default withSimpleModal;
