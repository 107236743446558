import { createSlice } from "@reduxjs/toolkit";

export const formDataSlice = createSlice({
  name: "form",
  initialState: {
    offlineFormData: {},
    lfeForData: {},
    offlineUploadedFiles: {},
    sendForSigningUploadedFiles: {}
  },
  reducers: {
    setOfflineFormData: (state, action) => {
      state.offlineFormData = {
        ...state.offlineFormData,
        ...action.payload
      };
    },
    setAndResetOfflineFormData: (state, action) => {
      state.offlineFormData = {
        ...action.payload
      };
    },
    setOfflineUploadedFiles: (state, action) => {
      state.offlineUploadedFiles = {
        ...state.offlineUploadedFiles,
        ...action.payload
      };
    },
    setSendForSigningUploadedFiles: (state, action) => {
      state.sendForSigningUploadedFiles = {
        ...state.sendForSigningUploadedFiles,
        ...action.payload
      };
    }
  }
});

export const { setOfflineFormData, setAndResetOfflineFormData, setOfflineUploadedFiles, setSendForSigningUploadedFiles } = formDataSlice.actions;
export const selectOfflineFormData = state => state.form.offlineFormData;
export const selectOfflineUploadedFiles = state => state.form.offlineUploadedFiles;
export const selectSendForSigningUploadedFiles = state => state.form.sendForSigningUploadedFiles;
export default formDataSlice.reducer;
