import React, { useState, useEffect } from "react";
import Form from "./Form";
import { getSiteSuggestions, getOfficeSuggestions, getComplexSuggestions, getStreetSuggestions } from "../../../services/Shipment/SpeedyService";

const FormSpeedy = ({ initialValues, selectedCity, selectedCityPostalCode, selectedAddress, officeDeliveryId, deliveryData, cityDeliveryId, canCurrentStepContinue }) => {
  const [choosenCity, setChoosenCity] = useState(null);
  const [choosenOffice, setChoosenOffice] = useState(null);
  const [choosenComplex, setChoosenComplex] = useState(null);
  const [choosenStreet, setChoosenStreet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [officeIsLoading, setOfficeIsLoading] = useState(false);
  const [complexIsLoading, setComplexIsLoading] = useState(false);
  const [streetIsLoading, setStreetIsLoading] = useState(false);
  const [siteId, setSiteId] = useState(0);

  const getSuggestionsSpeedy = async () => {
    try {
      setIsLoading(true);
      const response = await getSiteSuggestions(100, selectedCity, selectedCityPostalCode);
      if (response?.length === 1) {
        setChoosenCity(response[0]);
        setSiteId(response[0].id);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      console.log("e", e.message);
    }
  };

  const getOfficeSuggestionsSpeedy = async () => {
    try {
      setOfficeIsLoading(true);
      const response = await getOfficeSuggestions(100, cityDeliveryId, officeDeliveryId, selectedCity, selectedCityPostalCode);
      const results = response?.filter(office => !office.country);
      if (results?.length === 1) {
        // Refill the office autocomplete only if we have a refilled city
        if (choosenCity !== null) {
          setChoosenOffice(results[0]);
        }
        setOfficeIsLoading(false);
      } else {
        const result = response.filter(office => office.id === officeDeliveryId);
        if (result[0] !== undefined && result[0]) {
          setChoosenOffice(result[0]);
        }
        setOfficeIsLoading(false);
        return;
      }
      setOfficeIsLoading(false);
      // if (choosenCity !== null) {
      //   setChoosenOffice(response[0]);
      // }
      // setOfficeIsLoading(false);
    } catch (e) {
      console.log("e", e.message);
    }
  };

  const getComplexSuggestionsSpeedy = async () => {
    try {
      if (siteId && deliveryData?.ulDelivery) {
        setComplexIsLoading(true);
        const response = await getComplexSuggestions(siteId, deliveryData?.ulDelivery);
        if (response?.length > 0) {
          setChoosenComplex(response[0]);
        }
      }
      setComplexIsLoading(false);
    } catch (e) {
      setComplexIsLoading(false);
      console.log("e", e.message);
    }
  };

  const getStreetSuggestionsSpeedy = async () => {
    try {
      const bl = deliveryData.blDelivery;
      const no = deliveryData.noDelivery;
      // BOL-5755
      const doNotLoadStreet = bl && !no;

      if (siteId && deliveryData?.ulDelivery && !doNotLoadStreet) {
        setStreetIsLoading(true);
        const response = await getStreetSuggestions(siteId, deliveryData?.ulDelivery);
        if (response?.length > 0) {
          setChoosenStreet(response[0]);
        }
      }
      setStreetIsLoading(false);
    } catch (e) {
      setStreetIsLoading(false);
      console.log("e", e.message);
    }
  };

  useEffect(() => {
    if (selectedCity) {
      getSuggestionsSpeedy();
    }
  }, []);

  useEffect(() => {
    if (siteId > 0) {
      if (initialValues.pickupOffice) {
        getOfficeSuggestionsSpeedy();
      } else {
        getComplexSuggestionsSpeedy();
        getStreetSuggestionsSpeedy();
      }
    }
  }, [siteId]);

  return (
    <Form
      initialValues={{ ...initialValues, courier: "Speedy" }}
      autocompleteServices={{
        site: () => getSiteSuggestions.bind(null, 100),
        office: ({ site }) => getOfficeSuggestions.bind(null, 100, site),
        complex: ({ site }) => getComplexSuggestions.bind(null, site),
        street: ({ site }) => {
          return getStreetSuggestions.bind(null, site);
        }
      }}
      deliveryData={deliveryData}
      choosenCity={choosenCity}
      choosenOffice={choosenOffice}
      choosenComplex={choosenComplex}
      choosenStreet={choosenStreet}
      isLoading={isLoading}
      officeIsLoading={officeIsLoading}
      complexIsLoading={complexIsLoading}
      streetIsLoading={streetIsLoading}
      selectedCity={selectedCity}
      canCurrentStepContinue={canCurrentStepContinue}
    />
  );
};

export default FormSpeedy;
