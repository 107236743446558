import React from "react";
import { Grid, Icon } from "@mui/material";
import "./sticker.status.sass";

const getStatusColor = status => {
  switch (status) {
    case "Reserved":
      return "warning";
    case "Free":
      return "success";
    case "Cancelled":
      return "danger";
    case "Used":
      return "gray";
    default:
      return "gray";
  }
};
export const getBgTranslation = (status, type) => {
  switch (status) {
    case "Free":
      if (type === "goGreenCard") return "свободна";
      if (type === "goSticker") return "свободен";
      break;
    case "Cancelled":
      if (type === "goGreenCard") return "анулирана";
      if (type === "goSticker") return "анулиран";
      break;
    case "Used":
      if (type === "goGreenCard") return "използвана";
      if (type === "goSticker") return "използван";
      break;
    case "Reserved":
      if (type === "goGreenCard") return "резервирана";
      if (type === "goSticker") return "резервиран";
      break;
    default:
      return "";
  }
};

const StickerStatus = ({ status, type }) => {
  const statusText = getBgTranslation(status, type);
  return (
    <Grid container alignItems="center" justifyContent="center" direction="row">
      <Icon className={`sticker-status-icon ${getStatusColor(status)}`}>circle</Icon> <span className={`sticker-status-text ${getStatusColor(status)}`}>{statusText}</span>
    </Grid>
  );
};

export default StickerStatus;
