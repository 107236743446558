import React from "react";
import _get from "lodash.get";
import { setConfirmationStatusPayment } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { primary } from "../../utils/colors";

const statusConfig = {
  CSC: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        confirmed: ["Rejected", "PaidElsewhere"],
        issued: [],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        paidElsewhere: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        rejected: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  GO: {
    installments: {
      installments: {
        confirmed: ["Process", "Issued", "Rejected", "PaidElsewhere"],
        issued: ["Process"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        paidElsewhere: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued", "Process"]
      }
    }
  },
  PRP: {
    installments: {
      installments: {
        issued: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        confirmed: ["Rejected", "PaidElsewhere"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  HLT: {
    installments: {
      installments: {
        issued: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  CHLT: {
    installments: {
      installments: {
        issued: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  MED: {
    installments: {
      installments: {
        issued: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  OFF: {
    installments: {
      installments: {
        issued: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        process: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        informed: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        cancelled: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"],
        all: ["CustomerInformed", "Rejected", "PaidElsewhere", "Confirmed", "Issued"]
      }
    }
  },
  LFE: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "Issued"],
        informed: ["CustomerInformed", "Rejected", "Issued"],
        rejected: [],
        all: ["CustomerInformed", "Rejected", "Issued"]
      }
    }
  },
  HWP: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "Issued"],
        informed: ["CustomerInformed", "Rejected", "Issued"],
        rejected: [],
        all: ["CustomerInformed", "Rejected", "Issued"]
      }
    }
  },
  CI: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "Issued"],
        informed: ["CustomerInformed", "Rejected", "Issued"],
        rejected: [],
        all: ["CustomerInformed", "Rejected", "Issued"]
      }
    }
  },
  LFI: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "Issued"],
        informed: ["CustomerInformed", "Rejected", "Issued"],
        rejected: [],
        all: ["CustomerInformed", "Rejected", "Issued"]
      }
    }
  },
  HCL: {
    installments: {
      installments: {
        process: ["CustomerInformed", "Rejected", "Issued"],
        overdue: ["CustomerInformed", "Rejected", "Issued"],
        informed: ["CustomerInformed", "Rejected", "Issued"],
        rejected: [],
        all: ["CustomerInformed", "Rejected", "Issued"]
      }
    }
  }
};

const isStatusVisible = (actionId, productId, variant, subVariant) => {
  const type = "installments";
  const configArr = _get(statusConfig, `${productId}.${type}.${variant}${subVariant ? `.${subVariant}` : ""}`, []);
  return configArr.includes(actionId);
};

class ChangeStatusConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: "" };
  }
  handleTxtChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  };

  render() {
    const { modalName, isChangeStatusModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange, variant, subVariant } = this.props;
    const { status } = this.state;
    const modalTitle = "Промяна на статус на дадената вноска";
    const btnTxt = "Промени";
    const productId = _get(objWithEgnAndRequestId, "productId");
    const isVisible = actionId => isStatusVisible(actionId, productId, variant, subVariant);
    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        btnDisabled={!status}
        isModalOpen={isChangeStatusModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{
          ...objWithEgnAndRequestId,
          status: status
        }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Тип
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              labelId="demo-simple-select-placeholder-label-label"
              id="outlined-size-small"
              value={status}
              onChange={e => this.handleTxtChange(e, "status")}
              displayEmpty
            >
              {isVisible("CustomerInformed") && <MenuItem value={"CustomerInformed"}>Клиентът е информиран</MenuItem>}
              {isVisible("Process") && <MenuItem value={"Process"}>Предстояща</MenuItem>}
              {isVisible("Rejected") && <MenuItem value={"Rejected"}>Отказана</MenuItem>}
              {(_get(objWithEgnAndRequestId, "offer") === "online" || variant === "installments") && isVisible("PaidElsewhere") && (
                <MenuItem value={"PaidElsewhere"}>Платена на друго място</MenuItem>
              )}
              {isVisible("Confirmed") && <MenuItem value={"Confirmed"}>Потвърдена</MenuItem>}
              {isVisible("Issued") && <MenuItem value={"Issued"}>Издадена</MenuItem>}
            </Select>
          </FormControl>
        </Box>
      </ConfirmationModal>
    );
  }
}

const generatePayloadRequest = props => {
  const requestPayload = {
    duePaymentId: _get(props, "objWithEgnAndRequestId.duePaymentId"),
    status: _get(props, "objWithEgnAndRequestId.status")
  };

  return requestPayload;
};

export default withConfirmationModal(ChangeStatusConfirmationModal, {
  generatePayloadRequest,
  apiRequest: setConfirmationStatusPayment,
  modalName: "changeStatusModal",
  successMessage: "Успешна промяна!"
});
