import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Shipment from "../../../../components/pages/Shipment";

const ShipmentAnnexes = props => {
  const { policyDBId, isFromWizard, refreshData, variant, canCurrentStepContinue, cantCurrentStepContinue, passedData, wizardStatus } = props;
  const annex = passedData?.annex || props?.annex;

  useEffect(() => {
    props?.setWidth(900);
  }, []);

  return (
    <Grid container spacing={0} display="flex" justifyContent="center">
      <Grid item xs={11} lg={11}>
        <Shipment
          annex={annex}
          policyDBId={policyDBId}
          isFromWizard={isFromWizard}
          wizardStatus={wizardStatus}
          refreshData={refreshData}
          variant={variant}
          canCurrentStepContinue={canCurrentStepContinue}
          cantCurrentStepContinue={cantCurrentStepContinue}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentAnnexes;
