import React from "react";
import _get from "lodash.get";

const ForeignInstallment = props => {
  const productId = _get(props, "policy.productId");
  const goPolicyNumber = _get(props, "policy.policyData.goPolicyNumber");
  const goCoveredFrom = _get(props, "policy.policyData.goCoveredFrom");
  const goCoveredTo = _get(props, "policy.policyData.goCoveredTo");
  const goInstallmentNo = _get(props, "policy.policyData.goInstallmentNo");
  const carTalonNumberWhenInstallmentIsForeign = _get(props, "policy.policyData.goTalon");
  const totalPaidSum = _get(props, "policy.policyData.total");
  const paymentMode = _get(props, "policy.policyData.paymentMode");
  const insurerName = _get(props, "policy.policyData.insurerName");
  const installments = _get(props, "policy.policyData.installments");
  const isAForeignInstallment = Boolean(_get(props, "policy.policyData.goPolicyNumber"));

  return (
    productId === "GO" &&
    isAForeignInstallment && (
      <>
        <div>
          <b>Полица:</b>
          <span>{goPolicyNumber}</span>
        </div>
        <div>
          <b>Поредна вноска: </b>
          <span>{goInstallmentNo}</span>
        </div>
        <div>
          <b>Вноска от:</b>
          <span>{goCoveredFrom}</span>
        </div>
        <div>
          <b>Вноска до: </b>
          <span>{goCoveredTo}</span>
        </div>
        <div>
          <b>Начин на плащане:</b>
          <span>{paymentMode === 1 ? "Банкова карта" : "Наложен платеж"}</span>
        </div>
        <div>
          <b>Застраховател:</b>
          <span> {insurerName}</span>
        </div>
        <div>
          <b>Платена сума:</b> <span>{totalPaidSum}</span>
        </div>
        <div>
          <b>Вноски: </b>
          <span>{installments}</span>
        </div>
        <div>
          <b>Талон на автомобил:</b>
          <span>{carTalonNumberWhenInstallmentIsForeign}</span>
        </div>
      </>
    )
  );
};

export default ForeignInstallment;
