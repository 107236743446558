import React from "react";
import { Grid } from "@mui/material";
import ManualPolicyIssuance from "./ManualPolicyIssuance";

const PolicyIssuance = props => {
  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "75%" }}>
          <ManualPolicyIssuance {...props} wizardStatus="step1" />
        </div>
      </Grid>
    </Grid>
  );
};

export default PolicyIssuance;
