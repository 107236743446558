import _get from "lodash.get";
export const sendDocumentsFields = [
  {
    name: "email",
    label: "ИМЕЙЛ НА КЛИЕНТ",
    placeholder: "kristian@boleron.com",
    defaultValueFunction: props => {
      return _get(props, "defaultEmail");
    },
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"]
  },
  {
    name: "additionalEmails",
    label: "ИМЕЙЛИ (met@boleron.com, life@boleron.com ...)",
    placeholder: "brokers@boleron.com ...",
    validators: ["validateEmailsSeparatedByComma"],
    errorMessages: ["Невалиден списък с имейл адреси"],
    value: process.env.REACT_APP_ENV === "production" ? "brokers@boleron.com" : "",
    multiline: true,
    rows: 3
  }
];
