import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { setStickerStatus } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { primary } from "../../utils/colors";
import { getBgTranslation } from "../StickerStatus/StickerStatus";

const ChangeStatusStickerConfirmationModal = props => {
  const [status, setStatus] = useState("");

  const { modalName, isChangeStatusStickerModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = props;
  const modalTitle = "Промяна на статус на стикер/зелена карта";
  const btnTxt = "Промени";
  const type = _get(objWithEgnAndRequestId, "type");

  useEffect(() => {
    setStatus(objWithEgnAndRequestId?.status || "");
  }, [isChangeStatusStickerModalOpen]);

  return (
    <ConfirmationModal
      modalName={modalName}
      modalTitle={modalTitle}
      btnColor={primary}
      btnTxt={btnTxt}
      btnDisabled={!status}
      isModalOpen={isChangeStatusStickerModalOpen}
      toggle={toggle}
      objWithEgnAndRequestId={{
        ...objWithEgnAndRequestId,
        status: status
      }}
      egn={egn}
      handle={handle}
      showEgnCheck={showEgnCheck}
      egnValidationError={egnValidationError}
      onChange={onChange}
    >
      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Тип
          </InputLabel>
          <Select
            SelectDisplayProps={{
              style: { fontSize: 16 }
            }}
            labelId="demo-simple-select-placeholder-label-label"
            id="outlined-size-small"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <MenuItem value={"Free"}>{getBgTranslation("Free", type)}</MenuItem>
            <MenuItem value={"Used"}>{getBgTranslation("Used", type)}</MenuItem>
            <MenuItem value={"Reserved"}>{getBgTranslation("Reserved", type)}</MenuItem>
            <MenuItem value={"Cancelled"}>{getBgTranslation("Cancelled", type)}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </ConfirmationModal>
  );
};

const generatePayloadRequest = props => {
  return {
    _id: _get(props, "objWithEgnAndRequestId._id"),
    status: _get(props, "objWithEgnAndRequestId.status")
  };
};

export default withConfirmationModal(ChangeStatusStickerConfirmationModal, {
  generatePayloadRequest,
  apiRequest: setStickerStatus,
  modalName: "changeStatusStickerModal",
  successMessage: "Успешна промяна!"
});
