import React from "react";
import moment from "moment/moment";
import { Box } from "@mui/material";
import _get from "lodash.get";

const LastModified = props => {
  // todo Важно!!! lastModified,lastModifiedUser, lastModifiedAction, когато листинга е за вноски идват от DuePayments таблицата, а не от Policies
  const { policy } = props;
  const lastModified = _get(policy, "lastModified", "");
  const lastModifiedUser = _get(policy, "lastModifiedUser", "");
  const lastModifiedAction = _get(policy, "lastModifiedAction", "");

  return (
    lastModified &&
    lastModifiedUser && (
      <Box sx={{ mt: 1, mb: 1 }}>
        <b>Последна промяна: </b>{" "}
        <span>
          {lastModifiedUser} {moment(lastModified).format("DD-MM-YYYY HH:mm:ss")}
          {lastModifiedAction ? ` | ${lastModifiedAction}` : ""}
        </span>
      </Box>
    )
  );
};

export default LastModified;
