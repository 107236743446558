import React from "react";
import styles from "./Checkboxes.module.scss";
import { ReactComponent as Check } from "../../images/square_check_selected.svg";
import { ReactComponent as Circle } from "../../images/square_check.svg";

class Checkboxes extends React.Component {
  constructor(props) {
    super(props);
    const { options, selected } = props;
    this.state = {
      list: options,
      selected
    };
  }
  // static getDerivedStateFromProps(props) {
  //   // return { selected: props.selected };
  // }
  onChange = id => {
    const { selected } = this.state;
    let find = selected.findIndex(item => item.id === id);
    if (find > -1) {
      selected.splice(find, 1);
    } else {
      selected.push(this.state.list.find(item => item.id === id));
    }
    selected.sort((a, b) => a.id - b.id);
    this.setState({ selected });
    this.props.handleChange(
      this.props.name,
      selected,
      selected.map(a => a.id)
    );
  };

  render() {
    const { selected, list } = this.state;
    const { isHalf, label } = this.props;
    const selectedIds = selected.map(a => a.id);
    return (
      <>
        <p className="font-size-6 regular-txt mb-2">{label}</p>
        <div className={`${styles["form-control"]} ${isHalf ? styles["is-half"] : ""}`}>
          <div className={`${styles["form-group"]}`}>
            {list.map(item => {
              const isItemSelected = selectedIds.includes(item.id);
              return (
                <div key={item.id} className={`${styles["label-wrapper"]} ${isItemSelected ? styles["selected"] : ""}`}>
                  <label className={`${styles["label"]} ${isItemSelected ? styles["selected"] : ""}`}>
                    <input type="checkbox" className={styles["input"]} onChange={() => this.onChange(item.id)} selected={isItemSelected} />
                    <span className={styles["svg-wrapper"]}>{isItemSelected ? <Check width={23} height={23} /> : <Circle width={23} height={23} />}</span>
                    <span className={`${styles["label-text"]} is-flex p-1 is-flex-direction-column is-align-items-flex-start`}>{item.name}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default Checkboxes;
