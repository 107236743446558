import React from "react";

import { Box, Typography } from "@mui/material";
import { determineFuelType, determineVehicleType } from "../../utils/functions";
import _get from "lodash.get";

const KatData = props => {
  const productId = _get(props, "policy.productId");
  const ownerData = _get(props, "policy.policyData.savedCar.ownerData");
  const activeGO = _get(props, "policy.policyData.activeGo");
  const carMake = _get(props, "policy.policyData.savedCar.make");
  const carModel = _get(props, "policy.policyData.savedCar.model");
  const CSCRegNumber = _get(props, "policy.policyData.cscRegNumber");
  const GORegCarNumber = _get(props, "policy.policyData.goRegNumber");
  const carTalonNumber = _get(props, "policy.policyData.savedCar.talonNo");
  const vin = _get(props, "policy.policyData.savedCar.vehicleData.vin");
  const seatsCount = _get(props, "policy.policyData.savedCar.vehicleData.seatsCount");
  const engineVolume = _get(props, "policy.policyData.savedCar.vehicleData.engineVolume");
  const vehicleWeightG = _get(props, "policy.policyData.savedCar.vehicleWeightG");
  const kW = _get(props, "policy.policyData.savedCar.vehicleData.kW");
  const firstRegDate = _get(props, "policy.policyData.savedCar.vehicleData.firstRegDate");
  const fuelTypeId = _get(props, "policy.policyData.savedCar.vehicleData.fuelTypeId");
  const typeId = _get(props, "policy.policyData.savedCar.vehicleData.typeId");
  const seatsDetail = _get(props, "policy.policyData.savedCar.seatsDetail");
  const gearbox = _get(props, "policy.policyData.savedCar.gearbox");
  const bodyType = _get(props, "policy.policyData.savedCar.bodyType");
  const variant = _get(props, "policy.policyData.savedCar.variant");
  const ownerName = `${ownerData?.firstName} ${ownerData?.middleName} ${ownerData?.lastName}`;
  const ownerEGN = _get(ownerData, "egn");
  const ownerAddress = _get(ownerData, "address");
  const ownerCityName = _get(ownerData, "cityName");
  const ownerIsAFirm = Boolean(_get(props, "policy.policyData.savedCar.ownerData.eik"));
  const companyName = _get(props, "policy.policyData.savedCar.ownerData.companyName");
  const eik = _get(props, "policy.policyData.savedCar.ownerData.eik");
  const colorId = _get(props, "policy.policyData.savedCar.vehicleData.colorId");
  const colorName = _get(props, "policy.policyData.savedCar.vehicleColorName", "");

  const isAForeignInstallment = Boolean(_get(props, "policy.policyData.goPolicyNumber"));
  return (
    <>
      {["CSC", "GO"].includes(productId) && !isAForeignInstallment && (
        <>
          <Box mt={3}>
            <div>
              <b>Марка: </b>
              <span>{carMake}</span>
            </div>
            <div>
              <b>Модел: </b>
              <span>{carModel}</span>
            </div>
            {["CSC"].includes(productId) && variant && (
              <div>
                <b>Вариант:</b>
                <span>{variant}</span>
              </div>
            )}
            {["CSC"].includes(productId) ? (
              <div>
                <b>Рег.номер:</b>
                <span>{CSCRegNumber}</span>
              </div>
            ) : (
              <div>
                <b>Рег.номер:</b>
                <span>{GORegCarNumber}</span>
              </div>
            )}
            <div>
              <b>Номер на талон:</b>
              <span>{carTalonNumber}</span>
            </div>
            <div>
              <b>VIN:</b>
              <span>{vin}</span>
            </div>
            <div>
              <b>Брой места: </b>
              <span>{seatsCount}</span>
            </div>
            {["CSC"].includes(productId) && (
              <div>
                <b>Брой врати: </b>
                <span>{seatsDetail}</span>
              </div>
            )}
            <div>
              <b>Обем на двигателя: </b>
              <span>{engineVolume}</span>
            </div>
            <div>
              <b>Тегло: </b>
              <span>{vehicleWeightG}кг</span>
            </div>
            {["CSC"].includes(productId) && (
              <div>
                <b>Цвят: </b>
                <span>
                  {colorName} {colorId ? `(№: ${colorId})` : ""}
                </span>
              </div>
            )}
            <div>
              <b>Мощност kW:</b>
              <span> {kW}</span>
            </div>
            {["CSC"].includes(productId) && (
              <>
                <div>
                  <b>Скорости: </b>
                  <span> {gearbox}</span>
                </div>
                <div>
                  <b>Вид купе: </b>
                  <span> {bodyType}</span>
                </div>
              </>
            )}
            <div>
              <b>Дата първа регистрация: </b>
              <span> {firstRegDate}</span>
            </div>
            <div>
              <b>Вид гориво: </b>
              <span> {determineFuelType(fuelTypeId)}</span>
            </div>
            <div>
              <b>Вид автомобил:</b>
              <span> {determineVehicleType(typeId)}</span>
            </div>
            {ownerIsAFirm ? (
              <>
                <div>
                  <b>Собственик:</b>
                  <span> {companyName}</span>
                </div>
                <div>
                  <b>ЕИК:</b>
                  <span> {eik}</span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <b>Собственик:</b>
                  <span> {ownerName}</span>
                </div>
                <div>
                  <b>ЕГН:</b>
                  <span> {ownerEGN}</span>
                </div>
              </>
            )}
            <div>
              <b>Адрес:</b>
              <span> {ownerAddress}</span>
            </div>
            <div>
              <b>Населено място: {ownerCityName}</b>
              <span> {ownerAddress}</span>
            </div>
            {activeGO && (
              <div>
                <b>Текуща ГО:</b>
                <span>{activeGO}</span>
              </div>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default KatData;
