import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as colors from "../../utils/colors";
import "./product.icon.sass";

const ProductIcon = ({ id, label, icon, color, secondary }) => (
  <ListItem key={id} disablePadding sx={{ display: "block" }}>
    <ListItemButton
      sx={{
        minHeight: 60,
        justifyContent: "center",
        py: 0,
        px: 2.5
      }}
      style={{
        backgroundColor: "transparent"
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
          background: colors[`${color}Light`],
          color: colors[color],
          p: 1,
          borderRadius: "5px"
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} secondary={secondary} sx={{ whiteSpace: "pre-wrap" }} primaryTypographyProps={{ variant: "body1" }} className="product-item-text" />
    </ListItemButton>
  </ListItem>
);

export default ProductIcon;
