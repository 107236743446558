import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ContentPaste from "@mui/icons-material/ContentPaste";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import CreditCard from "@mui/icons-material/CreditCard";
import History from "@mui/icons-material/History";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { grayLight } from "../../utils/colors";

const SideTabs = ({ value, onChange, productId, variant, subVariant, currentProduct, isOffer }) => {
  return (
    <Tabs orientation="vertical" variant="scrollable" value={value} onChange={onChange} aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: "divider" }}>
      <Tab
        label="Данни"
        icon={<ContentPaste fontSize="small" />}
        iconPosition="start"
        sx={{
          justifyContent: "flex-start",
          fontSize: "0.625rem",
          fontWeight: "bold",
          borderBottom: `1px solid ${grayLight}`,
          pt: 0,
          pb: 0,
          minHeight: 60
        }}
        value="data"
      />
      <Tab
        className="tab-border-bottom"
        label="Детайли"
        icon={<VisibilityOutlined fontSize="small" />}
        iconPosition="start"
        sx={{
          justifyContent: "flex-start",
          fontSize: "0.625rem",
          fontWeight: "bold",
          borderBottom: `1px solid ${grayLight}`,
          pt: 0,
          pb: 0,
          minHeight: 60
        }}
        value="details"
      />
      <Tab
        label="Документи"
        icon={<DescriptionOutlined fontSize="small" />}
        iconPosition="start"
        sx={{
          justifyContent: "flex-start",
          fontSize: "0.625rem",
          fontWeight: "bold",
          borderBottom: `1px solid ${grayLight}`,
          pt: 0,
          pb: 0,
          minHeight: 60
        }}
        value="documents"
      />
      {(["CSC", "GO"].includes(productId) && ["policies"].includes(variant)) || (!["TRA", "MTN", "EXT"].includes(productId) && ["installments"].includes(variant)) ? (
        <Tab
          label="Вноски и плащания"
          icon={<CreditCard fontSize="small" />}
          iconPosition="start"
          sx={{
            justifyContent: "flex-start",
            fontSize: "0.625rem",
            fontWeight: "bold",
            borderBottom: `1px solid ${grayLight}`,
            pt: 0,
            pb: 0,
            minHeight: 60
          }}
          value="installments"
        />
      ) : null}
      {["CSC", "GO"].includes(productId) && ["policies", "installments"].includes(variant) ? (
        <Tab
          label="История"
          icon={<History fontSize="small" />}
          iconPosition="start"
          sx={{
            justifyContent: "flex-start",
            fontSize: "0.625rem",
            fontWeight: "bold",
            borderBottom: `1px solid ${grayLight}`,
            pt: 0,
            pb: 0,
            minHeight: 60
          }}
          value="history"
        />
      ) : null}
      {productId === "GO" && variant === "queries" ? (
        <Tab
          label="История"
          icon={<History fontSize="small" />}
          iconPosition="start"
          sx={{
            justifyContent: "flex-start",
            fontSize: "0.625rem",
            fontWeight: "bold",
            borderBottom: `1px solid ${grayLight}`,
            pt: 0,
            pb: 0,
            minHeight: 60
          }}
          value="history"
        />
      ) : null}
      {(currentProduct === "CSC" && variant === "queries" && ["offers", "confirmed", "issued"].includes(subVariant)) ||
      (currentProduct === "CSC" && variant === "policies" && ["active"].includes(subVariant) && isOffer) ||
      (currentProduct === "CSC+GO" && productId === "CSC" && variant === "offers" && ["offers", "confirmed"].includes(subVariant)) ||
      (currentProduct === "CSC+GO" && productId === "CSC" && variant === "policies" && ["active"].includes(subVariant) && isOffer) ? (
        <Tab
          label="Оферти"
          icon={<ArticleOutlinedIcon fontSize="small" />}
          iconPosition="start"
          sx={{
            justifyContent: "flex-start",
            fontSize: "0.625rem",
            fontWeight: "bold",
            borderBottom: `1px solid ${grayLight}`,
            pt: 0,
            pb: 0,
            minHeight: 60
          }}
          value="offers"
        />
      ) : null}
    </Tabs>
  );
};

export default SideTabs;
