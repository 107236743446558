export const txtFields = [
  {
    name: "premium",
    label: "Внесена премия (без данък)",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "tax",
    label: "Данък внесена премия",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "total",
    label: "Внесена премия (с данък без отстъпка)",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "totalPromo",
    label: "Внесена премия (след отстъпка)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountPremium",
    label: "Годишна премия без данък",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTax",
    label: "Данък в/у годишна премия",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTotal",
    label: "Годишна премия",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountPromo",
    label: "Годишна премия след отстъпка",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountCommission",
    label: "Комисионна",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  }
];
