import React from "react";
import { Icon, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { danger } from "../../utils/colors";

const style = {
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    paddingY: 2,
    paddingX: 3,
    maxHeight: "80%",
    overflowY: "scroll"
  },
  closeBtn: {
    position: "absolute",
    top: 1,
    left: 1,
    color: danger,
    cursor: "pointer"
  },
  noPadding: {
    paddingY: 0,
    paddingX: 0
  }
};

export default function SimpleModal(props) {
  const open = !!props.open;
  const setOpen = props.setOpen;
  const { title, description, children, icon, noPadding, width } = props;
  return (
    <Modal style={{ overflow: "hidden" }} open={open} onClose={() => setOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <Box sx={{ ...style.paper, ...(noPadding ? style.noPadding : {}), width }}>
        {icon && (
          <Icon style={style.closeBtn} onClick={() => setOpen(false)}>
            close
          </Icon>
        )}
        {title && (
          <h2 id="simple-modal-title" style={{ textAlign: "center", marginTop: 0, marginBottom: 10 }}>
            {title}
          </h2>
        )}
        {description && <p id="simple-modal-description">{description}</p>}
        {children && <div>{children}</div>}
      </Box>
    </Modal>
  );
}
