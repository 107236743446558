import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Grid, Icon, TextField } from "@mui/material";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { sendEndingPayments } from "../../../services/PoliciesService";
import { success, gray } from "../../../utils/colors";
import _get from "lodash.get";
import { connect } from "react-redux";

class EndingPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      month: ""
    };

    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.handleMonth = this.handleMonth.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleTxtChange(e) {
    this.setState({
      email: e.target.value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const { email, month } = this.state;
      const response = await sendEndingPayments({ month, email });
      if (response.status === 200) {
        this.setState({
          email: "",
          month: ""
        });
        successAlert("sent successfully!");
      }
    } catch (e) {
      errorAlert(e.message);
    }
  }

  handleMonth(e) {
    this.setState({
      month: e.target.value
    });
  }
  render() {
    const { email, month } = this.state;
    const btnDisabled = !month || !email;
    return (
      <PageLayout title="Отчет изтичащи вноски" {...this.props}>
        <Grid item xs={6} sm={4} md={2}>
          <form style={{ marginLeft: "10px" }} onSubmit={this.handleSubmit}>
            <Box mb={2}>
              <TextField fullWidth label="Email" id="outlined-size-small" variant="outlined" size="small" value={email} onChange={this.handleTxtChange} />
            </Box>
            <Box mb={2}>
              <TextField fullWidth onChange={this.handleMonth} id="outlined-size-small" variant="outlined" size="small" label="Month(1-12)" value={month} />
            </Box>
            <Box mb={2}>
              <Button
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: !btnDisabled ? success : gray,
                  color: "#fff",
                  width: "min-content",
                  float: "right"
                }}
                endIcon={<Icon>arrow_right</Icon>}
              >
                Изпрати
              </Button>
              <div style={{ clear: "both" }} />
            </Box>
          </form>
        </Grid>
      </PageLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(EndingPayments);
