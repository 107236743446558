import React from "react";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "./questions.answers.sass";
import _get from "lodash.get";

class QuestionsAnswers extends React.Component {
  render() {
    const { policy } = this.props;
    const productId = _get(policy, "productId");
    const person = ["KID", "MED"].includes(productId) ? _get(this.props, "policy.policyData.persons[0]", []) : null;
    const label = person ? `${person.firstName} ${person.middleName} ${person.lastName}` : "";
    let questions;
    if (["KID", "MED"].includes(productId)) {
      questions = _get(this.props, "policy.policyData.questions", []);
      questions = Array.isArray(_get(questions, "[0]")) ? _get(questions, "[0]") : questions;
    } else if (productId === "CSC") {
      const cascoAdditionalQuestions = _get(this.props, "policy.policyData.cscAdditionalQuestions", []);
      questions = cascoAdditionalQuestions.map(({ text, answerText }) => {
        return {
          text,
          answerText
        };
      });
    }

    return (
      ["CSC", "KID", "MED"].includes(productId) && (
        <div>
          <br />
          <b>Въпроси и отговори:</b>
          {questions.map((q, index) => (
            <div key={`question-${index}`} className="question-wrapper">
              <span className="question-txt">{q?.text}</span>
              <div className="vertical-align">
                <TaskAltIcon fontSize="inherit" sx={{ mr: 1 }} /> {q?.answerText}
              </div>
            </div>
          ))}
        </div>
      )
    );
  }
}
export default QuestionsAnswers;
