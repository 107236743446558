import React, { useState } from "react";
import { TextField } from "@mui/material";
import moment from "moment";
import _get from "lodash.get";
import withValidation from "../../../../HOC/withValidation";
import { txtFields } from "../../../../../config/WizardGO/ManualPolicyIssuanceGO.page.config";
import { reserveStickerAndGreenCardForDuePayment, setWizardStatus, uploadPolicy } from "../../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../../utils/notifications";
import { isAutomaticStickersActive } from "../../../../../config/mainConfig/helpers";
import WizardButton from "../../../common/WizardButton";

const ManualPolicyIssuance = props => {
  const dateFromDefault = _get(props, "objWithEgnAndRequestId.policyData.validFrom");
  const dateToDefault = _get(props, "objWithEgnAndRequestId.policyData.validTo");
  const { variant, setLoading, loading } = props;
  const [dateFrom, setDateFrom] = useState(dateFromDefault ? moment(dateFromDefault).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"));
  const [dateTo, setDateTo] = useState(dateToDefault ? moment(dateToDefault).format("DD-MM-YYYY") : moment().add(1, "years").format("DD-MM-YYYY"));

  const handleDateChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    const isValid = moment(value, "DD-MM-YYYY", true).isValid();
    setDateFrom(value);
    if (isValid) {
      setDateTo(moment(value, "DD-MM-YYYY").add(1, "years").format("DD-MM-YYYY"));
    } else {
      setDateTo("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { nextStep, refreshData } = props;

    if (variant === "queries") {
      if (props?.handleValidation()) {
        try {
          const dateFromFormatted = dateFrom ? moment(dateFrom, "DD-MM-YYYY").format("YYYY-MM-DD") : dateFrom;
          let requestPayload;
          if (isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId)) {
            requestPayload = {
              date: dateFromFormatted,
              policyNumber: props?.formData?.policyNumber?.trim(),
              sticker: props?.formData?.sticker?.trim(),
              greenCard: props?.formData?.greenCard?.trim(),
              prefix: props?.formData?.prefix?.trim(),
              policy: props?.objWithEgnAndRequestId,
              isFromWizard: true
            };
          } else {
            requestPayload = {
              date: dateFromFormatted,
              policyNumber: props?.formData?.policyNumber?.trim(),
              policy: props?.objWithEgnAndRequestId,
              isFromWizard: true
            };
          }
          await uploadPolicy(requestPayload);
          await setWizardStatus({ policyDBId: props?.objWithEgnAndRequestId?._id, wizardStatus: "step1.1" });
          refreshData();
          nextStep();
          successAlert("policy number and date set successfully!");
        } catch (e) {
          setLoading(false);
          if (e?.response?.data) errorAlert("Error: " + e.response.data);
          else if (e?.message) errorAlert("Error: " + e.message);
          else errorAlert("Error occurred!");
        }
      }
    }
    if (variant === "installments") {
      if (isAutomaticStickersActive(props?.policy?.insurerId, props?.policy?.productId)) {
        const requestPayload = {
          duePaymentId: props?.objWithEgnAndRequestId?.duePayment?._id?.trim(),
          sticker: props?.formData?.sticker?.trim(),
          greenCard: props?.formData?.greenCard?.trim(),
          prefix: props?.formData?.prefix?.trim()
        };
        await reserveStickerAndGreenCardForDuePayment(requestPayload);
      }
      await setWizardStatus({ duePaymentId: props?.objWithEgnAndRequestId?.duePayment?._id?.trim(), wizardStatus: "step1.1" });
      refreshData();
      nextStep();
      successAlert("installment issued successfully!");
    }

    setLoading(false);
  };
  const btnText = variant === "queries" ? "Запази" : "Издай";
  //todo DA GO NAPRAVQ KATO AutomaticPolicyIssuance.js
  return (
    <>
      {variant === "queries" && (
        <>
          <span className="red size-6" style={{ marginLeft: 14, marginBottom: 5, display: "block" }}>
            В сила от:
          </span>
          <TextField
            fullWidth
            label="Въведи датата"
            placeholder="24-01-2020"
            id="outlined-size-small-date-from"
            variant="outlined"
            size="small"
            value={dateFrom}
            onChange={handleDateChange}
          />
          <br />
          <br />
        </>
      )}
      {txtFields
        .filter(f => !f.hidden?.(props))
        .map(f => (
          <TextField
            sx={{ mb: 1, mt: 1 }}
            key={f.name}
            fullWidth
            label={f.label}
            placeholder={f.placeholder}
            variant="outlined"
            size="small"
            name={f.name}
            value={props?.formData[f.name]}
            onChange={props?.handleChange}
            helperText={props?.errors[f.name]}
            error={!!props?.errors[f.name]}
          />
        ))}
      <WizardButton disabled={loading} handleSubmit={handleSubmit} loading={loading} btnText={btnText} />
    </>
  );
};

export default withValidation(ManualPolicyIssuance, { txtFields });
