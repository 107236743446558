import _get from "lodash.get";
import moment from "moment";
export const reasons = [
  { value: "ownerChange", label: "Смяна на собственик" },
  { value: "carNumberChange", label: "Смяна на рег. номер" },
  { value: "addressChange", label: "Смяна на адрес" }
];

export const fields = [
  {
    name: "date",
    type: "date",
    label: "ВЪВЕДИ ДАТА",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    value: moment()
  },
  {
    name: "reason",
    type: "select",
    label: "ПРИЧИНА",
    value: "",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    options: reasons
  },
  {
    name: "annexNumber",
    type: "text",
    label: "НОМЕР НА АНЕКС",
    placeholder: "",
    validators: ["required"],
    errorMessages: ["Полето е задължително"],
    defaultValueFunction: props => {
      return _get(props, "objWithEgnAndRequestId.policyId", "");
    }
  }
];

export const getFields = productId => {
  if (!productId || productId === "GO") {
    return fields;
  } else if (productId === "CSC") {
    const fieldsWithExtraReasons = [...fields];

    const reasonField = fieldsWithExtraReasons.find(field => field.name === "reason");
    if (reasonField) {
      reasonField.options = [
        ...reasons,
        { value: "policyChange", label: "Промяна полица( Смяна вид сервиз, пътна помощ, друго)" },
        { value: "additionalInsurance", label: "Дозастраховане" }
      ];
    }

    return fieldsWithExtraReasons;
  }
};
