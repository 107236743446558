import mainConfig from "./index";

export const isAutomaticStickersActive = (insurer, product) => {
  const automaticStickersConfig = mainConfig?.automaticStickers;
  if (automaticStickersConfig && product === "GO") {
    return !!automaticStickersConfig?.find(x => x.id === insurer)?.active;
  }
  return false;
};

export const isAutomaticAndManualPolicyIssuanceActive = (product, insurer) => {
  const automaticAndManualPolicyIssuanceConfig = mainConfig?.automaticAndManualPolicyIssuance;
  return !!automaticAndManualPolicyIssuanceConfig?.[product]?.find(x => x.id === insurer)?.active;
};
