import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Grid } from "@mui/material";
import moment from "moment";
import _get from "lodash.get";
import { useEffect, useState } from "react";
import { getPaymentsByRequestId } from "../../../services/PoliciesService";

const Payments = props => {
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    const requestId = _get(props, "location.state.requestId", []);
    async function fetchData() {
      const response = await getPaymentsByRequestId(requestId);
      setPayments(_get(response, "data", []));
    }
    fetchData();
  }, []);
  return (
    <PageLayout title="Плащания" {...props}>
      <Grid item xs={12} sm={12} md={12}>
        {!!payments.length &&
          payments.map((p, index) => (
            <div key={index} style={{ borderBottom: "1px solid #f0f0f0", marginBottom: 10 }}>
              <p>request ID: {p.requestId}</p>
              <p>user ID: {p.userId}</p>
              <p>receipt ID: {p.receiptId}</p>
              <p>checkTransStatus: {p.checkTransStatus ? "true" : "false"}</p>
              <p>статус: {p.status}</p>
              <p>сума: {`${p.amount} лв.`}</p>
              <p>дата: {moment(p.timestamp).format("DD-MM-YYYY HH:mm:ss")}</p>
              <p>продукт: {p.productId}</p>
              <p>
                <b>Начин на плащане:</b> {p?.paymentMode === "1" && "Банкова карта"}
                {p?.paymentMode === "2" && "Наложен платеж"}
              </p>
              {p.description && <p>описание: {p.description}</p>}
            </div>
          ))}
      </Grid>
    </PageLayout>
  );
};
export default Payments;
