export const fields = [
  {
    name: "amount",
    label: "Брутна премия (с данък)",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"],
    type: "text"
  },
  {
    name: "tax",
    label: "Данък (сума)",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"],
    type: "text"
  },
  {
    name: "premium",
    label: "Нетна премия (без данък)",
    placeholder: "",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"],
    type: "text"
  }
];
