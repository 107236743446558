import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Typography, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import Modal from "../Modal";
import { error as errorAlert, success as successAlert } from "../../utils/notifications";
import { addUserToBlacklist, removeUserFromBlacklist } from "../../services/PoliciesService";
import _uniqby from "lodash.uniqby";

const IncorrectPayersModal = ({ isIncorrectPayersModalOpen, toggle, close, policy, refreshTable }) => {
  const egn = policy?.policyData?.egn;
  const egnOwner = policy?.policyData?.savedCar?.ownerData?.egn;
  const eikOwner = policy?.policyData?.savedCar?.ownerData?.eik;
  const policyId = policy?._id;
  const isBlacklisted = Boolean(policy?.policyData?.isBlacklisted);
  const [action, setAction] = useState("");
  let blacklisted = [];
  const addBlacklisted = async blacklisted => {
    try {
      const response = await addUserToBlacklist({ blacklisted, policyId });
      if (response?.data === "OK") {
        successAlert("added successfully!");
        refreshTable();
        close("incorrectPayersModal");
      } else if (response?.data?.includes("duplicate key error collection")) {
        errorAlert(response?.data);
      }
    } catch (e) {
      errorAlert(e.message);
    }
  };

  const removeBlacklisted = async blacklistedIds => {
    try {
      const response = await removeUserFromBlacklist({ blacklistedIds, policyId });
      if (response?.data === "OK") {
        successAlert("removed successfully!");
        refreshTable();
        close("incorrectPayersModal");
      }
    } catch (e) {
      errorAlert(e.message);
    }
  };

  const handleTxtChange = e => setAction(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();
    if (egn) {
      blacklisted.push({ blacklistedId: egn });
    }
    if (egnOwner) {
      blacklisted.push({ blacklistedId: egnOwner });
    }
    if (eikOwner) {
      blacklisted.push({ blacklistedId: eikOwner });
    }
    if (action === "Некоректен платец") {
      const uniqBlacklisted = _uniqby(blacklisted, "blacklistedId");
      await addBlacklisted(uniqBlacklisted);
    } else {
      let blacklistedIds = blacklisted?.map(({ blacklistedId }) => blacklistedId);
      await removeBlacklisted(blacklistedIds);
    }
  };
  const content = (
    <form onSubmit={e => handleSubmit(e)}>
      <Box>
        <Typography mb={2}>{`Застраховащ ЕГН: ${egn}`}</Typography>
        <Typography>{`Застрахован ЕГН/ЕИК: ${egnOwner ? egnOwner : eikOwner}`}</Typography>
        <Box mt={3}>
          <FormControl fullWidth variant="standard">
            <Select
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              value={action}
              onChange={e => handleTxtChange(e)}
              displayEmpty
            >
              {isBlacklisted ? (
                <MenuItem value={"Извади от списък некоректни платци"}>Извади от списък некоректнии платци</MenuItem>
              ) : (
                <MenuItem value={"Некоректен платец"}>Некоректен платец</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Stack mt={12} direction="row" justifyContent="flex-end">
          <Button variant="contained" color="primary" endIcon={<CheckIcon />} disabled={!action} type="submit">
            Потвърди
          </Button>
        </Stack>
      </Box>
    </form>
  );

  return (
    <Modal
      open={isIncorrectPayersModalOpen}
      setOpen={() => toggle("incorrectPayersModal")}
      title={`${isBlacklisted ? "Премахни некоректен платец" : "Добави некоректени платци"}`}
      icon
    >
      {content}
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(IncorrectPayersModal);
