const routesConfig = {
  home: {
    route: `/`
  },
  login: {
    route: `/login`
  },
  payments: {
    route: `/payments`
  },
  refunds: {
    route: `/refunds`
  },
  statistics: {
    route: `/statistics`
  },
  policies: {
    route: `/ALL`
  },
  bestDoctorsReport: {
    route: `/bestDoctorsReport`
  },
  kfnReport: {
    route: `/kfnReport`
  },
  addStickers: {
    route: `/add-stickers`
  },
  addPromoCodes: {
    route: `/add-promo-codes`
  },
  addReferral: {
    route: `/add-referral`
  },
  stickers: {
    route: `/stickers`
  },
  offlinePolicy: {
    route: `/offline-policy`
  },
  salesReport: {
    route: `/sales-report`
  },
  endingPoliciesReport: {
    route: `/endingPolicies-report`
  },
  endingPaymentsReport: {
    route: `/endingPayments-report`
  },
  reportsForPeriod: {
    route: `/reports-for-period`
  },
  commissionAndWeeklyReports: {
    route: `/commission-and-weekly-reports`
  },
  reportsTransactions: {
    route: `/reports-transactions`
  },
  sendDocuments: {
    route: `/send-documents`
  },
  sendDocumentsToInsurer: {
    route: `/send-documents-to-insurer`
  },
  sendDocumentsCascoPlusGO: {
    route: `/send-documents-csc-plus-go`
  },
  sendPolicy: {
    route: `/send-policy`
  },
  signingPDF: {
    route: `/signing-pdf`
  },
  editPolicy: {
    route: `/edit-policy`
  },
  shipment: {
    route: `/shipment`
  },
  reports: {
    route: `/reports`
  },
  notAuthorized: {
    route: `/not-authorized`
  },
  cloneBestDoctors: {
    route: `/clone-best-doctors`
  },
  purchases: {
    route: `/purchases`
  },
  casco: {
    route: `/CSC`
  },
  go: {
    route: `/GO`
  },
  cascoPlusGo: {
    route: `/CSC+GO`
  },
  sendOffer: {
    route: `/send-offer`
  },
  sendOfferCascoPlusGO: {
    route: `/send-offer-csc-plus-go`
  },
  tra: {
    route: `/TRA`
  },
  prp: {
    route: `/PRP`
  },
  mtn: {
    route: `/MTN`
  },
  ext: {
    route: `/EXT`
  },
  hlt: {
    route: `/HLT`
  },
  off: {
    route: `/OFF`
  },
  med: {
    route: `/MED`
  },
  lfe: {
    route: `/LFE`
  },
  forgottenPass: {
    route: `/forgotten-pass`
  },
  error: {
    route: `/error`
  }
};
export const routesArray = Object.values(routesConfig).map(({ route }) => route);
export default routesConfig;
