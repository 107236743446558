import React from "react";
import _get from "lodash.get";
import { payInstallmentRequest } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { TextField, Box } from "@mui/material";
import { danger, primary } from "../../utils/colors";

const fieldsForValidation = ["prefix", "stickerGo", "greenCardGo"];
class PayInstallmentModal extends React.Component {
  constructor(props) {
    super(props);
    const validFields = {};
    fieldsForValidation.forEach(el => {
      validFields[`${el}IsValid`] = true;
    });
    this.state = { stickerGo: "", greenCardGo: "", prefix: "", ...validFields };
  }
  handleTxtChange = (e, name) => {
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        if (name === "greenCardGo") {
          const checkFromIsValid = this.typeCheckIsValid("stickerGo");
          this.setState({
            [`stickerGoIsValid`]: checkFromIsValid
          });
        }
        if (fieldsForValidation.includes(name)) {
          const valueOfName = this.typeCheckIsValid(name);
          this.setState({
            [`${name}IsValid`]: valueOfName
          });
        }
      }
    );
  };

  typeCheckIsValid = name => {
    const { prefix, greenCardGo, stickerGo } = this.state;
    if (this.state[name].trim() === "") {
      return true;
    }
    if (name === "stickerGo") {
      return (stickerGo.length === 8 || stickerGo.length === 9) && !/\D/.test(stickerGo);
    } else if (name === "greenCardGo") {
      return greenCardGo.length === 6 && !/\D/.test(greenCardGo);
    } else if (name === "prefix") {
      return prefix.length === 2 && /\D/.test(prefix) && prefix === prefix.toUpperCase();
    }
  };
  render() {
    const { modalName, isPayInstallmentModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const modalTitle = "Разплащане на вноска";
    const btnTxt = "Плати";

    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        btnDisabled={
          !this.state.prefix || !this.state.stickerGo || !this.state.greenCardGo || !this.state.stickerGoIsValid || !this.state.greenCardGoIsValid || !this.state.prefixIsValid
        }
        isModalOpen={isPayInstallmentModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{
          ...objWithEgnAndRequestId,
          sticker: `${this.state.stickerGo}`,
          greenCard: `${this.state.greenCardGo}`,
          prefix: `${this.state.prefix}`
        }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <Box mb={2}>
          <TextField
            fullWidth
            label="Стикер"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={this.state.stickerGo}
            onChange={e => this.handleTxtChange(e, "stickerGo")}
          />
          {!this.state.stickerGoIsValid && <p style={{ color: danger }}>Стикерът трябва да е 8 цифри.</p>}
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Зелена карта"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={this.state.greenCardGo}
            onChange={e => this.handleTxtChange(e, "greenCardGo")}
          />
          {!this.state.greenCardGoIsValid && <p style={{ color: danger }}>Зелената карта трябва да е 6 цифри.</p>}
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Префикс"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={this.state.prefix}
            onChange={e => this.handleTxtChange(e, "prefix")}
          />
          {!this.state.prefixIsValid && <p style={{ color: danger }}>Невалиден Префикс на Зелена карта- Трябва да е 2 ГЛАВНИ букви. </p>}
        </Box>
      </ConfirmationModal>
    );
  }
}
const generatePayloadRequest = props => {
  const requestPayload = {
    policyId: _get(props, "objWithEgnAndRequestId._id"),
    insurerName: _get(props, "objWithEgnAndRequestId.insurerId"),
    sticker: _get(props, "objWithEgnAndRequestId.sticker"),
    greenCard: _get(props, "objWithEgnAndRequestId.greenCard"),
    prefix: _get(props, "objWithEgnAndRequestId.prefix"),
    installment: _get(props, "objWithEgnAndRequestId.paymentId", 0).toString()
  };
  return requestPayload;
};

export default withConfirmationModal(PayInstallmentModal, {
  generatePayloadRequest,
  apiRequest: payInstallmentRequest,
  modalName: "payInstallmentModal",
  successMessage: "Успешно заплащане!"
});
