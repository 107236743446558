import _get from "lodash.get";
import _find from "lodash.find";

export const attachmentFields = [
  {
    name: "policy",
    type: "file",
    defaultValueFunction: props => {
      const formData = _get(props, `form.lfeFormData`);
      const uploadedFiles = _get(props, `form.lfeUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      const uploaded = _get(_find(uploadedFiles, { key: "policy" }), "uploaded", false);
      return uploaded ? { name: "файлът е качен" } : "";
    },
    defaultErrorFunction: props => {
      const formData = _get(props, `form.lfeFormData`);
      const uploadedFiles = _get(props, `form.lfeUploadedFiles[${props?.policy.policyDBId || formData?.policyOid}]`);
      return _get(_find(uploadedFiles, { key: "policy" }), "message", "");
    },
    label: "Полица  (задължително):",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  }
];
