import { request } from "../APIservice";
import { POST } from "../../constants/api";
const siteConfig = window.localStorage.getItem("siteConfig") || "boleron";
const baseUrlMainApi = process.env[`REACT_APP_BASE_URL_${siteConfig.toUpperCase()}`];

export const getSiteSuggestions = async (city, postCode) => {
  const r = await request(
    POST,
    `/econt/location/site`,
    {
      name: city,
      postCode
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data);
  return r;
};

export const getOfficeSuggestions = async (cityId, cityName, postCode) => {
  const r = await request(
    POST,
    `/econt/location/office`,
    {
      cityId,
      name: cityName,
      postCode
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data);
  return r;
};

export const getComplexSuggestions = async (cityId, search) => {
  const r = await request(
    POST,
    `/econt/location/complex`,
    {
      cityId,
      name: search
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data);
  return r;
};

export const getStreetSuggestions = async (cityId, search) => {
  const r = await request(
    POST,
    `/econt/location/street`,
    {
      cityID: cityId,
      name: search
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data);
  return r;
};
