import React from "react";
import _get from "lodash.get";
import { countriesFullNameView } from "../../utils/functions";
import countriesJson from "../../utils/countries.json";

const CountriesInfo = props => {
  const { policy } = props;
  const productId = _get(policy, "productId");
  const selectedCountries = _get(policy, "policyData.selectedCountries", []);
  const countriesNames = countriesFullNameView(selectedCountries, countriesJson);
  return (
    <>
      {["TRA"].includes(productId) && (
        <div>
          <b>Посочени страни:</b>
          <p style={{ fontSize: "11px" }}>{countriesNames}</p>
        </div>
      )}
    </>
  );
};

export default CountriesInfo;
