import { request } from "../APIservice";
import { POST } from "../../constants/api";
// import { fs } from "fs";
// import fileDownload from "js-file-download";

const siteConfig = window.localStorage.getItem("siteConfig") || "boleron";
const baseUrlMainApi = process.env[`REACT_APP_BASE_URL_${siteConfig.toUpperCase()}`];

export const getSiteSuggestions = async (countryId = 100, cityName, postCode) => {
  const r = await request(
    POST,
    `speedy/location/site`,
    {
      countryId,
      name: cityName,
      postCode
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data.sites);
  return r;
};

export const getOfficeSuggestions = async (countryId = 100, siteId, officeId, cityName, postCode) => {
  const r = await request(
    POST,
    `speedy/location/office`,
    {
      countryId,
      siteId,
      officeId,
      name: cityName,
      postCode
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data.offices);
  return r;
};

export const getComplexSuggestions = async (siteId, search) => {
  const r = await request(
    POST,
    `speedy/location/complex`,
    {
      siteId,
      name: search
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data.complexes);
  return r;
};

export const getStreetSuggestions = async (siteId, search) => {
  const r = await request(
    POST,
    `speedy/location/street`,
    {
      siteId,
      name: search
    },
    {},
    true,
    true,
    baseUrlMainApi
  ).then(({ data }) => data.streets);
  return r;
};

// export const downloadPdf = async (token, form) => {
//   const { parcelId, requestId } = form;
//   const r = await request(
//     POST,
//     `speedy/print`,
//     {
//       language: "BG",
//       paperSize: "A6",
//       parcels: [
//         {
//           parcelId: {
//             id: parcelId
//           }
//         }
//       ],
//       additionalWaybillSenderCopy: "NONE"
//     },
//     {},
//     token,
//     true,
//     baseUrlMainApi,
//     "blob"
//   ).then(({ data }) => fileDownload(data, `policy-${requestId}.pdf`));
//   return r;
// };

// export const downloadPdfByPolicyId = async (token, form) => {
//   const { policyId, requestId, courier } = form;
//   const r = await request(
//     GET,
//     `shipment/print`,
//     {
//       policyId
//     },
//     {},
//     token,
//     true,
//     false,
//     "blob"
//   ).then(({ data }) => fileDownload(data, `Policy-${requestId}-${courier}.pdf`));
//   return r;
// };
