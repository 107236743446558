import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Individual from "./Individual";
import LegalEntity from "./LegalEntity";
// import "./policy.issuance.sass";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch } from "react-redux";
import { setAndResetOfflineFormData } from "../../../../store/reducers/form";

const InsurerData = props => {
  const [selectedTab, setSelectedTab] = useState("individual");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleChange = e => {
    setSelectedTab(e?.target?.value);
  };
  useEffect(() => {
    props?.setWidth(700);
  }, []);

  const clearForm = () => {
    dispatch(setAndResetOfflineFormData({}));
    props?.close("issuePolicyWizard");
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center">
        <div style={{ marginTop: 10, width: "60%" }}>
          <a
            className="link"
            onClick={() => {
              clearForm();
            }}
          >
            Изчисти формата
          </a>
          {!loading && (
            <FormControl>
              <RadioGroup className="vertical-align" row aria-labelledby="row-radio-buttons" name="row-radio-buttons-group" value={selectedTab}>
                {/*<FormLabel id="row-radio-buttons">Данни на застраховащ</FormLabel>*/}
                <FormControlLabel value="individual" control={<Radio onChange={handleChange} />} label="Физическо лице" />
                <FormControlLabel value="legalEntity" control={<Radio onChange={handleChange} />} label="Юридическо лице" />
              </RadioGroup>
            </FormControl>
          )}

          {selectedTab === "individual" && <Individual {...props} loading={loading} setLoading={setLoading} />}
          {selectedTab === "legalEntity" && <LegalEntity {...props} loading={loading} setLoading={setLoading} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default InsurerData;
