import React, { useContext } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import AsynchronousAutocomplete from "../../core/AsynchronousAutocomplete.js";
import { FormContext } from "./Form.js";
import useToggle from "../../../hooks/useToggle.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const FieldInput = ({ name, label, type = "text", ...rest }) => {
  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;
  return <TextField size="small" type={type} name={name} label={label} variant="outlined" value={form[name]} onChange={handleFormChange} autoComplete="off" {...rest} />;
};

export const FieldAutocomplete = ({ name, label, initialValue, ...rest }) => {
  const formContext = useContext(FormContext);
  const { form, errors = {}, autocompleteServices, handleFormChange } = formContext;

  return (
    <AsynchronousAutocomplete
      name={name}
      label={label}
      autocompleteService={autocompleteServices[name](form)}
      value={form[name]}
      onChange={handleFormChange}
      error={errors[name]}
      initialValue={initialValue}
      {...rest}
    />
  );
};

export const FieldCheckbox = ({ name, label, value, ...rest }) => {
  const [state, toggle] = useToggle(value);
  const formContext = useContext(FormContext);
  const { handleFormChange } = formContext;
  return (
    <FormControlLabel
      name={name}
      label={label}
      control={
        <Checkbox
          checked={state}
          onChange={e => {
            toggle();
            handleFormChange(e);
          }}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
      {...rest}
    />
  );
};

export const FieldCalendar = ({ name, label, ...rest }) => {
  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        label={label}
        inputFormat="DD.MM.YYYY г."
        value={form[name]}
        onChange={value => handleFormChange({ target: { name, type: "datepicker", value } })}
        renderInput={params => <TextField size="small" fullWidth {...params} />}
        {...rest}
      />
    </LocalizationProvider>
  );
};
