import React from "react";
import _get from "lodash.get";
import { setUserRisk } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { primary } from "../../utils/colors";

class RiskyUserProfileConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusRisk: ""
    };
  }
  handleTxtChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  };
  render() {
    const { modalName, isRiskyUserProfileModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const { statusRisk } = this.state;
    const modalTitle = "Промени рисков профил";
    const btnTxt = "Промени";
    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        btnDisabled={!statusRisk}
        isModalOpen={isRiskyUserProfileModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{
          ...objWithEgnAndRequestId,
          risk: statusRisk
        }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <Box mb={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Статус
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              labelId="demo-simple-select-placeholder-label-label"
              id="outlined-size-small"
              value={statusRisk}
              onChange={e => this.handleTxtChange(e, "statusRisk")}
              displayEmpty
            >
              <MenuItem value={"low"}>Нисък</MenuItem>
              <MenuItem value={"medium"}>Среден</MenuItem>
              <MenuItem value={"high"}>Висок</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </ConfirmationModal>
    );
  }
}
const generatePayloadRequest = props => {
  const requestPayload = {
    uid: _get(props, "objWithEgnAndRequestId.userId"),
    risk: _get(props, "objWithEgnAndRequestId.risk")
  };
  return requestPayload;
};

export default withConfirmationModal(RiskyUserProfileConfirmationModal, {
  generatePayloadRequest,
  apiRequest: setUserRisk,
  modalName: "riskyUserProfileModal",
  successMessage: "Успешна промяна!"
});
